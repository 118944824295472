import React, { useState, useContext, ReactElement } from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LanguageIcon from "@mui/icons-material/Language";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import MenuIcon from "@mui/icons-material/Menu";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/client";

import RenderNavigationItem from "../navigation/RenderNavigationItem";
import { getRoute } from "core/helpers";
import { CompassContext } from "core/context/CompassContext";
import LanguageOptions, { LanguageCode } from "core/languages/LanguageOptions";
import { MainRoutes } from "routes/routes";
import { ReactComponent as Logo } from "assets/images/logo/WhiteLogo.svg";
import { userSettingMutation } from "api/mutations";
import OrganizationListDialog from "./OrganizationListDialog";
import { useLang, useSurveys } from "core/hooks";
import { useHeaderStyles } from "./styles";
import { NavigationKey, NavigationType, ProfileMenuAction } from "./enums";
import { MainRoutes as MainRoutesEnum, ReportRoutes as ReportRoutesEnum } from "routes/enums";
import { ReportRoutes } from "routes/routes";
import { LanguageDetail } from "core/context/interfaces";
import { Translation } from "core/languages/interfaces";
import { NavigationOption, SelectOptionNavigation } from "./interfaces";
import { UserInfo } from "../admin/users/interface";
import { CompassContextActions } from "core/context/actions";
import getTranslation from "core/languages/getTranslation";
import { initFilter } from "components/filters/inits";
import { initResponseRateStoredData } from "components/reports/responseRate/init";
import { initHeatmapRestoreValue } from "components/reports/heatmap/heatmap.init";
import {
    initSelectedSurvey,
    initSnapshotSettings,
    initCommentSettings,
    initCorrelationSettings,
    initUserDashboarSettings,
    initInsightSettings
} from "components/admin/users/initialVariables";
import { List, ListItem } from "lib/list";
import { Drawer } from "lib/drawer";
import { useUser } from "core/context/user/useUser";
import { useOrganization } from "core/context/organization/useOrganization";
import { OrganizationInfo } from "components/admin/organizations/interfaces";

const getLinkTo = (routeKey: MainRoutesEnum, user: UserInfo): string => {
    const route = getRoute(routeKey, MainRoutes(user));
    return route.path;
};

const getSelectLanguage = (languageOptions: LanguageDetail[], langCode: string): SelectOptionNavigation[] => {
    return languageOptions.map((lang: LanguageDetail) => {
        return { label: lang.label, value: lang.value, selected: lang.code === langCode };
    });
};

const helpMenuOption = (lang: Translation): SelectOptionNavigation[] => {
    return [
        { label: lang.knowledgeBase, value: 0, hyperLinkTo: "https://docs.talentmap.com/knowledge-base" },
        {
            label: lang.whatIsNew,
            value: 1,
            hyperLinkTo:
                "https://docs.talentmap.com/knowledge-base/step-5-analyze-interpret-and-report/software-release-notes/2024-product-updates"
        }
    ];
};

const profileMenuOption = (lang: Translation, user: UserInfo): SelectOptionNavigation[] => {
    return [
        { label: user.email ? user.email : user.name ?? lang.user, value: 0 },
        { label: lang.tour, value: 1, switchTour: true },
        { label: lang.logout, value: 2, action: ProfileMenuAction.logout }
    ];
};

const reportsMenuOption = (
    lang: Translation,
    disableOption: boolean,
    disabledMsg: string,
    rrDisableOption: boolean,
    isCommentViewer: boolean,
    isTalentMapAdmin: boolean,
    isDashboardEnabled: boolean,
    isInsightPageEnabled: boolean
): SelectOptionNavigation[] => [
    {
        label: lang.dashboard,
        value: 1,
        hidden: !isDashboardEnabled && !isTalentMapAdmin,
        linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.userDashboard}`,
        disabled: disableOption,
        disabledMsg: disabledMsg
    },
    {
        label: lang.responseRate,
        value: 2,
        linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.responseRate}`,
        hidden: rrDisableOption
    },
    {
        label: lang.snapshot,
        value: 3,
        linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.snapshot}`,
        disabled: disableOption,
        disabledMsg: disabledMsg
    },
    {
        label: lang.heatmap,
        value: 4,
        linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.heatmap}`,
        disabled: disableOption,
        disabledMsg: disabledMsg
    },
    {
        label: lang.comments,
        value: 5,
        linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.comment}`,
        hidden: !isCommentViewer,
        disabled: disableOption,
        disabledMsg: disabledMsg
    },
    {
        label: lang.insights,
        value: 6,
        linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.insight}`,
        hidden: !isInsightPageEnabled,
        disabled: disableOption,
        disabledMsg: disabledMsg
    },
    {
        label: lang.correlations,
        value: 7,
        linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.correlation}`,
        hidden: rrDisableOption,
        disabled: disableOption,
        disabledMsg: disabledMsg
    },
    {
        label: lang.orgChart,
        value: 0,
        linkTo: `/${MainRoutesEnum.reports}/${ReportRoutesEnum.orgChart}`,
        hidden: rrDisableOption,
        disabled: disableOption,
        disabledMsg: disabledMsg
    }
];

//TODO: This array is kind of duplicated. refactor to a better solution
// user permission is set at Main.routes.ts
const navigationList = (
    user: UserInfo,
    lang: Translation,
    langCode: string,
    hasSurvey: boolean,
    drawer: boolean,
    isDashboardEnabled: boolean
): NavigationOption[] => {
    return drawer
        ? [
              {
                  navigationKey: NavigationKey.home,
                  navType: NavigationType.linkWithIcon,
                  linkTo: getLinkTo(MainRoutesEnum.home, user),
                  icon: <Logo />,
                  isAccessGranted: getRoute(MainRoutesEnum.home, MainRoutes(user)).isAccessGranted,
                  align: "left",
                  padding: 0,
                  disabled: {
                      isDisabled:
                          !user.isSiteAdmin && !user.isSurveyCreator && !user.isTalentMapAdmin && user.isRestricted,
                      message: ""
                  }
              },
              {
                  navigationKey: NavigationKey.surveys,
                  label: lang[NavigationKey.surveys],
                  navType: NavigationType.linkWithText,
                  linkTo: getLinkTo(MainRoutesEnum.createSurveys, user),
                  isAccessGranted: getRoute(MainRoutesEnum.createSurveys, MainRoutes(user)).isAccessGranted,
                  align: "left"
              },
              {
                  navigationKey: NavigationKey.reports,
                  label: lang[NavigationKey.reports],
                  navType: NavigationType.drawerDropdown,
                  selectOptions: reportsMenuOption(
                      lang,
                      !hasSurvey,
                      lang.reportWillBeUnlockSurveyCreated,
                      user.isRestricted,
                      user.isCommentViewer,
                      user.isTalentMapAdmin,
                      isDashboardEnabled,
                      user.isInsightsEnabled
                  ),
                  isAccessGranted: getRoute(MainRoutesEnum.reports, ReportRoutes(user)).isAccessGranted,
                  align: "left"
              },
              {
                  navigationKey: NavigationKey.downloads,
                  label: lang[NavigationKey.downloads],
                  navType: NavigationType.linkWithText,
                  linkTo: getLinkTo(MainRoutesEnum.downloads, user),
                  isAccessGranted: getRoute(MainRoutesEnum.downloads, MainRoutes(user)).isAccessGranted,
                  align: "left"
              },
              {
                  navigationKey: NavigationKey.admin,
                  label: lang[NavigationKey.admin],
                  navType: NavigationType.linkWithText,
                  linkTo: getLinkTo(MainRoutesEnum.admin, user),
                  isAccessGranted: getRoute(MainRoutesEnum.admin, MainRoutes(user)).isAccessGranted,
                  align: "left"
              },
              {
                  navigationKey: NavigationKey.profile,
                  navType: NavigationType.drawerDropdown,
                  label: lang[NavigationKey.profile],
                  selectOptions: profileMenuOption(lang, user),
                  isAccessGranted: true, //Access to everyone,
                  align: "left"
              },
              {
                  navigationKey: NavigationKey.language,
                  navType: NavigationType.dropdownWithText,
                  selectOptions: getSelectLanguage(LanguageOptions, langCode),
                  isAccessGranted: true, //Access to everyone,
                  align: "left"
              },
              {
                  navigationKey: NavigationKey.help,
                  navType: NavigationType.dropdownWithIcon,
                  selectOptions: helpMenuOption(lang),
                  icon: <HelpRoundedIcon />,
                  isAccessGranted: true, //Access to everyone,
                  align: "left"
              }
          ]
        : [
              {
                  navigationKey: NavigationKey.home,
                  navType: NavigationType.linkWithIcon,
                  linkTo: getLinkTo(MainRoutesEnum.home, user),
                  icon: <Logo />,
                  isAccessGranted: getRoute(MainRoutesEnum.home, MainRoutes(user)).isAccessGranted,
                  align: "left",
                  padding: 0,
                  disabled: {
                      isDisabled:
                          !user.isSiteAdmin && !user.isSurveyCreator && !user.isTalentMapAdmin && user.isRestricted,
                      message: ""
                  }
              },
              {
                  navigationKey: NavigationKey.surveys,
                  label: lang[NavigationKey.surveys],
                  navType: NavigationType.linkWithText,
                  linkTo: getLinkTo(MainRoutesEnum.createSurveys, user),
                  isAccessGranted: getRoute(MainRoutesEnum.createSurveys, MainRoutes(user)).isAccessGranted,
                  align: "left"
              },
              {
                  navigationKey: NavigationKey.reports,
                  label: lang[NavigationKey.reports],
                  navType: NavigationType.dropdownWithText,
                  selectOptions: reportsMenuOption(
                      lang,
                      !hasSurvey,
                      lang.reportWillBeUnlockSurveyCreated,
                      user.isRestricted,
                      user.isCommentViewer,
                      user.isTalentMapAdmin,
                      isDashboardEnabled,
                      user.isInsightsEnabled
                  ),
                  isAccessGranted: getRoute(MainRoutesEnum.reports, ReportRoutes(user)).isAccessGranted,
                  align: "left"
              },
              {
                  navigationKey: NavigationKey.downloads,
                  label: lang[NavigationKey.downloads],
                  navType: NavigationType.linkWithText,
                  linkTo: getLinkTo(MainRoutesEnum.downloads, user),
                  isAccessGranted: getRoute(MainRoutesEnum.downloads, MainRoutes(user)).isAccessGranted,
                  align: "left"
              },
              {
                  navigationKey: NavigationKey.admin,
                  label: lang[NavigationKey.admin],
                  navType: NavigationType.linkWithText,
                  linkTo: getLinkTo(MainRoutesEnum.admin, user),
                  isAccessGranted: getRoute(MainRoutesEnum.admin, MainRoutes(user)).isAccessGranted,
                  align: "left"
              },
              {
                  navigationKey: NavigationKey.profile,
                  navType: NavigationType.dropdownWithIcon,
                  icon: <AccountCircleIcon />,
                  selectOptions: profileMenuOption(lang, user),
                  isAccessGranted: true, //Access to everyone,
                  align: "right"
              },
              {
                  navigationKey: NavigationKey.language,
                  navType: NavigationType.dropdownWithIcon,
                  icon: <LanguageIcon />,
                  selectOptions: getSelectLanguage(LanguageOptions, langCode),
                  isAccessGranted: true, //Access to everyone,
                  align: "right"
              },
              {
                  navigationKey: NavigationKey.help,
                  navType: NavigationType.dropdownWithIcon,
                  selectOptions: helpMenuOption(lang),
                  icon: <HelpRoundedIcon />,
                  isAccessGranted: true, //Access to everyone,
                  align: "right"
              },
              {
                  navigationKey: NavigationKey.selectOrganization,
                  navType: NavigationType.openDialog,
                  isAccessGranted: user.isTalentMapAdmin,
                  align: "right"
              }
          ];
};

const Header = (): ReactElement => {
    const { logout } = useAuth0();
    const { compassContextDispatch } = useContext(CompassContext);
    const { user, setUser } = useUser();
    const { surveys } = useSurveys();
    const [updateUserSetting] = useMutation(userSettingMutation);
    const { lang, languageCode } = useLang();
    const classes = useHeaderStyles();
    const mediaMatches = useMediaQuery("(min-width:1024px)");
    const history = useHistory();
    const { selectedOrganization, selectOrganization } = useOrganization();
    const [isOrgSelectorOpen, setOrgSelectorOpen] = useState(false);
    const [openDrawer, setDrawerStat] = useState<boolean>(false);

    const logoutWithRedirect = (): void => {
        logout();
    };

    const updateOrganizationSelected = (selectedOrg: OrganizationInfo): void => {
        const settings = {
            ...user.settings,
            selectedOrganization: selectedOrg,
            filtersItems: initFilter.items,
            userDashboard: initUserDashboarSettings,
            responseRate: initResponseRateStoredData,
            selectedSurvey: [initSelectedSurvey],
            heatmap: initHeatmapRestoreValue,
            languageCode: LanguageCode.en,
            snapshot: initSnapshotSettings,
            comment: initCommentSettings,
            correlation: initCorrelationSettings,
            insight: initInsightSettings
        };
        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };
        updateUserSetting(mutationOptions);
        setUser({ settings });
        selectOrganization(selectedOrg);
        setOrgSelectorOpen(false);
        history.push("/");
    };

    const handleOpenSelectOrganization = (): void => {
        setOrgSelectorOpen(true);
    };

    const handleDrawerStat = (): void => {
        setDrawerStat(!openDrawer);
    };

    const handleSelectChange = (selectedValue: number, selectKey: string): void => {
        if (selectKey === NavigationKey.language) {
            const updateLanguage = LanguageOptions.find((language: LanguageDetail) => language.value === selectedValue);
            const updateLanguageCode = updateLanguage ? updateLanguage.code : LanguageCode.en;

            compassContextDispatch({
                type: CompassContextActions.UPDATE_LANGUAGE,
                payload: {
                    ...getTranslation(updateLanguageCode),
                    languageCode: updateLanguageCode
                }
            });

            const settings = {
                ...user.settings,
                languageCode: updateLanguageCode
            };
            const mutationOptions = {
                variables: { settings: JSON.stringify(settings) }
            };
            updateUserSetting(mutationOptions);
            setUser({ settings });
        }
        if (selectKey === NavigationKey.profile) {
            const itemSelected = profileMenuOption(lang, user).find(
                (item: SelectOptionNavigation) => item.value === selectedValue
            );
            if (itemSelected?.action === ProfileMenuAction.logout) {
                logoutWithRedirect();
            }
        }
    };

    const MenuDrawer = ({ matches }: { matches: boolean }): ReactElement => {
        return (
            <div className={classes.list} onClick={handleDrawerStat} onKeyDown={handleDrawerStat}>
                <List>
                    {navigationList(
                        user,
                        lang,
                        languageCode,
                        surveys.length > 0,
                        matches,
                        selectedOrganization.isDashboardEnabled
                    ).map(
                        (item: NavigationOption) =>
                            item.isAccessGranted && (
                                <ListItem key={item.navigationKey}>
                                    <RenderNavigationItem
                                        dataTestId={item.navigationKey}
                                        key={item.navigationKey}
                                        item={item}
                                        handleOpenSelectOrganization={handleOpenSelectOrganization}
                                        handleSelectChange={handleSelectChange}
                                    />
                                </ListItem>
                            )
                    )}
                </List>
            </div>
        );
    };

    return (
        <div className={classes.headerContainer} data-testid="header">
            {mediaMatches ? (
                navigationList(
                    user,
                    lang,
                    languageCode,
                    surveys.length > 0,
                    !mediaMatches,
                    selectedOrganization.isDashboardEnabled
                ).map(
                    (item: NavigationOption) =>
                        item.isAccessGranted && (
                            <RenderNavigationItem
                                dataTestId={item.navigationKey}
                                key={item.navigationKey}
                                item={item}
                                handleOpenSelectOrganization={handleOpenSelectOrganization}
                                handleSelectChange={handleSelectChange}
                            />
                        )
                )
            ) : (
                <div className={classes.headerMenu}>
                    <MenuIcon className={classes.iconColor} onClick={handleDrawerStat} />
                    <Drawer
                        anchor="left"
                        classes={{ paper: classes.drawerPaper }}
                        open={openDrawer}
                        onClose={handleDrawerStat}
                    >
                        <MenuDrawer matches={!mediaMatches} />
                    </Drawer>
                </div>
            )}
            {isOrgSelectorOpen && (
                <OrganizationListDialog
                    isOpen={isOrgSelectorOpen}
                    onCancel={() => setOrgSelectorOpen(false)}
                    organizationSelected={selectedOrganization}
                    onSelect={updateOrganizationSelected}
                />
            )}
        </div>
    );
};

export default Header;
