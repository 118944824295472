export enum SurveyInputKey {
    surveyLoad = "surveyLoad",
    responseLoad = "responseLoad",
    scaleLoad = "scaleLoad",
    closeDate = "closeDate",
    name = "name",
    confidentialityThreshold = "confidentialityThreshold",
    none = "none",
    isLocked = "isLocked"
}

export enum SurveySource {
    imported = "Imported",
    compass = "Compass"
}
