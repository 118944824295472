import { IRouteProps } from "../interfaces";
import { MainRoutes, AdminRoutes as AdminRoutesEnum } from "../enums";

import AdminUsers from "../../components/admin/users/AdminUsers";
import AdminResults from "../../components/admin/results/AdminResults";
import AdminOrganizations from "../../components/admin/organizations/AdminOrganizations";
import Tags from "../../components/admin/tags/Tags";
import BenchmarksAdmin from "../../components/admin/benchmarks/BenchmarksAdmin";
import BulkOverallStatus from "../../components/admin/bulkStatus/BulkOverallStatusAdmin";
import { UserInfo } from "../../components/admin/users/interface";
import AdminParticipants from "../../components/admin/participants/AdminParticipants";

export const AdminRoutes = (user: UserInfo): IRouteProps[] => {
    return [
        {
            path: `/${MainRoutes.admin}/${AdminRoutesEnum.results}`,
            exact: true,
            label: AdminRoutesEnum.results,
            routeKey: AdminRoutesEnum.results,
            component: AdminResults,
            isAccessGranted: user.isTalentMapAdmin || user.isSiteAdmin
        },
        {
            path: `/${MainRoutes.admin}/${AdminRoutesEnum.participants}`,
            exact: true,
            label: AdminRoutesEnum.participants,
            routeKey: AdminRoutesEnum.participants,
            component: AdminParticipants,
            isAccessGranted: user.isTalentMapAdmin || user.participantEnabled
        },
        {
            path: `/${MainRoutes.admin}/${AdminRoutesEnum.users}`,
            exact: true,
            label: AdminRoutesEnum.users,
            routeKey: AdminRoutesEnum.users,
            component: AdminUsers,
            isAccessGranted: user.isTalentMapAdmin || user.isSiteAdmin
        },
        {
            path: `/${MainRoutes.admin}/${AdminRoutesEnum.organizations}`,
            exact: true,
            label: AdminRoutesEnum.organizations,
            routeKey: AdminRoutesEnum.organizations,
            component: AdminOrganizations,
            isAccessGranted: user.isTalentMapAdmin
        },
        {
            path: `/${MainRoutes.admin}/${AdminRoutesEnum.tags}`,
            exact: true,
            label: AdminRoutesEnum.tags,
            routeKey: AdminRoutesEnum.tags,
            component: Tags,
            isAccessGranted: user.isTalentMapAdmin
        },
        {
            path: `/${MainRoutes.admin}/${AdminRoutesEnum.benchmarks}`,
            exact: true,
            label: AdminRoutesEnum.benchmarks,
            routeKey: AdminRoutesEnum.benchmarks,
            component: BenchmarksAdmin,
            isAccessGranted: user.isTalentMapAdmin
        },
        {
            path: `/${MainRoutes.admin}/${AdminRoutesEnum.bulkStatus}`,
            exact: true,
            label: AdminRoutesEnum.bulkStatus,
            routeKey: AdminRoutesEnum.bulkStatus,
            component: BulkOverallStatus,
            isAccessGranted: user.isTalentMapAdmin
        },
        {
            path: `/${MainRoutes.admin}`,
            routeKey: MainRoutes.admin,
            isHiddenLink: true,
            isAccessGranted: user.isTalentMapAdmin
        }
    ];
};
