import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const useStyles = makeStyles(() => ({
    scrollableBar: {
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    listItemWrap: {
        display: "flex",
        width: "100%",
        borderBottom: theme.border.main,
        flexDirection: "row",
        alignItems: "center"
    },
    listItemTextWrap: {
        width: 320,
        maxWidth: 320,
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    listItemQuestionTextWrap: {
        display: "flex",
        paddingLeft: 48
    },
    dimensionHeader: {
        display: "flex",
        borderBottom: theme.border.main,
        height: 48,
        minHeight: 48,
        maxHeight: 48
    },
    dimensionsAndItems: {
        width: "50%",
        display: "flex",
        alignItems: "center"
    },
    commentCount: {
        width: "50%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    commentCountText: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    commentCountTextIcon: {
        display: "flex",
        paddingLeft: 4
    },
    chartActions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    expandMoreIcon: {
        transform: "rotate(0deg)",
        marginRight: theme.spacing(1),
        color: theme.palette.action.active
    },
    expandLessIcon: {
        transform: "rotate(-90deg)",
        marginRight: theme.spacing(1),
        color: theme.palette.action.active
    },
    rateBarWrap: {
        display: "flex",
        paddingLeft: 16,
        width: 360
    },
    rateBar: {
        width: 300
    }
}));
