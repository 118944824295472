import React, { ReactElement, useState, useRef, useMemo, useEffect } from "react";
import { useMutation } from "@apollo/client";
import { userSettingMutation } from "api/mutations";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import NavPopover from "./NavPopover";
import { SelectOptionNavigation, NavItemDisabled } from "../header/interfaces";
import { NavigationKey } from "../header/enums";
import { useDropdownStyles } from "./styles";
import { ReportRoutes } from "routes/enums";
import { Typography } from "lib/typography";
import { theme } from "lib/theme";
import { textDarkBackgroundDisabled, textDarkBackgroundMain, textDarkBackgroundNotActive } from "lib/palette";
import { Menu } from "lib/menu";
import { Tooltip } from "lib/tooltip";
import { MenuItem } from "lib/menu-item";
import { Switch } from "lib/switch";
import { UserSettings } from "components/admin/users/interface";
import { useUser } from "core/context/user/useUser";

type Props = {
    selectOptions: SelectOptionNavigation[];
    onSelect: (selectedValue: number, selectKey: string) => void;
    navigationKey: NavigationKey;
    icon?: ReactElement;
    label?: string;
    align: "right" | "left";
    disabled?: NavItemDisabled;
    hidden?: boolean;
};

const Dropdown: React.FC<Props> = (props): ReactElement => {
    const [isOpen, setOpen] = useState(false);
    const buttonRef = useRef(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const location = useLocation();
    const disabled = props.disabled?.isDisabled || false;
    const [checked, setChecked] = useState(true);
    const { user, setUser } = useUser();
    const [updateUserSetting] = useMutation(userSettingMutation);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        if (event.target.checked === false) {
            const settings: UserSettings = {
                ...user.settings,
                startTour: event.target.checked
            };
            const mutationOptions = {
                variables: { settings: JSON.stringify(settings) }
            };
            updateUserSetting(mutationOptions).then(() => {
                setUser({ settings });
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            });
        } else {
            const settings: UserSettings = {
                ...user.settings,
                startTour: event.target.checked
            };
            const mutationOptions = {
                variables: { settings: JSON.stringify(settings) }
            };
            updateUserSetting(mutationOptions);
            setUser({ settings });
        }
    };

    const selected = useMemo(() => {
        return (
            location.pathname.includes(ReportRoutes.responseRate) ||
            location.pathname.includes(ReportRoutes.snapshot) ||
            location.pathname.includes(ReportRoutes.orgChart)
        );
    }, [location.pathname]);

    const styleProps = {
        alignTo: props.align,
        disabled,
        selected,
        hidden: props.hidden ? props.hidden : false
    };
    const classes = useDropdownStyles(styleProps);
    const openClose = (): void => {
        setOpen((prevState: boolean) => !prevState);
    };

    const handleOnClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>): void => {
        props.onSelect(event.currentTarget.value, event.currentTarget.title);

        if (props.navigationKey === NavigationKey.language) {
            setAnchorEl(null);
            setOpen(false);
        }
    };

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = (): void => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (user && user.settings && user.settings.startTour !== undefined && user.settings.startTour !== null) {
            setChecked(user.settings.startTour);
        }
    }, []);

    return (
        <>
            {props.disabled?.isDisabled ? (
                <div
                    className={classes.menuButton}
                    ref={buttonRef}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    data-testid={"disabled-header-" + props.label?.toLocaleLowerCase()}
                >
                    {props.icon && !props.label ? (
                        props.icon
                    ) : (
                        <Typography className={classes.textColor} variant="body2">
                            {props.label}
                        </Typography>
                    )}
                </div>
            ) : (
                <div
                    className={classes.menuButton}
                    onClick={openClose}
                    ref={buttonRef}
                    data-testid={
                        props.label
                            ? "enabled-header-" + props.label.toLocaleLowerCase()
                            : "enabled-header-" + props.navigationKey.toLocaleLowerCase()
                    }
                >
                    {props.icon && !props.label ? (
                        props.icon
                    ) : (
                        <Typography
                            sx={{
                                color: disabled
                                    ? textDarkBackgroundDisabled
                                    : selected
                                      ? textDarkBackgroundMain
                                      : textDarkBackgroundNotActive,
                                fontWeight: selected
                                    ? theme.typography.fontWeightBold
                                    : theme.typography.fontWeightRegular
                            }}
                            variant="body2"
                        >
                            {props.label}
                        </Typography>
                    )}
                </div>
            )}
            <Menu
                id={props.navigationKey}
                anchorEl={buttonRef.current}
                open={isOpen}
                onClose={openClose}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
            >
                {props.selectOptions.map((option: SelectOptionNavigation, index: number) =>
                    option.disabled && option.disabledMsg ? (
                        <Tooltip title={option.disabledMsg} key={index}>
                            <div>
                                <MenuItem
                                    onClick={handleOnClick}
                                    selected={option.selected}
                                    value={option.value}
                                    title={props.navigationKey}
                                    style={{ display: option.hidden ? "none" : "", minWidth: 200, color: "black" }}
                                    disabled={option.disabled}
                                >
                                    <Typography variant="body1">{option.label}</Typography>
                                </MenuItem>
                            </div>
                        </Tooltip>
                    ) : (
                        <MenuItem
                            key={index}
                            onClick={handleOnClick}
                            selected={option.selected || location.pathname === option.linkTo}
                            value={option.value}
                            title={props.navigationKey}
                            style={{ display: option.hidden ? "none" : "", minWidth: 200, color: "black" }}
                            data-testid={"header-dropdown-" + option.label}
                        >
                            {option.linkTo ? (
                                <Link
                                    style={{ color: "black" }}
                                    className={classes.link}
                                    to={option.linkTo}
                                    onClick={openClose}
                                >
                                    <Typography variant="body1">{option.label}</Typography>
                                </Link>
                            ) : option.hyperLinkTo ? (
                                <a
                                    style={{ color: "black" }}
                                    className={classes.link}
                                    href={option.hyperLinkTo}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onBlur={openClose}
                                >
                                    {option.label}
                                </a>
                            ) : option.switchTour ? (
                                <Switch label={option.label} checked={checked} onChange={handleChange} />
                            ) : (
                                option.label
                            )}
                        </MenuItem>
                    )
                )}
            </Menu>
            {Boolean(anchorEl) && (
                <NavPopover
                    isOpen={Boolean(anchorEl)}
                    anchorEl={anchorEl}
                    onPopoverClose={handlePopoverClose}
                    message={props.disabled ? props.disabled.message : ""}
                />
            )}
        </>
    );
};

export default Dropdown;
