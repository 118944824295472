import { useMutation } from "@apollo/client";

import { useUser } from "core/context/user/useUser";
import { initFilter } from "components/filters/inits";
import { initBreakdownItems } from "components/reports/responseRate/init";
import { userSettingMutation } from "api/mutations";
import { Filter } from "components/filters/interfaces";
import { UserSettings } from "components/admin/users/interface";
import { HeatmapRestoreValue } from "components/reports/heatmap/interface";
import { SnapshotRestoreData } from "components/admin/users/interface/AdminUser.interfaces";

type ReturnType = {
    setInitUserSetting: (id: number) => void;
    setUserSettingAfterFilterApplied: (filter: Filter) => void;
    setKeyDriverInitUserSetting: (id: number, selectedOutcomeId: number, selectedKeyDriverId: number) => void;
};

export const useUpdateUserSettings = (): ReturnType => {
    const { user, setUser } = useUser();
    const [updateUserSetting] = useMutation(userSettingMutation);
    const initHeatmapSettings = (id: number): HeatmapRestoreValue => {
        return {
            surveySelected: id,
            breakdownItems: initBreakdownItems.items,
            selectedCompareOptionID: 0,
            selectedCompareTargetID: -1,
            selectedCompareTargetName: "",
            swapAxes: true,
            compareToPrevious: false,
            showFavorable: false
        };
    };
    const initSnapshotSettings = (id: number): SnapshotRestoreData => {
        return {
            id: id,
            orgId: -1,
            showOrgOverview: false,
            showOtherComparison: false,
            otherComparisonSurveyId: -1,
            otherComparisonFilter: initFilter.items,
            lookdownItems: [],
            heatmapLookdownItems: [],
            columnLabel: "",
            rrLookdownItems: [],
            keyDriversLookdownItems: [],
            customizedShowBenchmark: true,
            customizedShowPP: true,
            exportTitle: "",
            showOverallCommentsSlide: true,
            includeLookupOrgOverall: false
        };
    };

    const setInitUserSetting = (id: number): void => {
        const settings: UserSettings = {
            ...user.settings,
            filtersItems: initFilter.items,
            heatmap: initHeatmapSettings(id),
            userDashboard: { surveySelected: id },
            snapshot: initSnapshotSettings(id),
            comment: {
                surveySelected: id,
                itemIds: [],
                typeOfSearch: 1,
                searchTerm: "",
                exportIncludeWordCloud: false
            },
            correlation: {
                surveySelected: id
            },
            insight: {
                surveySelected: id,
                selectedOutcome: -1,
                selectedKeyDriver: -1,
                selectedDemographicField: "",
                showOrgOverall: false,
                customizedShowPP: true
            }
        };
        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };
        updateUserSetting(mutationOptions);
        setUser({ settings });
    };

    const setKeyDriverInitUserSetting = (id: number, selectedOutcomeId: number, selectedKeyDriverId: number): void => {
        const settings: UserSettings = {
            ...user.settings,
            filtersItems: initFilter.items,
            heatmap: initHeatmapSettings(id),
            userDashboard: {
                surveySelected: id
            },
            snapshot: initSnapshotSettings(id),
            comment: {
                surveySelected: id,
                itemIds: [],
                typeOfSearch: 1,
                searchTerm: "",
                exportIncludeWordCloud: false
            },
            correlation: {
                surveySelected: id
            },
            insight: {
                surveySelected: id,
                selectedOutcome: selectedOutcomeId,
                selectedKeyDriver: selectedKeyDriverId,
                selectedDemographicField: "",
                showOrgOverall: false,
                customizedShowPP: true
            }
        };
        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };
        updateUserSetting(mutationOptions);
        setUser({ settings });
    };

    const setUserSettingAfterFilterApplied = (filter: Filter): void => {
        const settings = {
            ...user.settings,
            filtersItems: filter.items
        };
        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };
        updateUserSetting(mutationOptions);
        setUser({ settings });
    };

    return { setInitUserSetting, setUserSettingAfterFilterApplied, setKeyDriverInitUserSetting };
};
