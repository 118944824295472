import React, { ReactElement, useEffect, useReducer, useState } from "react";
import { makeStyles } from "@mui/styles";
import { useMutation, useLazyQuery } from "@apollo/client";
import * as _ from "lodash";
import { theme } from "lib/theme";
import {
    useLang,
    useSurveys,
    useOrgChartService,
    useLoading,
    useSnackbar,
    useQueryWithPromise,
    useUpdateUserSettings
} from "core/hooks";
import NoSurveySelected from "assets/images/emptyPages/ReportingEmptyState.svg";
import Error500 from "../../errorPage/Error500";
import { emptySurveyInfo } from "components/admin/results/init";
import { SurveyInfo } from "components/admin/results/interfaces";
import { HrisFieldInfo, SnapshotResult } from "managerPortal/interfaces";
import { Filter } from "components/filters/interfaces";
import { initFilter } from "components/filters/inits";
import { ComponentStatus } from "core/enums";
import Filters from "components/filters/Filters";
import { Card } from "managerPortal/components/cards/interfaces";
import { initCard } from "managerPortal/components/cards/init";
import { FilterMode, FilterType } from "components/filters/enums";
import { ConfidentialityResult } from "managerPortal/components/snapshotReport/enums";
import { snapshotReducer } from "./reducer/Snapshot.reducer";
import { SnapshotActions } from "./action/Snapshot.action";
import { SurveySection } from "managerPortal/context/interfaces";
import { initSurveySection } from "managerPortal/context/initialState";
import EmptyPage from "components/shared/EmptyPage";
import SnapshotSectionDetails from "managerPortal/components/snapshotReport/content/SnapshotSectionDetails";
import { DialogExportSlideWithLookdown } from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { DemographicOptions } from "../heatmap/interface";
import { defaultDemographicOption } from "./Snapshot.init";
import { SnapshotComparisonPopup } from "managerPortal/components/snapshotReport/SnapshotComparisonPopup";
import { userSettingMutation } from "api/mutations";
import { demographicFieldsAndValuesQuery } from "api/queries";
import {
    sortArray,
    OtherCompareFilterMapper,
    mapSurveysToAutocompleteOption,
    getMapSurveySelected
} from "core/helpers";
import ShowNotEnoughResponsesMessage from "managerPortal/components/snapshotReport/ShowNotEnoughResponsesMessage";
import { UserSettings } from "components/admin/users/interface";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import {
    LookdownItemExport,
    LookdownItem,
    heatmapLookdownItem,
    rrLookdownItem,
    KeyDriversLookdownItem,
    keyDriversExport
} from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { ReportsLayout } from "../layout/ReportsLayout";
import { BulkExportDemographic } from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { BulkReportType } from "api/rest/BulkReportStatus";
import { Snackbar } from "lib/snackbar";
import dayjs from "dayjs";
import { useUser } from "core/context/user/useUser";
import ShowBelowConfidentialityMessageWithCountAndFilter from "../comment/ShowBelowConfidentialityMessageWithCountAndFilter";
import ShowConfidentialtyMessageWithCountAndFilter from "managerPortal/components/snapshotReport/ShowConfidentialtyMessageWithCountAndFilter";

const useStyles = makeStyles(() => ({
    emptyPage: {
        gridArea: "responseContent",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center"
    },
    contentContainer: {
        display: "flex",
        height: "100%"
    },
    snapshotContent: {
        width: "100%"
    },
    lastUpdated: {
        height: 48,
        display: "flex",
        alignItems: "center",
        paddingRight: theme.spacing(3)
    }
}));

export interface CompareGroup {
    id: number;
    name: string;
}

export interface State {
    selectedSurvey: SurveyInfo;
    status: ComponentStatus;
    currentFilter: Filter;
    isFilterAllowed: boolean;
    isFilterDialogOpen: boolean;
    snapshotData: Card;
    isExportDialogOpen: boolean;
    confidentiality: ConfidentialityResult;
    selectedSurveySections: SurveySection[];
    surveySections: SurveySection[];
    lastUpdate: string;
    showOrgOverall: boolean;
    showOtherComparison: boolean;
    compareOptions: DemographicOptions[];
    showConfirmationDialog: boolean;
    otherComparisonSurveyId: number;
    otherComparisonFilter: Filter;
    columnLabel: string;
    customizedShowBenchmark: boolean;
    customizedShowPP: boolean;
    isBulkExport: boolean;
    snackBar: { isOpen: boolean; message: string };
    compareGroupIds: CompareGroup[];
}

const initialState: State = {
    selectedSurvey: emptySurveyInfo,
    status: ComponentStatus.idle,
    currentFilter: initFilter,
    isFilterAllowed: true,
    isFilterDialogOpen: false,
    snapshotData: initCard,
    isExportDialogOpen: false,
    confidentiality: ConfidentialityResult.success,
    selectedSurveySections: initSurveySection,
    surveySections: [],
    lastUpdate: dayjs().format("LLL"),
    showOrgOverall: false,
    showOtherComparison: false,
    compareOptions: [],
    showConfirmationDialog: false,
    otherComparisonSurveyId: emptySurveyInfo.id,
    otherComparisonFilter: initFilter,
    columnLabel: "",
    customizedShowBenchmark: true,
    customizedShowPP: true,
    isBulkExport: false,
    snackBar: { isOpen: false, message: "" },
    compareGroupIds: []
};

const Snapshot = (): ReactElement => {
    const { user, setUser } = useUser();
    const orgChartService = useOrgChartService();
    const { lang, languageCode } = useLang();
    const { surveys } = useSurveys();
    const { setInitUserSetting, setUserSettingAfterFilterApplied } = useUpdateUserSettings();
    const { setLoading } = useLoading();
    const classes = useStyles();
    const { setMessage } = useSnackbar();
    const [state, dispatch] = useReducer(snapshotReducer, initialState);
    const [showFavorableUnfavorableOnly, setFavorableUnfavorableOnly] = useState(false);
    const [preSelection, setPreSelection] = useState<{
        showOrgOverall: boolean;
        showOtherComparison: boolean;
        columnLabel: string;
        customizedShowBenchmark: boolean;
        customizedShowPP: boolean;
        otherComparisonSurveyId: number;
        otherComparisonFilter: Filter;
    }>({
        showOrgOverall: state.showOrgOverall,
        showOtherComparison: state.showOtherComparison,
        columnLabel: state.columnLabel,
        customizedShowBenchmark: state.customizedShowBenchmark,
        customizedShowPP: state.customizedShowPP,
        otherComparisonSurveyId: state.otherComparisonSurveyId,
        otherComparisonFilter: state.otherComparisonFilter
    });
    const [bulkExportDemographic, setBulkExportDemographic] = useState<BulkExportDemographic>({
        demographicFieldId: -1,
        demographicField: ""
    });
    const [contactFields, setContactFields] = useState<HrisFieldInfo[]>([]);
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const fetchHris = useQueryWithPromise(demographicFieldsAndValuesQuery);

    let hrisList: HrisFieldInfo[] = [];
    const showPP =
        state.snapshotData.previousPeriod &&
        state.snapshotData.previousPeriod.length > 0 &&
        state.snapshotData.previousPeriod.filter(s => !_.isEmpty(s.values)).length > 0;
    const showBenchmarks = state.snapshotData.benchmark;
    const mapSurveys = mapSurveysToAutocompleteOption([...surveys, emptySurveyInfo]);
    const selectedMapSurvey = getMapSurveySelected(mapSurveys, state.selectedSurvey.id);

    const [loadDemographics] = useLazyQuery(demographicFieldsAndValuesQuery, {
        onCompleted: data => {
            if (data.demographicFieldsAndValues) {
                hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);

                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                setContactFields(sortedDemographic);
                const compareOptions =
                    sortedDemographic.length === 1
                        ? [defaultDemographicOption]
                        : [
                              defaultDemographicOption,

                              ...sortedDemographic.map((contactField: HrisFieldInfo) => {
                                  return {
                                      name: contactField.fieldName,
                                      id: contactField.fieldId,
                                      values: contactField.fieldValues
                                  };
                              })
                          ];

                dispatch({
                    type: SnapshotActions.SET_COMPARE_OPTIONS,
                    payload: { compareOptions }
                });
            }
        }
    });

    const [updateUserSetting] = useMutation(userSettingMutation);

    const initSnapshotData = (
        filter: Filter,
        surveyId: number,
        showOrgOverall: boolean,
        showOtherComparison: boolean,
        customizedShowBenchmark: boolean,
        customizedShowPP: boolean,
        otherComparisonSurveyId: number,
        otherComparisonFilter: Filter,
        columnLabel: string
    ): void => {
        dispatch({
            type: SnapshotActions.SET_STATUS,
            payload: { status: ComponentStatus.loading }
        });
        if (surveyId === emptySurveyInfo.id) {
            dispatch({
                type: SnapshotActions.SET_STATUS,
                payload: { status: ComponentStatus.idle }
            });
            return;
        }

        orgChartService
            //no hierarchyId and cardId on page
            .snapshotReport(
                0,
                0,
                filter.items,
                surveyId,
                user.settings.languageCode,
                showOrgOverall,
                showOtherComparison,
                OtherCompareFilterMapper(otherComparisonSurveyId, columnLabel, otherComparisonFilter),
                !customizedShowBenchmark,
                !customizedShowPP
            )
            .then((response: SnapshotResult) => {
                const surveySelected = surveys.find(s => s.id === surveyId);
                //uncheck show overall when data not returned
                let shouldShowOverall = false;
                //response.data could be null when confidentiality fail
                if (response.data) {
                    shouldShowOverall = true;
                }

                const payload = {
                    snapshotData: response.filterAllowed
                        ? {
                              ...response.data,
                              title: surveySelected
                                  ? surveySelected.translations[0].name
                                  : state.selectedSurvey.translations[0].name
                          }
                        : {
                              ...initCard,
                              title: state.snapshotData.title,
                              id: state.snapshotData.id,
                              response_count: response.data.response_count
                          },
                    isFilterAllowed:
                        response.filterAllowed && response.data.response_count >= response.confidentialityResult,
                    confidentiality: response.confidentialityResult,
                    currentFilter: filter,
                    surveySections: response.filterAllowed ? response.surveySections : [],
                    lastUpdate: response.lastUpdate ? dayjs(response.lastUpdate).format("LLL") : dayjs().format("LLL"),
                    showOrgOverall: shouldShowOverall ? showOrgOverall : false,
                    customizedShowBenchmark: customizedShowBenchmark,
                    customizedShowPP: customizedShowPP,
                    status: ComponentStatus.idle,
                    compareGroupIds: response.compareGroupIds
                };

                if (response.confidentialityResult === ConfidentialityResult.compareGroupBelowThreshold) {
                    dispatch({
                        type: SnapshotActions.SHOW_CONFIRMATION_DIALOG,
                        payload: { showConfirmationDialog: true }
                    });
                }

                //save user settings
                setInitUserSetting(surveyId);

                dispatch({
                    type: SnapshotActions.DATA_ONLOAD,
                    payload
                });
            })
            .catch(() => {
                setMessage(lang.somethingWentWrong);
                dispatch({
                    type: SnapshotActions.SET_STATUS,
                    payload: { status: ComponentStatus.error }
                });
            });
    };

    const updateSnapshotData = (
        filter: Filter,
        surveyId: number,
        showOrgOverall: boolean,
        showOtherComparison: boolean,
        lookdownItems: LookdownItem[],
        heatmapLookdownItems: heatmapLookdownItem[],
        columnLabel: string,
        rrLookdownItems: rrLookdownItem[],
        kdLookdownItems: KeyDriversLookdownItem[],
        customizedShowBenchmark: boolean,
        customizedShowPP: boolean,
        otherComparisonSurveyId: number,
        otherComparisonFilter: Filter,
        setUserSetting: boolean
    ): void => {
        dispatch({
            type: SnapshotActions.SET_STATUS,
            payload: { status: ComponentStatus.loading }
        });
        if (surveyId === emptySurveyInfo.id) return;

        orgChartService
            //no hierarchyId and cardId on page
            .snapshotReport(
                0,
                0,
                filter.items,
                surveyId,
                user.settings.languageCode,
                showOrgOverall,
                showOtherComparison,
                OtherCompareFilterMapper(otherComparisonSurveyId, columnLabel, otherComparisonFilter),
                !customizedShowBenchmark,
                !customizedShowPP
            )
            .then((response: SnapshotResult) => {
                const surveySelected = surveys.find(s => s.id === surveyId);
                //uncheck show overall when data not returned
                let shouldShowOverall = false;
                //response.data could be null when confidentiality fail
                if (response.data) {
                    shouldShowOverall = true;
                }

                const payload = {
                    snapshotData: response.filterAllowed
                        ? {
                              ...response.data,
                              title: surveySelected
                                  ? surveySelected.translations[0].name
                                  : state.selectedSurvey.translations[0].name
                          }
                        : {
                              ...initCard,
                              title: state.snapshotData.title,
                              id: state.snapshotData.id,
                              response_count: response.data.response_count
                          },
                    isFilterAllowed:
                        response.filterAllowed && response.data.response_count >= response.confidentialityResult,
                    confidentiality: response.confidentialityResult,
                    currentFilter: filter,
                    surveySections: response.filterAllowed ? response.surveySections : [],
                    lastUpdate: response.lastUpdate ? dayjs(response.lastUpdate).format("LLL") : dayjs().format("LLL"),
                    showOrgOverall: shouldShowOverall ? showOrgOverall : false,
                    customizedShowBenchmark: customizedShowBenchmark,
                    customizedShowPP: customizedShowPP,
                    status: ComponentStatus.idle,
                    compareGroupIds: response.compareGroupIds
                };

                if (response.confidentialityResult === ConfidentialityResult.compareGroupBelowThreshold) {
                    dispatch({
                        type: SnapshotActions.SHOW_CONFIRMATION_DIALOG,
                        payload: { showConfirmationDialog: true }
                    });
                }

                //save user settings
                if (setUserSetting) {
                    setUserSettingAfterFilterApplied(filter);
                } else {
                    const updatedSettings = {
                        ...user.settings,
                        filtersItems: filter.items,
                        snapshot: {
                            ...user.settings.snapshot,
                            surveyId,
                            orgId: user.settings.selectedOrganization.id,
                            showOrgOverview: shouldShowOverall ? showOrgOverall : false,
                            showOtherComparison: showOtherComparison,
                            lookdownItems: lookdownItems,
                            heatmapLookdownItems: heatmapLookdownItems,
                            columnLabel: columnLabel,
                            rrLookdownItems: rrLookdownItems,
                            keyDriversLookdownItems: kdLookdownItems,
                            customizedShowBenchmark: customizedShowBenchmark,
                            customizedShowPP: customizedShowPP,
                            exportTitle: surveySelected
                                ? surveySelected.translations[0].name
                                : state.selectedSurvey.translations[0].name,
                            otherComparisonSurveyId: otherComparisonSurveyId,
                            otherComparisonFilter: otherComparisonFilter.items
                        }
                    };
                    updateUserSettings(updatedSettings);
                }

                dispatch({
                    type: SnapshotActions.DATA_ONLOAD,
                    payload
                });
            })
            .catch(() => {
                setMessage(lang.somethingWentWrong);
                dispatch({
                    type: SnapshotActions.SET_STATUS,
                    payload: { status: ComponentStatus.error }
                });
            });
    };

    const updateUserSettings = (settings: UserSettings): void => {
        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };
        updateUserSetting(mutationOptions);
        setUser({ settings });
    };
    const handleSurveySelected = (id: number): void => {
        const selectedSurvey = surveys.find((survey: SurveyInfo) => survey.id === id);
        if (selectedSurvey && selectedSurvey.id !== state.selectedSurvey.id) {
            dispatch({
                type: SnapshotActions.SELECT_SURVEY,
                payload: {
                    selectedSurvey,
                    currentFilter: initFilter,
                    surveySections: [],
                    snapshotData: initCard,
                    confidentiality: ConfidentialityResult.success,
                    lastUpdate: dayjs().format("LLL"),
                    showOrgOverall: false,
                    isFilterAllowed: true,
                    selectedSurveySections: initSurveySection,
                    showOtherComparison: false,
                    otherComparisonSurveyId: emptySurveyInfo.id,
                    otherComparisonFilter: initFilter,
                    columnLabel: "",
                    customizedShowBenchmark: true,
                    customizedShowPP: true
                }
            });

            setPreSelection({
                showOrgOverall: false,
                showOtherComparison: false,
                columnLabel: "",
                customizedShowBenchmark: true,
                customizedShowPP: true,
                otherComparisonSurveyId: emptySurveyInfo.id,
                otherComparisonFilter: initFilter
            });
            initSnapshotData(
                initFilter,
                selectedSurvey.id,
                false,
                false,
                true,
                true,
                emptySurveyInfo.id,
                initFilter,
                ""
            );
            loadDemographics({ variables: { surveyId: id, languageCode } });
        }
    };

    const restoreUserSettings = async (snapshotSetting: UserSettings["snapshot"]): Promise<void> => {
        if (
            snapshotSetting.id &&
            snapshotSetting.id !== emptySurveyInfo.id &&
            mapSurveys.map(s => s.id).includes(user.settings.snapshot.id)
        ) {
            const selectedSurvey = surveys.find((survey: SurveyInfo) => survey.id === snapshotSetting.id);
            if (selectedSurvey) {
                const loadedHris = await fetchHris({ surveyId: selectedSurvey.id, languageCode });
                if (loadedHris) {
                    const { data } = loadedHris;
                    hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);
                    const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                    setContactFields(sortedDemographic);
                    const compareOptions =
                        sortedDemographic.length === 1
                            ? [defaultDemographicOption]
                            : [
                                  defaultDemographicOption,
                                  ...sortedDemographic.map((contactField: HrisFieldInfo) => {
                                      return {
                                          name: contactField.fieldName,
                                          id: contactField.fieldId,
                                          values: contactField.fieldValues
                                      };
                                  })
                              ];
                    dispatch({
                        type: SnapshotActions.SET_COMPARE_OPTIONS,
                        payload: { compareOptions }
                    });
                }

                let restoredFilter = user.settings.filtersItems
                    ? { ...initFilter, items: user.settings.filtersItems }
                    : initFilter;
                restoredFilter.items
                    .filter(item => item.filterType === FilterType.contactField)
                    .forEach(item => {
                        if (!hrisList.map(list => list.fieldId).includes(item.field.id as number)) {
                            restoredFilter = initFilter;
                        }
                    });

                setPreSelection({
                    ...preSelection,
                    showOrgOverall:
                        snapshotSetting.showOrgOverview === undefined ? false : snapshotSetting.showOrgOverview,
                    showOtherComparison:
                        snapshotSetting.showOtherComparison === undefined ? false : snapshotSetting.showOtherComparison,
                    columnLabel: snapshotSetting.columnLabel === undefined ? "" : snapshotSetting.columnLabel,
                    customizedShowBenchmark:
                        snapshotSetting.customizedShowBenchmark === undefined
                            ? true
                            : snapshotSetting.customizedShowBenchmark,
                    customizedShowPP:
                        snapshotSetting.customizedShowPP === undefined ? true : snapshotSetting.customizedShowPP,
                    otherComparisonSurveyId:
                        snapshotSetting.otherComparisonSurveyId === undefined
                            ? emptySurveyInfo.id
                            : snapshotSetting.otherComparisonSurveyId,
                    otherComparisonFilter:
                        snapshotSetting.otherComparisonFilter === undefined
                            ? initFilter
                            : { ...initFilter, items: snapshotSetting.otherComparisonFilter }
                });

                dispatch({
                    type: SnapshotActions.SELECT_SURVEY,
                    payload: {
                        selectedSurvey,
                        currentFilter: restoredFilter,
                        surveySections: [],
                        snapshotData: initCard,
                        confidentiality: ConfidentialityResult.success,
                        lastUpdate: dayjs().format("LLL"),
                        showOrgOverall: snapshotSetting.showOrgOverview ? false : snapshotSetting.showOrgOverview,
                        isFilterAllowed: true,
                        selectedSurveySections: initSurveySection,
                        showOtherComparison:
                            snapshotSetting.showOtherComparison === undefined
                                ? false
                                : snapshotSetting.showOtherComparison,
                        columnLabel: snapshotSetting.columnLabel === undefined ? "" : snapshotSetting.columnLabel,
                        otherComparisonSurveyId:
                            snapshotSetting.otherComparisonSurveyId === undefined
                                ? emptySurveyInfo.id
                                : snapshotSetting.otherComparisonSurveyId,
                        otherComparisonFilter: snapshotSetting.otherComparisonFilter
                            ? { ...initFilter, items: snapshotSetting.otherComparisonFilter }
                            : initFilter
                    }
                });

                updateSnapshotData(
                    restoredFilter,
                    selectedSurvey.id,
                    snapshotSetting.showOrgOverview === undefined ? false : snapshotSetting.showOrgOverview,
                    snapshotSetting.showOtherComparison === undefined ? false : snapshotSetting.showOtherComparison,
                    snapshotSetting.lookdownItems === undefined ? [] : snapshotSetting.lookdownItems,
                    snapshotSetting.heatmapLookdownItems === undefined ? [] : snapshotSetting.heatmapLookdownItems,
                    snapshotSetting.columnLabel === undefined ? "" : snapshotSetting.columnLabel,
                    snapshotSetting.rrLookdownItems === undefined ? [] : snapshotSetting.rrLookdownItems,
                    snapshotSetting.keyDriversLookdownItems === undefined
                        ? []
                        : snapshotSetting.keyDriversLookdownItems,
                    snapshotSetting.customizedShowBenchmark === undefined
                        ? true
                        : snapshotSetting.customizedShowBenchmark,
                    snapshotSetting.customizedShowPP === undefined ? true : snapshotSetting.customizedShowPP,
                    snapshotSetting.otherComparisonSurveyId === undefined
                        ? emptySurveyInfo.id
                        : snapshotSetting.otherComparisonSurveyId,
                    snapshotSetting.otherComparisonFilter
                        ? { ...initFilter, items: snapshotSetting.otherComparisonFilter }
                        : initFilter,
                    false
                );
            }
        }
    };

    const handleStartExport = (isBulkExport: boolean): void => {
        dispatch({
            type: SnapshotActions.SET_BULK_EXPORT,
            payload: { isBulkExport: isBulkExport }
        });
        dispatch({
            type: SnapshotActions.OPEN_EXPORT,
            payload: { isExportDialogOpen: true }
        });
    };

    const handleOpenFilter = (): void => {
        dispatch({
            type: SnapshotActions.OPEN_FILTER,
            payload: { isFilterDialogOpen: true }
        });
    };

    const handleCloseFilters = (): void => {
        dispatch({
            type: SnapshotActions.OPEN_FILTER,
            payload: { isFilterDialogOpen: false }
        });
    };
    const setFilters = (updateFilter?: Filter): void => {
        dispatch({
            type: SnapshotActions.SET_FILTER,
            payload: { currentFilter: updateFilter ?? initFilter }
        });

        updateSnapshotData(
            updateFilter ?? initFilter,
            state.selectedSurvey.id,
            state.showOrgOverall,
            state.showOtherComparison,
            user.settings.snapshot.lookdownItems,
            user.settings.snapshot.heatmapLookdownItems,
            state.columnLabel,
            user.settings.snapshot.rrLookdownItems,
            user.settings.snapshot.keyDriversLookdownItems,
            state.customizedShowBenchmark,
            state.customizedShowPP,
            state.otherComparisonSurveyId,
            state.otherComparisonFilter,
            true
        );
    };

    const handleSelectSection = (sectionId: SurveySection["sectionId"]): void => {
        const section = state.surveySections.find(s => s.sectionId === sectionId);
        dispatch({ type: SnapshotActions.SELECT_SURVEY_SECTION, payload: { selectedSurveySection: section } });
    };
    const handleUnSelectSection = (sectionId: SurveySection["sectionId"]): void => {
        const section = state.surveySections.find(s => s.sectionId === sectionId);
        dispatch({ type: SnapshotActions.UNSELECT_SURVEY_SECTION, payload: { unSelectedSurveySection: section } });
    };

    const handleExportDialogClose = (): void => {
        dispatch({
            type: SnapshotActions.OPEN_EXPORT,
            payload: { isExportDialogOpen: false }
        });
    };

    const handleApplyComparison = (): void => {
        dispatch({
            type: SnapshotActions.APPLY_PRESELECTION,
            payload: {
                showOrgOverall: preSelection.showOrgOverall,
                showOtherComparison: preSelection.showOtherComparison,
                columnLabel: preSelection.columnLabel === undefined ? "" : preSelection.columnLabel,
                customizedShowBenchmark: preSelection.customizedShowBenchmark,
                customizedShowPP: preSelection.customizedShowPP,
                otherComparisonSurveyId: preSelection.otherComparisonSurveyId,
                otherComparisonFilter: preSelection.otherComparisonFilter
            }
        });
        updateSnapshotData(
            state.currentFilter,
            state.selectedSurvey.id,
            preSelection.showOrgOverall,
            preSelection.showOtherComparison,
            user.settings.snapshot.lookdownItems,
            user.settings.snapshot.heatmapLookdownItems,
            preSelection.columnLabel === undefined ? "" : preSelection.columnLabel,
            user.settings.snapshot.rrLookdownItems,
            user.settings.snapshot.keyDriversLookdownItems,
            preSelection.customizedShowBenchmark,
            preSelection.customizedShowPP,
            preSelection.otherComparisonSurveyId,
            preSelection.otherComparisonFilter,
            false
        );
        setAnchorEl(null);
    };

    const messageComposer = (msg: string, fileName: string): string => {
        const index = msg.indexOf(".");
        return msg.slice(0, index) + fileName + msg.slice(index);
    };

    const onStartBulkExport = (mainTitle: string, showOverallCommentsSlide: boolean): void => {
        handleExportDialogClose();
        dispatch({
            type: SnapshotActions.SNACKBAR_OPEN_CLOSE,
            payload: { snackBar: { isOpen: true, message: lang.startSnapshotBulkExport } }
        });
        orgChartService
            .startBulkExport(
                state.selectedSurvey.id,
                bulkExportDemographic.demographicFieldId,
                languageCode,
                BulkReportType.Snapshot,
                {
                    ...user.settings,
                    snapshot: {
                        ...user.settings.snapshot,
                        exportTitle: mainTitle,
                        showOverallCommentsSlide: showOverallCommentsSlide
                    }
                }
            )
            .then(() => {
                const updatedSettings = {
                    ...user.settings,
                    snapshot: {
                        ...user.settings.snapshot,
                        exportTitle: mainTitle,
                        showOverallCommentsSlide: showOverallCommentsSlide
                    }
                };
                updateUserSettings(updatedSettings);
            });
    };

    const handleUpdateBulkExportDemographic = (updatedBulkExport: BulkExportDemographic): void => {
        setBulkExportDemographic(updatedBulkExport);
    };

    const handleExport = (
        mainTitle: string,
        secondaryTitle: string,
        lookdownItems: LookdownItemExport[],
        heatmapFieldIds: number[],
        rrFieldIds: number[],
        keyDrivers: keyDriversExport[],
        showOverallCommentsSlide: boolean,
        heatmapCompareToOptions: number[],
        includeLookupOrgOverall: boolean
    ): void => {
        if (!state.isBulkExport) {
            handleExportDialogClose();
            setMessage(messageComposer(lang.snapshotExportMsg[0], mainTitle), true);
            orgChartService
                .exportSnapshotReport(
                    state.selectedSurvey.id,
                    mainTitle,
                    secondaryTitle,
                    0,
                    0,
                    state.currentFilter.items,
                    languageCode,
                    state.showOrgOverall,
                    state.showOtherComparison,
                    lookdownItems,
                    heatmapFieldIds,
                    state.columnLabel,
                    rrFieldIds,
                    keyDrivers,
                    !state.customizedShowBenchmark,
                    !state.customizedShowPP,
                    showOverallCommentsSlide,
                    heatmapCompareToOptions,
                    OtherCompareFilterMapper(
                        state.otherComparisonSurveyId,
                        state.columnLabel,
                        state.otherComparisonFilter
                    ),
                    includeLookupOrgOverall
                )
                .then(() => {
                    const updatedSettings = {
                        ...user.settings,
                        snapshot: {
                            ...user.settings.snapshot,
                            showOverallCommentsSlide: showOverallCommentsSlide
                        }
                    };
                    updateUserSettings(updatedSettings);
                    setMessage(messageComposer(lang.snapshotExportMsg[1], mainTitle));
                })
                .catch(() => {
                    setMessage(lang.somethingWentWrong);
                });
        } else {
            // snapshot bulk export
            onStartBulkExport(mainTitle, showOverallCommentsSlide);
        }
    };

    const handleExcelExport = (): void => {
        orgChartService
            .exportSnapshotExcelReport(
                state.selectedSurvey.id,
                0,
                0,
                state.currentFilter.items,
                languageCode,
                state.showOrgOverall,
                state.showOtherComparison,
                !state.customizedShowBenchmark,
                !state.customizedShowPP,
                OtherCompareFilterMapper(state.otherComparisonSurveyId, state.columnLabel, state.otherComparisonFilter)
            )
            .catch(() => {
                setMessage(lang.somethingWentWrong);
            });
    };

    const handleClosePopover = (): void => {
        setPreSelection({
            ...preSelection,
            showOrgOverall: state.showOrgOverall,
            showOtherComparison: state.showOtherComparison,
            columnLabel: state.columnLabel,
            customizedShowBenchmark: state.customizedShowBenchmark,
            customizedShowPP: state.customizedShowPP,
            otherComparisonSurveyId: state.otherComparisonSurveyId,
            otherComparisonFilter: state.otherComparisonFilter
        });
        setAnchorEl(null);
    };

    const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const el = event.currentTarget as Element;
        setAnchorEl(el);
    };

    const enableComparisonApply = (): boolean => {
        const enable =
            state.showOrgOverall !== preSelection.showOrgOverall ||
            state.showOtherComparison !== preSelection.showOtherComparison ||
            state.columnLabel !== preSelection.columnLabel ||
            state.customizedShowBenchmark !== preSelection.customizedShowBenchmark ||
            state.customizedShowPP !== preSelection.customizedShowPP ||
            state.otherComparisonSurveyId !== preSelection.otherComparisonSurveyId ||
            state.otherComparisonFilter !== preSelection.otherComparisonFilter;
        const otherComparisonReady =
            !preSelection.showOtherComparison ||
            (preSelection.showOtherComparison &&
                preSelection.otherComparisonSurveyId !== emptySurveyInfo.id &&
                preSelection.columnLabel.length <= 6);
        return enable && otherComparisonReady;
    };

    const handleDeleteFilterItem = (filterItemOrder: number): void => {
        const updateCurrentFilter = { ...state.currentFilter };
        updateCurrentFilter["items"] = updateCurrentFilter.items
            .filter(item => item.order !== filterItemOrder)
            .map((item, index) => {
                return {
                    ...item,
                    order: index
                };
            });

        setFilters(updateCurrentFilter);
    };

    const handleFilterApplied = (filter: Filter): void => {
        if (filter.items.length === 0) {
            setFilters();
        } else {
            updateSnapshotData(
                filter,
                state.selectedSurvey.id,
                state.showOrgOverall,
                state.showOtherComparison,
                user.settings.snapshot.lookdownItems,
                user.settings.snapshot.heatmapLookdownItems,
                user.settings.snapshot.columnLabel,
                user.settings.snapshot.rrLookdownItems,
                user.settings.snapshot.keyDriversLookdownItems,
                state.customizedShowBenchmark,
                state.customizedShowPP,
                state.otherComparisonSurveyId,
                state.otherComparisonFilter,
                true
            );
        }
    };

    const setShowOrgOverall = (): void => {
        setPreSelection({ ...preSelection, showOrgOverall: !preSelection.showOrgOverall });
    };

    const setShowOtherComparison = (): void => {
        setPreSelection({
            ...preSelection,
            showOtherComparison: !preSelection.showOtherComparison,
            otherComparisonSurveyId: emptySurveyInfo.id,
            otherComparisonFilter: initFilter,
            columnLabel: ""
        });
    };
    const handleColumnLabelOnChange = (label: string): void => {
        setPreSelection({
            ...preSelection,
            columnLabel: label
        });
    };

    const handleCmparisonGroupIdChange = (id: number): void => {
        setPreSelection({
            ...preSelection,
            otherComparisonSurveyId: id,
            otherComparisonFilter: initFilter
        });
    };

    const handleCmparisonGroupFilterChange = (filter: Filter): void => {
        setPreSelection({
            ...preSelection,
            otherComparisonFilter: filter
        });
    };

    const handleSelectAllSections = (): void => {
        if (state.surveySections && state.surveySections.length > 0) {
            dispatch({
                type: SnapshotActions.SELECT_ALL_SURVEY_SECTIONS,
                payload: { selectedSurveySections: state.surveySections }
            });
        }
    };
    const handleCollapseAllSections = (): void => {
        dispatch({
            type: SnapshotActions.UNSELECT_ALL_SURVEY_SECTIONS,
            payload: { selectedSurveySections: initSurveySection }
        });
    };
    const handleToggleCustomizedBenchmark = (): void => {
        setPreSelection({
            ...preSelection,
            customizedShowBenchmark: !preSelection.customizedShowBenchmark
        });
    };
    const handleToggleCustomizedPP = (): void => {
        setPreSelection({
            ...preSelection,
            customizedShowPP: !preSelection.customizedShowPP
        });
    };

    const handleCloseSnackbar = (): void => {
        dispatch({
            type: SnapshotActions.SNACKBAR_OPEN_CLOSE,
            payload: { snackBar: { isOpen: false, message: "" } }
        });
    };

    useEffect(() => {
        setLoading(state.status === ComponentStatus.loading);
    }, [state.status]);

    //restore user settings
    useEffect(() => {
        if (user.settings.snapshot) {
            restoreUserSettings(user.settings.snapshot);
        }
    }, [languageCode]);

    if (state.status === ComponentStatus.error) {
        return <Error500 />;
    }

    const RenderSubPanel = (): ReactElement => {
        return (
            <SnapshotSectionDetails
                currentFilter={state.currentFilter}
                selectedCard={state.snapshotData}
                setCardElementSelected={handleSelectSection}
                setCardElementUnSelected={handleUnSelectSection}
                showBenchmarks={showBenchmarks ? state.customizedShowBenchmark : showBenchmarks}
                surveySections={state.surveySections}
                showPreviousPeriod={showPP ? state.customizedShowPP : showPP}
                handleComparisonOpen={handleOpenPopover}
                showDemographic={state.showOtherComparison}
                showOrgOverall={state.showOrgOverall}
                columnLabel={state.columnLabel}
                selectedSurveySections={state.selectedSurveySections}
                onSelectAllSections={handleSelectAllSections}
                onCollapseAllSections={handleCollapseAllSections}
                handleDeleteFilterItem={handleDeleteFilterItem}
                showFavorableUnfavorableOnly={showFavorableUnfavorableOnly}
            />
        );
    };

    const handleConfirmation = (): void => {
        dispatch({
            type: SnapshotActions.APPLY_PRESELECTION,
            payload: {
                showOrgOverall: preSelection.showOrgOverall,
                showOtherComparison: false,
                columnLabel: "",
                customizedShowBenchmark: preSelection.customizedShowBenchmark,
                customizedShowPP: preSelection.customizedShowPP,
                otherComparisonSurveyId: emptySurveyInfo.id,
                otherComparisonFilter: initFilter
            }
        });
        updateSnapshotData(
            state.currentFilter,
            state.selectedSurvey.id,
            preSelection.showOrgOverall,
            false,
            user.settings.snapshot.lookdownItems,
            user.settings.snapshot.heatmapLookdownItems,
            "",
            user.settings.snapshot.rrLookdownItems,
            user.settings.snapshot.keyDriversLookdownItems,
            preSelection.customizedShowBenchmark,
            preSelection.customizedShowPP,
            emptySurveyInfo.id,
            initFilter,
            false
        );

        dispatch({
            type: SnapshotActions.SHOW_CONFIRMATION_DIALOG,
            payload: { showConfirmationDialog: false }
        });
    };

    return (
        <ReportsLayout
            mapSurveys={mapSurveys}
            selectedSurvey={selectedMapSurvey}
            handleSurveySelected={handleSurveySelected}
            handleStartExport={handleStartExport}
            handleOpenFilter={handleOpenFilter}
            exportDisabled={
                state.selectedSurvey.id === emptySurveyInfo.id ||
                state.confidentiality !== ConfidentialityResult.success
            }
            filterDisabled={state.selectedSurvey.id === emptySurveyInfo.id}
            exportDataTestId={"btn-heatmap-export"}
            filter={state.currentFilter}
            onDeleteFilterItem={setFilters}
            isEmptyPage={false}
            startSnapshotExcelExport={handleExcelExport}
            contactFields={contactFields}
        >
            {state.selectedSurvey.id === emptySurveyInfo.id && user.isRestricted && surveys.length === 0 ? (
                <div className={classes.emptyPage}>
                    <EmptyPage
                        image={NoSurveySelected}
                        message={lang.noReportDataAvailable}
                        subMessage={lang.pleaseContactAdministrator}
                    />
                </div>
            ) : state.selectedSurvey.id === emptySurveyInfo.id && user.isRestricted && surveys.length > 0 ? (
                <div className={classes.emptyPage}>
                    <EmptyPage
                        image={NoSurveySelected}
                        message={lang.noSurveyInformationSelected}
                        subMessage={lang.responseRateEmptyMessage}
                    />
                </div>
            ) : state.selectedSurvey.id === emptySurveyInfo.id ? (
                <div className={classes.emptyPage}>
                    <EmptyPage
                        image={NoSurveySelected}
                        message={lang.noSurveyInformationSelected}
                        subMessage={lang.responseRateEmptyMessage}
                    />
                </div>
            ) : state.confidentiality === ConfidentialityResult.compareGroupBelowThreshold ? (
                <div className={classes.emptyPage}>
                    <EmptyPage image={NoSurveySelected} message={lang.snapshotCompareConfidentialityMsg} />
                </div>
            ) : (
                <div className={classes.contentContainer} data-testid="snapshot-container">
                    {state.isFilterAllowed && <div className={classes.snapshotContent}>{RenderSubPanel()}</div>}
                    {!state.isFilterAllowed && (
                        <div className={classes.snapshotContent}>
                            {state.confidentiality === ConfidentialityResult.tooSimilar ? (
                                <ShowConfidentialtyMessageWithCountAndFilter
                                    responseCount={state.snapshotData.response_count}
                                    currentFilter={state.currentFilter}
                                    handleDeleteFilterItem={handleDeleteFilterItem}
                                />
                            ) : state.confidentiality === ConfidentialityResult.belowThreshold ? (
                                <ShowBelowConfidentialityMessageWithCountAndFilter
                                    responseCount={state.snapshotData.response_count}
                                    currentFilter={state.currentFilter}
                                    handleDeleteFilterItem={handleDeleteFilterItem}
                                />
                            ) : (
                                <ShowNotEnoughResponsesMessage responseCount={state.snapshotData.response_count} />
                            )}
                        </div>
                    )}
                </div>
            )}
            {state.isFilterDialogOpen && (
                <Filters
                    onCloseFilter={handleCloseFilters}
                    isOpen={state.isFilterDialogOpen}
                    cardId={state.snapshotData.id}
                    onFiltersApplied={handleFilterApplied}
                    currentFilter={state.currentFilter}
                    filterMode={FilterMode.snapshot}
                    surveyId={state.selectedSurvey.id}
                    contactFields={contactFields}
                />
            )}
            {state.isExportDialogOpen && (
                <DialogExportSlideWithLookdown
                    currentFilter={state.currentFilter}
                    compareOptions={state.compareOptions}
                    surveySections={state.surveySections}
                    fileTitle={state.snapshotData.title}
                    dialogTitle={state.isBulkExport ? lang.bulkExportPowerPoint : lang.exportPowerPoint}
                    isOpen={state.isExportDialogOpen}
                    onClose={handleExportDialogClose}
                    onSubmitCallback={handleExport}
                    hasSubTitle
                    contactFields={contactFields}
                    responseCount={state.snapshotData.response_count}
                    isBulkExport={state.isBulkExport}
                    bulkExportDemographic={bulkExportDemographic}
                    updateBulkExportDemographic={handleUpdateBulkExportDemographic}
                    showPreviousPeriodOptionInHeatmap={
                        state.snapshotData.previousPeriod &&
                        state.snapshotData.previousPeriod.length > 0 &&
                        state.snapshotData.previousPeriod.filter(s => !_.isEmpty(s.values)).length > 0 &&
                        state.snapshotData.previousPeriod.filter(s => s.showInHeatmap === true).length > 0 &&
                        state.customizedShowPP
                    }
                    disablePreviousPeriodOptionInHeatmap={
                        state.snapshotData.previousPeriod &&
                        !!(state.snapshotData.previousPeriod.length > 0) &&
                        state.snapshotData.previousPeriod.map(pp => pp.showInHeatmap).every(e => e === false)
                    }
                />
            )}
            {!!anchorEl && (
                <SnapshotComparisonPopup
                    showOrgOverall={preSelection.showOrgOverall}
                    showOtherComparison={preSelection.showOtherComparison}
                    anchorEl={anchorEl}
                    handleClosePopover={handleClosePopover}
                    setShowOrgOverall={setShowOrgOverall}
                    setShowOtherComparison={setShowOtherComparison}
                    applyComparison={handleApplyComparison}
                    shouldEnableApply={enableComparisonApply()}
                    columnLabel={preSelection.columnLabel}
                    handleColumnLabelOnChange={handleColumnLabelOnChange}
                    customizedShowBenchmark={preSelection.customizedShowBenchmark}
                    customizedShowPP={preSelection.customizedShowPP}
                    toggleCustomizedBenchmark={handleToggleCustomizedBenchmark}
                    toggleCustomizedPP={handleToggleCustomizedPP}
                    showBenchmarks={showBenchmarks}
                    showPreviousPeriod={showPP}
                    showFavorableUnfavorableOnly={showFavorableUnfavorableOnly}
                    setFavorableUnfavorableOnly={setFavorableUnfavorableOnly}
                    compareGroupIds={state.compareGroupIds}
                    otherComparisonSurveyId={preSelection.otherComparisonSurveyId}
                    otherComparisonFilter={preSelection.otherComparisonFilter}
                    handleCmparisonGroupIdChange={handleCmparisonGroupIdChange}
                    handleCmparisonGroupFilterChange={handleCmparisonGroupFilterChange}
                />
            )}
            <ConfirmationDialog
                open={state.showConfirmationDialog}
                onCancelClicked={handleConfirmation}
                onConfirmationClicked={(): void => void 0}
                title={lang.warning}
                message={lang.snapshotCompareConfidentialityMsg}
                hideCancelButton
            />
            <Snackbar open={state.snackBar.isOpen} handleClose={handleCloseSnackbar} message={state.snackBar.message} />
        </ReportsLayout>
    );
};

export default Snapshot;
