import React, { createContext, PropsWithChildren, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";

import { auth0UserIdQuery } from "api/queries";
import { UserInfo } from "components/admin/users/interface";
import Loading from "components/shared/Loading";
import Error404 from "components/errorPage/Error404";
import { useAuth } from "../auth/useAuth";
import { initUserInfo } from "components/admin/users/initialVariables";
import { LanguageCode } from "core/languages/LanguageOptions";

type Context = {
    user: UserInfo;
    setUser: (updateData: Partial<UserInfo>) => void;
};

const UserContext = createContext<Context>({
    user: initUserInfo,
    setUser: () => null
});

const UserProvider = ({ children }: PropsWithChildren) => {
    const { user: auth0User } = useAuth();
    const [user, setUser] = useState<UserInfo>(initUserInfo);

    const [getUser, { loading: loadingUser, error: errorUser }] = useLazyQuery(auth0UserIdQuery, {
        onCompleted: data => {
            let userSettings = {
                ...initUserInfo.settings,
                selectedOrganization: {
                    ...initUserInfo.settings.selectedOrganization,
                    id: data.auth0UserId.organizationId,
                    name: data.auth0UserId.organizationId === 1 ? "TalentMap" : ""
                }
            };

            if (data.auth0UserId.settings) {
                try {
                    userSettings = JSON.parse(data.auth0UserId.settings);

                    userSettings = {
                        ...userSettings,
                        selectedSurvey: userSettings.selectedSurvey || initUserInfo.settings.selectedSurvey,
                        snapshot: userSettings.snapshot || initUserInfo.settings.snapshot,
                        selectedOrganization:
                            userSettings.selectedOrganization || initUserInfo.settings.selectedOrganization,
                        responseRate: userSettings.responseRate || initUserInfo.settings.responseRate,
                        languageCode: userSettings.languageCode || LanguageCode.en,
                        heatmap: userSettings.heatmap || initUserInfo.settings.heatmap,
                        comment: userSettings.comment || initUserInfo.settings.comment,
                        commentsSettings: userSettings.commentsSettings || initUserInfo.settings.commentsSettings,
                        insight: userSettings.insight || initUserInfo.settings.insight
                    };
                    if (userSettings.startTour === null || userSettings.startTour === undefined) {
                        userSettings = { ...userSettings, startTour: initUserInfo.settings.startTour };
                    }
                } catch (e) {
                    if (data) {
                        userSettings = {
                            ...initUserInfo.settings,
                            selectedOrganization: {
                                ...initUserInfo.settings.selectedOrganization,
                                id: data.auth0UserId.organizationId,
                                name: data.auth0UserId.organizationId === 1 ? "TalentMap" : ""
                            },
                            languageCode: LanguageCode.en
                        };
                    } else {
                        userSettings = initUserInfo.settings;
                    }
                }
            }

            const mapUser: UserInfo = {
                ...data.auth0UserId,
                settings: userSettings
            };

            setUser(mapUser);
        }
    });

    const updateUser = (updateData: Partial<UserInfo>) => {
        let updateUser = user || initUserInfo;
        updateUser = {
            ...updateUser,
            ...updateData
        };
        setUser(updateUser);
    };

    useEffect(() => {
        let auth0UserId = "auth0|5f3ee5c473edc1003d6bad45";
        if (window.Cypress) {
            const auth0Cypress = window.localStorage.getItem("auth0Cypress") ?? auth0UserId;
            auth0UserId = JSON.parse(auth0Cypress).auth0UserId;
        } else if (process.env.JEST_WORKER_ID) {
            auth0UserId = "auth0|5f3ee5c473edc1003d6bad45";
        } else {
            auth0UserId = auth0User!.sub as string;
        }
        getUser({ variables: { auth0UserId } });
    }, []);

    if (loadingUser) {
        return <Loading />;
    }

    if (user.id === initUserInfo.id) return <></>;

    if (errorUser || !user) {
        return <Error404 />;
    }

    return (
        <UserContext.Provider
            value={{
                user,
                setUser: updateUser
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export { UserContext, UserProvider };
