import React from "react";

import MuiSwitch, { SwitchProps } from "@mui/material/Switch";
import MuiFormGroup from "@mui/material/FormGroup";
import MuiFormControlLabel from "@mui/material/FormControlLabel";

type Props = SwitchProps & {
    label: string;
};

const Switch = (props: Props) => {
    const { label, ...rest } = props;
    return (
        <MuiFormGroup>
            <MuiFormControlLabel
                control={<MuiSwitch defaultChecked {...rest} />}
                label={label}
                labelPlacement="start"
                sx={{ marginLeft: 0 }}
                color="primary"
            />
        </MuiFormGroup>
    );
};

export { Switch };
