import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { QuestionType } from "../../context/enums";
import { PickManyMappedItem } from "../../interfaces/Interfaces";
import { Card } from "managerPortal/components/cards/interfaces";
import { SurveySection } from "../../context/interfaces";
import ShowPickOneItem from "./ShowPickOneItem";
import ShowPickManyItem from "./ShowPickManyItem";
import { NoScaleItem } from "./interfaces";
import { useLocation } from "react-router-dom";
import { ReportRoutes } from "routes/enums";
import { lightGreyColor } from "managerPortal/styles/GlobalStyles";

import type { ItemData, QuestionData, SectionData, SnapShotDefinition } from "./content/types";
import { labelLength } from "./constants/labelLength";
import { getLabelLength } from "./helpers/getLabelLength";
import { theme } from "lib/theme";
import { Tooltip } from "lib/tooltip";
import { Typography } from "lib/typography";
import { useLang } from "core/hooks";

type styleProps = {
    placeHolderWidth: number;
    stackbarWidth: number;
};

const useStyles = makeStyles(() => ({
    listContainer: {
        display: "block",
        height: "calc(100% - 56px)",
        overflowY: "auto",
        width: "100%"
    },
    pickOneTableHead: {
        display: "grid",
        width: "100%",
        gridTemplateRows: "56px",
        gridTemplateColumns: "1fr auto auto auto auto"
    },
    pickManyTableHead: {
        width: "100%",
        height: 56,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: "border-box"
    },
    pickManyTableHeadTitle: (props: styleProps) => {
        return {
            width: `calc(100% - ${props.placeHolderWidth}px)`,
            height: 56,
            display: "flex",
            paddingLeft: theme.spacing(3),
            alignItems: "center",
            borderBottom: theme.border.main
        };
    },
    cellInfo: {
        gridRow: "1 / 2",
        gridColumn: "1 / 2"
    },
    itemDetailsDivider: {
        height: 16,
        background: lightGreyColor
    },
    cellPlaceHolder: (props: styleProps) => {
        return {
            width: props.placeHolderWidth,
            minWidth: props.placeHolderWidth,
            height: 56,
            background: lightGreyColor
        };
    },
    pickOneTableHeader: {
        width: "100%",
        height: 56,
        borderBottom: theme.border.main,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        paddingLeft: theme.spacing(3),
        boxSizing: "border-box"
    },
    pickManyTableHeadTitleTitleText: (props: styleProps) => {
        return {
            display: "block",
            maxWidth: `calc(1500px - ${props.placeHolderWidth}px)`,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontWeight: 500
        };
    },
    pickManyTableHeadTitleTextDialog: (props: styleProps) => {
        return {
            display: "block",
            maxWidth: `calc(980px - ${props.placeHolderWidth}px)`,
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontWeight: 500
        };
    },
    pickOneTableHeadTitleTitleText: {
        display: "block",
        maxWidth: 1600,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontWeight: 500
    },
    pickOneTableHeadTitleTextDialog: {
        display: "block",
        maxWidth: 1000,
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontWeight: 500
    },
    listItemBar: (props: styleProps) => {
        return {
            borderLeft: theme.border.main,
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            boxSizing: "border-box",
            minWidth: props.stackbarWidth,
            width: props.stackbarWidth,
            widthMax: props.stackbarWidth,
            "& p": {
                fontWeight: 400,
                fontSize: "16px",
                color: theme.palette.text.secondary
            }
        };
    }
}));

type ShowDetailsProps = {
    selectedCard: Card;
    question: QuestionData;
    surveySections: SurveySection[];
    showOrgOverall: boolean;
    showDemographic: boolean;
    showBenchmarks: boolean;
    showPreviousPeriod: boolean;
    placeHolderWidth: number;
    snapShotDefinition: SnapShotDefinition;
    stackbarWidth: number;
};

const ShowDetails = (props: ShowDetailsProps): ReactElement => {
    const {
        selectedCard,
        question,
        surveySections,
        showOrgOverall,
        showDemographic,
        showBenchmarks,
        showPreviousPeriod,
        placeHolderWidth,
        snapShotDefinition,
        stackbarWidth
    } = props;
    const { lang } = useLang();
    const location = useLocation();
    const isInDialog = location.pathname.includes(ReportRoutes.orgChart);

    const classes = useStyles({ placeHolderWidth, stackbarWidth });

    if (question.items.length <= 0) return <></>;
    if (question.questionType === QuestionType.FreeText) return <></>;

    //Return PickMany/checkbox bars
    if (question.questionType === QuestionType.PickMany) {
        return (
            <>
                <div className={classes.pickManyTableHead}>
                    <div className={classes.pickManyTableHeadTitle}>
                        <Tooltip title={question.questionTitle} placement="bottom">
                            <Typography
                                variant="body1"
                                className={
                                    isInDialog
                                        ? classes.pickManyTableHeadTitleTextDialog
                                        : classes.pickManyTableHeadTitleTitleText
                                }
                            >
                                {question.questionTitle}
                            </Typography>
                        </Tooltip>
                    </div>
                    <div className={classes.cellPlaceHolder}></div>
                </div>
                <div data-testid="snapshot-pick-many">
                    {question.items
                        .sort((itemA: PickManyMappedItem, itemB: PickManyMappedItem) => {
                            const totalPickedA = isNaN(itemA.numberOfPicks) ? 0 : itemA.numberOfPicks;
                            const totalPickedB = isNaN(itemB.numberOfPicks) ? 0 : itemB.numberOfPicks;
                            return totalPickedB - totalPickedA;
                        })
                        .map((item: PickManyMappedItem) => {
                            return (
                                <ShowPickManyItem
                                    key={item.itemId}
                                    mappedItem={item}
                                    labelLength={getLabelLength(item.title, labelLength, isInDialog)}
                                    snapShotDefinition={snapShotDefinition}
                                    placeHolderWidth={placeHolderWidth}
                                    stackbarWidth={stackbarWidth}
                                />
                            );
                        })}
                </div>
            </>
        );
    }

    //NOMINAL DATA - No Scale
    if (question.questionType === QuestionType.PickOne && !question.isFun) {
        const mappedItem = (nsItem: NoScaleItem): PickManyMappedItem => {
            return {
                title: nsItem.description,
                itemId: nsItem.answerId,
                numberOfPicks: nsItem.count,
                percentage: nsItem.percent
            };
        };
        return (
            <div>
                {question.items.map((item: ItemData) => {
                    return (
                        <div key={item.itemId}>
                            <div className={classes.pickManyTableHead}>
                                <div className={classes.pickManyTableHeadTitle}>
                                    <Tooltip title={item.title} placement="bottom">
                                        <Typography
                                            variant="body1"
                                            className={
                                                isInDialog
                                                    ? classes.pickManyTableHeadTitleTextDialog
                                                    : classes.pickManyTableHeadTitleTitleText
                                            }
                                        >
                                            {item.title}
                                        </Typography>
                                    </Tooltip>
                                </div>
                                {Array.isArray(selectedCard["IN" + item.itemId.slice(1)]) &&
                                    selectedCard["IN" + item.itemId.slice(1)].length === 0 && (
                                        <div className={classes.listItemBar}>
                                            <Typography variant="body1">
                                                {lang.itemBelowConfidentialityThreshold}
                                            </Typography>
                                        </div>
                                    )}
                                <div className={classes.cellPlaceHolder}></div>
                            </div>
                            <div data-testid="pick-many-table-content">
                                {selectedCard["IN" + item.itemId.slice(1)]
                                    .sort((itemA: NoScaleItem, itemB: NoScaleItem) => {
                                        const totalPickedA = isNaN(mappedItem(itemA).numberOfPicks)
                                            ? 0
                                            : mappedItem(itemA).numberOfPicks;
                                        const totalPickedB = isNaN(mappedItem(itemB).numberOfPicks)
                                            ? 0
                                            : mappedItem(itemB).numberOfPicks;
                                        return totalPickedB - totalPickedA;
                                    })
                                    .map((nsItem: NoScaleItem) => {
                                        return (
                                            <ShowPickManyItem
                                                key={nsItem.answerId}
                                                mappedItem={mappedItem(nsItem)}
                                                labelLength={getLabelLength(
                                                    mappedItem(nsItem).title,
                                                    labelLength,
                                                    isInDialog
                                                )}
                                                snapShotDefinition={snapShotDefinition}
                                                placeHolderWidth={placeHolderWidth}
                                                stackbarWidth={stackbarWidth}
                                            />
                                        );
                                    })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    }

    //Return FUN bars
    let chartValues: number[] = [];
    question.items.forEach((item: ItemData) => {
        if (
            selectedCard["ISB" + item.itemId.slice(1)] &&
            selectedCard["ISB" + item.itemId.slice(1)].length > chartValues
        ) {
            chartValues = selectedCard["ISB" + item.itemId.slice(1)];
        }
    });

    return (
        <>
            {question.items.length > 1 && (
                <div className={classes.pickOneTableHead}>
                    <div className={`${classes.cellInfo} ${classes.pickOneTableHeader}`}>
                        <Tooltip title={question.questionTitle} placement="bottom">
                            <Typography
                                variant="body1"
                                className={
                                    isInDialog
                                        ? classes.pickOneTableHeadTitleTextDialog
                                        : classes.pickOneTableHeadTitleTitleText
                                }
                            >
                                {question.questionTitle}
                            </Typography>
                        </Tooltip>
                    </div>
                </div>
            )}
            <div data-testid="snapshot-pick-one">
                {question.items.map((item: ItemData) => (
                    <ShowPickOneItem
                        key={item.itemId}
                        itemData={item}
                        selectedCard={selectedCard}
                        questionIdentifier="ISB"
                        surveySections={surveySections}
                        showBenchmarks={showBenchmarks}
                        showPreviousPeriod={showPreviousPeriod}
                        labelLength={getLabelLength(item.title, labelLength, isInDialog)}
                        showDemographic={showDemographic}
                        showOrgOverall={showOrgOverall}
                        snapShotDefinition={snapShotDefinition}
                    />
                ))}
            </div>
        </>
    );
};

type Props = {
    selectedCard: Card;
    surveySections: SurveySection[];
    showOrgOverall: boolean;
    showDemographic: boolean;
    showBenchmarks: boolean;
    showPreviousPeriod: boolean;
    section: SectionData;
    snapShotDefinition: SnapShotDefinition;
};

const SnapshotItemsDetails = (props: Props): ReactElement => {
    const {
        showDemographic,
        showBenchmarks,
        showOrgOverall,
        selectedCard,
        showPreviousPeriod,
        surveySections,
        section,
        snapShotDefinition
    } = props;

    const numberOfColumnsWithoutPreviousPeriod = [showDemographic, showBenchmarks, showOrgOverall].filter(
        Boolean
    ).length;

    const numberOfColumnsWithPreviousPeriod =
        [showDemographic, showBenchmarks, showOrgOverall].filter(Boolean).length +
        snapShotDefinition.previousPeriodInfo.length;

    const numberOfColumns = showPreviousPeriod
        ? numberOfColumnsWithPreviousPeriod
        : numberOfColumnsWithoutPreviousPeriod;

    const placeHolderWidth = 79 * numberOfColumns;

    const stackbarWidth = 420 - (numberOfColumns - 1) * 10;
    const classes = useStyles({ placeHolderWidth, stackbarWidth });

    //pick many question cannot be calculated and should be shown in the end of the dialog
    const questionsCanCalculate = section.questions.filter(
        (question: QuestionData) => question.questionType === QuestionType.PickOne && question.isFun
    );

    const questionsCannotCalculate = section.questions.filter(
        (question: QuestionData) =>
            question.questionType === QuestionType.PickMany ||
            (question.questionType === QuestionType.PickOne && !question.isFun)
    );

    const questions = [...questionsCanCalculate, ...questionsCannotCalculate];

    return (
        <>
            <div className={classes.listContainer}>
                {questions.map((question: QuestionData) => {
                    return (
                        <>
                            <ShowDetails
                                key={question.questionId}
                                question={question}
                                selectedCard={selectedCard}
                                showPreviousPeriod={showPreviousPeriod}
                                surveySections={surveySections}
                                showBenchmarks={showBenchmarks}
                                showDemographic={showDemographic}
                                showOrgOverall={showOrgOverall}
                                placeHolderWidth={placeHolderWidth}
                                snapShotDefinition={snapShotDefinition}
                                stackbarWidth={stackbarWidth}
                            />
                            <div className={classes.itemDetailsDivider}></div>
                        </>
                    );
                })}
            </div>
        </>
    );
};

export default SnapshotItemsDetails;
