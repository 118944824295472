import React, { ReactElement, useState, useEffect, FC, CSSProperties } from "react";
import { FixedSizeList as List } from "react-window";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { makeStyles } from "@mui/styles";

import { Typography } from "lib/typography";
import { AutoSizer } from "react-virtualized";

import { useLang } from "core/hooks";
import RateBar from "components/shared/RateBar";
import { ReportBreakdownType } from "./enums";
import { PanelInfo } from "./interface";
import { theme } from "lib/theme";

type Props = {
    rawData: PanelInfo[];
    isAllExpanded: boolean;
    isAllCollapsed: boolean;
    breakdownType: ReportBreakdownType;
    onChange: () => void;
};

type PanelData = {
    id: string;
    parentId: string;
    level: number;
    name: string;
    surveysSent: number;
    surveysCompleted: number;
    responseRate: number;
};

type rowStyleProps = {
    isExpanded: boolean;
    level: number;
    canExpand: boolean;
};

const useRowStyles = makeStyles(() => ({
    expandMoreIcon: (styleProps: rowStyleProps) => ({
        transform: styleProps.isExpanded ? "rotate(0deg)" : "rotate(-90deg)",
        marginRight: theme.spacing(),
        color: theme.palette.action.active
    }),
    rowStyle: (styleProps: rowStyleProps) => ({
        alignItems: "center",
        boxSizing: "border-box",
        borderBottom: theme.border.main,
        display: "grid",
        gridTemplateRows: "1fr",
        gridTemplateColumns: "1fr 100px 100px 332px",
        gridTemplateAreas: `
            "name surveysSent surveysCompleted responseRate"
        `,
        "&:hover": {
            background: styleProps.canExpand ? theme.palette.action.hover : "inherit",
            cursor: styleProps.canExpand ? "pointer" : "inherit"
        }
    }),
    cellName: (styleProps: rowStyleProps) => ({
        paddingLeft: styleProps.canExpand
            ? theme.spacing((styleProps.level + 1) * 2)
            : theme.spacing((styleProps.level + 1) * 4),
        gridArea: "name",
        display: "flex",
        alignItems: "center"
    }),
    cellSurveysSent: {
        gridArea: "surveysSent",
        display: "flex",
        justifyContent: "center",
        height: "100%",
        alignItems: "center",
        borderLeft: theme.border.main
    },
    cellSurveysCompleted: {
        gridArea: "surveysCompleted",
        display: "flex",
        justifyContent: "center",
        borderLeft: theme.border.main,
        height: "100%",
        alignItems: "center"
    },
    cellResponseRate: {
        gridArea: "responseRate",
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        borderLeft: theme.border.main,
        height: "100%",
        alignItems: "center"
    }
}));

const mapRawData = (obj: PanelInfo[], level = 0, parentId = "", data: PanelData[] = []): PanelData[] => {
    obj.forEach((item: PanelInfo) => {
        const builtId: string = item.name.toLowerCase().replace(/\s/g, "") + parentId + level;
        const builtItem: PanelData = {
            id: builtId,
            parentId,
            level,
            name: item.name,
            surveysSent: item.surveysSent,
            surveysCompleted: item.surveysCompleted,
            responseRate: item.responseRate
        };
        data.push(builtItem);

        if (item.subNode && item.subNode.length > 0) {
            mapRawData(item.subNode, level + 1, builtId, data);
        }
    });

    return data;
};
const filterItemsToRender = (_openedPanels: string[], _mainPanelData: PanelData[]): PanelData[] => {
    return _mainPanelData.filter((item: PanelData) => {
        return _openedPanels.includes(item.id) || item.level === 0 || _openedPanels.includes(item.parentId);
    });
};

const ResponseRateListResult: FC<Props> = ({
    onChange,
    isAllCollapsed,
    rawData,
    isAllExpanded,
    breakdownType
}): ReactElement => {
    const [mainPanelData, setMainPanelData] = useState<PanelData[]>(mapRawData(rawData));
    const [openedPanels, setOpenedPanels] = useState<string[]>([]);
    const { lang } = useLang();

    const [renderPanelData, setRenderPanelData] = useState(filterItemsToRender(openedPanels, mainPanelData));
    useEffect(() => {
        const updateRenderPanelData = filterItemsToRender(openedPanels, mainPanelData);
        setRenderPanelData(updateRenderPanelData);
    }, [openedPanels, mainPanelData]);

    useEffect(() => {
        if (isAllCollapsed) setOpenedPanels([]);
    }, [isAllCollapsed]);

    useEffect(() => {
        if (isAllExpanded) {
            const expandAll: string[] = [];
            mainPanelData.forEach((item: PanelData) => expandAll.push(item.id));
            setOpenedPanels(expandAll);
        }
    }, [isAllExpanded, mainPanelData]);

    useEffect(() => {
        setMainPanelData(mapRawData(rawData));
        return (): void => {
            setMainPanelData([]);
        };
    }, [rawData]);

    const handleExpand = (panelId: string, canExpand: boolean): void => {
        if (canExpand) {
            let updateOpenedPanels = [...openedPanels];

            if (updateOpenedPanels.includes(panelId)) {
                updateOpenedPanels = updateOpenedPanels.filter((id: string) => !id.includes(panelId));
            } else {
                updateOpenedPanels = [...updateOpenedPanels, panelId];
            }
            setOpenedPanels(updateOpenedPanels);
            onChange();
        }
    };

    const Row = ({ index, style }: { index: number; style: CSSProperties }): ReactElement => {
        const item = renderPanelData[index];
        const isExpanded = openedPanels.some((id: string) => id === item.id);
        const canExpand = mainPanelData.some((panel: PanelData) => panel.parentId === item.id);
        const rowClasses = useRowStyles({
            isExpanded,
            level: item.level,
            canExpand
        });
        return (
            <div
                className={rowClasses.rowStyle}
                style={style}
                onClick={(): void => handleExpand(item.id, canExpand)}
                data-testid={`rrListResulstItem_${item.name}`}
            >
                <div className={rowClasses.cellName}>
                    {canExpand && <ExpandMoreIcon className={rowClasses.expandMoreIcon} />}
                    <Typography>{item.name ? item.name : lang.emptyData}</Typography>
                </div>
                {breakdownType !== ReportBreakdownType.surveyItem ? (
                    <div className={rowClasses.cellSurveysSent}>
                        <Typography variant="body2">{item.surveysSent}</Typography>
                    </div>
                ) : (
                    <div></div>
                )}
                <div className={rowClasses.cellSurveysCompleted}>
                    <Typography variant="body2">{item.surveysCompleted}</Typography>
                </div>
                <div className={rowClasses.cellResponseRate}>
                    <RateBar barWidth={item.responseRate} rate={1} />
                </div>
            </div>
        );
    };

    return (
        <AutoSizer>
            {({ height, width }): ReactElement => (
                <List height={height} itemCount={renderPanelData.length} itemSize={56} width={width}>
                    {Row}
                </List>
            )}
        </AutoSizer>
    );
};

export default ResponseRateListResult;
