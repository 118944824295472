export enum TableSort {
    desc = "desc",
    asc = "asc"
}

export enum CellType {
    link = "link",
    plainText = "plainText",
    radio = "radio",
    check = "check",
    clickableText = "clickableText",
    filterLabel = "filterLabel",
    chip = "chip",
    time = "time",
    jsonInfo = "jsonInfo",
    status = "status",
    downloadTitle = "downloadTitle",
    downloadStatus = "downloadStatus"
}

export enum DataKey {
    name = "name",
    created = "created",
    source = "source",
    createdBy = "createdBy",
    closeDate = "closeDate",
    email = "email",
    role = "role",
    selected = "selected",
    survey = "survey",
    applyFilter = "applyFilter",
    responses = "responses",
    filterLabel = "filterLabel",
    tags = "tags",
    title = "title",
    organizationName = "organizationName",
    createdDate = "createdDate",
    lastModified = "lastModified",
    statusDetails = "statusDetails",
    queuePosition = "queuePosition",
    action = "action",
    progress = "progress",
    participantCount = "participantCount",
    status = "status",
    responseCount = "responseCount",
    lastResponseSubmittedTime = "lastResponseSubmittedTime",
    createdTime = "createdTime",
    permission = "permission",
    lastActivity = "lastActivity",
    completedDate = "completedDate"
}
