import { UserInfo } from "../../components/admin/users/interface";
import { IRouteProps } from "../interfaces";
import { AdminParticipantRoutes } from "../enums";

import AdminParticipantsContacts from "components/admin/participants/AdminParticipantsContacts";

export const ParticipantsRoutes = (user: UserInfo): IRouteProps[] => {
    return [
        {
            path: `/${AdminParticipantRoutes.result}/${AdminParticipantRoutes.participants}/:id`,
            exact: true,
            label: AdminParticipantRoutes.participants,
            routeKey: AdminParticipantRoutes.participants,
            component: AdminParticipantsContacts,
            isAccessGranted: user.isTalentMapAdmin || user.participantEnabled
        }
    ];
};
