import { gql } from "@apollo/client";

export const allSurveysQuery = gql`
    query allSurveys($orgId: ID!) {
        allSurveys(orgId: $orgId) {
            active
            created
            closeDate
            id
            source
            confidentialityThreshold
            createdByName
            sgSurveyName
            isSurveyResult
            translations {
                name
            }
            sentimentCalculationStatus
            lockMappings
        }
    }
`;
