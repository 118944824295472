import React, { ReactElement, CSSProperties } from "react";
import MuiCheckbox, { CheckboxProps } from "@mui/material/Checkbox";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";

import { Typography } from "lib/typography";

type Props = Omit<FormControlLabelProps, "control" | "onChange" | "label"> &
    Pick<CheckboxProps, "onChange"> & {
        label?: string;
        labelStyle?: CSSProperties;
    };

const formControlLabelStyled = (label: string | undefined) => {
    if (label) {
        return {};
    }
    return { margin: 0 };
};

export const Checkbox = (props: Props): ReactElement => {
    const { onChange, label, ...rest } = props;

    return (
        <FormControlLabel
            {...rest}
            sx={formControlLabelStyled(label)}
            label={<Typography style={props.labelStyle}>{label || undefined}</Typography>}
            control={<MuiCheckbox disableRipple onChange={onChange} />}
        />
    );
};
