import { UserInfo } from "components/admin/users/interface";
import { QuestionType } from "managerPortal/context/enums";
import { SurveyItem, SurveyQuestion, SurveySection } from "managerPortal/context/interfaces";

export const getAvailableSurveySections = (surveySections: SurveySection[], user: UserInfo): SurveySection[] => {
    return surveySections
        .map((section: SurveySection) => {
            return {
                ...section,
                questions: section.questions
                    .filter(
                        (question: SurveyQuestion) =>
                            question.questionType === QuestionType.PickMany ||
                            question.questionType === QuestionType.PickOne
                    )
                    .map((question: SurveyQuestion) => {
                        if (question.questionType === QuestionType.PickMany) {
                            return { ...question };
                        }
                        return {
                            ...question,
                            items: question.items.filter((item: SurveyItem) => {
                                const restricted = user.isRestricted && item.isHiddenForManagers;
                                return !item.isIgnored && !restricted;
                            })
                        };
                    })
                    .filter((question: SurveyQuestion) => question.items.length)
            };
        })
        .filter((section: SurveySection) => section.questions.length);
};
