import React, { CSSProperties, ReactElement, useState, useEffect, useCallback, FC } from "react";
import clsx from "clsx";
import { FixedSizeList as List } from "react-window";
import { AutoSizer } from "react-virtualized";
import { TableCell, TableSortLabel } from "@mui/material";
import dayjs from "dayjs";

import { useColumnWidth } from "./hooks";
import { useLang } from "../../../core/hooks";
import { sortArray } from "../../../core/helpers";
import { Column } from "./interfaces";
import { DataKey, TableSort, CellType } from "./enums";
import { TableData } from "./types";
import CellLink from "./CellLink";
import CellPlainText from "./CellPlainText";
import CellRadio from "./CellRadio";
import CellCheck from "./CellCheck";
import CellClickableText from "./CellClickableText";
import CellFilterLabel from "./CellFilterLabel";
import CellTime from "./CellTime";
import CellJsonInfo from "./CellJsonInfo";
import CellStatus from "./CellStatus";
import CellDownloadTitle from "./CellDownloadTitle";
import CellDownloadStatus from "./CellDownloadStatus";
import { useOrganizationListStyles, useTableResizerStyles } from "./styles";
import { Translation } from "core/languages/interfaces";
import { ContactField } from "components/admin/results/previousPeriod/interfaces";
import { Chip } from "lib/chip";
import { Box } from "lib/box";
import { Typography } from "lib/typography";
import { OverallBulkStatus } from "api/rest/BulkReportStatus";

type Props = {
    dataSource: TableData[];
    tableColumns: Column[];
    radioSelectedValue?: number;
    hideHeader?: boolean;
    headerGrey?: boolean;
    headerPadding?: number;
    overflow?: string;
    onRadioValueUpdate?: (id: number, name: string) => void;
    onCheckboxValueUpdate?: (id: number, value: boolean) => void;
    onClickableTextClick?: (id: number) => void;
    contactFileds?: ContactField[];
    rowPadding?: number;
    isDownloadLink?: boolean;
    defaultOrderByColumn?: DataKey;
};

const InfiniteTable: FC<Props> = (props): ReactElement => {
    const classes = useOrganizationListStyles({
        hideHeader: props.hideHeader ? props.hideHeader : false,
        headerGrey: props.headerGrey ? props.headerGrey : false,
        headerPadding: props.headerPadding,
        overflow: props.overflow ? props.overflow : "",
        rowPadding: props.rowPadding
    });
    const [columns, setColumns] = useState(props.tableColumns);
    const [orderBy, setOrderBy] = useState(props.defaultOrderByColumn ?? DataKey.name);
    const { lang } = useLang();

    const sortTableData = useCallback(
        (data: TableData[], updateColumns?: Column[]): TableData[] => {
            //currentColumns reflects the columns that might be about to be updated, that's why we cannot rely on the current state get the current sort
            let parsedData: TableData[] = data
                ? data.map(d => {
                      if ("created" in d) {
                          d.created = dayjs(d.created).format("YYYY-MM-DD");
                      }
                      return d;
                  })
                : data;
            const currentColumns = updateColumns ? updateColumns : columns;
            const sortColumn = currentColumns.find((col: Column) => col.dataKey === orderBy);
            if (sortColumn && sortColumn.order) {
                parsedData = sortArray(parsedData, sortColumn.dataKey, sortColumn.order);
            }

            return parsedData;
        },
        [columns, orderBy]
    );

    const [tableData, setTableData] = useState(props.dataSource);

    useEffect(() => {
        setTableData(sortTableData(props.dataSource));
    }, [props.dataSource, sortTableData]);

    const handleSortClick = (dataKey: DataKey): void => {
        setColumns((prevState: Column[]) => {
            const findIndex = prevState.findIndex((col: Column) => col.dataKey === dataKey);
            if (findIndex > -1) {
                setOrderBy(dataKey);
                const updateColumns = [...prevState];
                updateColumns[findIndex].order =
                    updateColumns[findIndex].order === TableSort.asc ? TableSort.desc : TableSort.asc;
                const newTableData = sortTableData([...props.dataSource], updateColumns);
                setTableData(newTableData);
                return updateColumns;
            }
            return prevState;
        });
    };

    const handleRadioValueUpdate = (id: number, name: string): void => {
        props.onRadioValueUpdate && props.onRadioValueUpdate(id, name);
    };

    const handleCheckboxUpdate = (id: number, value: boolean): void => {
        props.onCheckboxValueUpdate && props.onCheckboxValueUpdate(id, value);
    };

    const onClickableTextClick = (id: number): void => {
        props.onClickableTextClick && props.onClickableTextClick(id);
    };
    const cellMapper = (col: Column, rawData: TableData): ReactElement => {
        switch (col.cellType) {
            case CellType.link:
                return <CellLink to={`${col.linkTo}${rawData.id}`} name={rawData[col.dataKey]} />;
            case CellType.check:
                return (
                    <CellCheck
                        name={rawData[col.dataKey]}
                        id={rawData.id}
                        updateSelect={handleCheckboxUpdate}
                        selected={rawData.selected}
                    />
                );
            case CellType.radio:
                return (
                    <CellRadio
                        name={rawData[col.dataKey]}
                        id={rawData.id}
                        selectedValue={props.radioSelectedValue}
                        onChange={handleRadioValueUpdate}
                    />
                );
            case CellType.clickableText:
                return (
                    <CellClickableText
                        name={rawData[col.dataKey]}
                        id={rawData.id}
                        onChange={onClickableTextClick}
                        isDownloadLink={props.isDownloadLink}
                    />
                );
            case CellType.filterLabel:
                return (
                    <CellFilterLabel
                        filter={rawData[col.dataKey]}
                        contactFields={props.contactFileds ? props.contactFileds : []}
                    />
                );
            case CellType.chip:
                return (
                    rawData[col.dataKey] && (
                        <Box display="flex" gap={1}>
                            {rawData[col.dataKey].map((tag: { id: number; name: string }) => (
                                <Chip key={tag.id} label={tag.name} />
                            ))}
                        </Box>
                    )
                );
            case CellType.time:
                return <CellTime time={rawData[col.dataKey]} />;
            case CellType.jsonInfo:
                return <CellJsonInfo jsonText={rawData[col.dataKey]} />;
            case CellType.status:
                return (
                    <CellStatus
                        progress={col.secondaryDataKey ? rawData[col.secondaryDataKey] : 0}
                        queuePosition={rawData[col.dataKey]}
                    />
                );
            case CellType.downloadTitle:
                return (
                    <CellDownloadTitle
                        name={rawData[col.dataKey]}
                        id={rawData.id}
                        onChange={onClickableTextClick}
                        isDownloadLink={props.isDownloadLink}
                        isCompleted={rawData["status"] === OverallBulkStatus.Complete}
                    />
                );
            case CellType.downloadStatus:
                return (
                    <CellDownloadStatus
                        isCompleted={rawData["status"] === OverallBulkStatus.Complete}
                        progress={col.secondaryDataKey ? rawData[col.secondaryDataKey] : 0}
                        queuePosition={col.thirdyDataKey ? rawData[col.thirdyDataKey] : 0}
                    />
                );
            default:
                return (
                    <CellPlainText
                        truncate={col.truncate ? true : false}
                        text={rawData[col.dataKey]}
                        withTranslation={col.withTranslation}
                        width={col.truncate ? col.minWidth : 0}
                    />
                );
        }
    };

    const Row = ({ index, style }: { index: number; style: CSSProperties }): ReactElement => {
        return (
            <div className={classes.tableRow} style={style} data-testid={`tableRow-${index}`}>
                {columns.map((col: Column, i: number) => (
                    <div
                        className={classes.tableCell}
                        key={i}
                        style={{
                            width: col.width as CSSProperties["width"],
                            minWidth: col.minWidth,
                            maxWidth: col.maxWidth
                        }}
                        data-testid={`tableCell-row${index}-${tableData[index][col.dataKey]}`}
                    >
                        {cellMapper(col, tableData[index])}
                    </div>
                ))}
            </div>
        );
    };

    const WidthResizer = ({ columnIndex }: { columnIndex: number }): ReactElement => {
        const [current, setCurrent] = useState<HTMLDivElement | null>(null);
        const resizerClasses = useTableResizerStyles();

        const ResizerRef = useCallback((node: HTMLDivElement) => {
            if (node) {
                setCurrent(node);
            }
        }, []);

        useColumnWidth(current, columns, columnIndex, setColumns);

        return (
            <div className={resizerClasses.resizer} ref={ResizerRef} data-testid={"column-resizer-" + columnIndex}>
                <Typography variant="subtitle2">||</Typography>
            </div>
        );
    };

    const HeaderRow = (): ReactElement => {
        return (
            <div className={classes.headerRow}>
                {columns.map((col: Column, index: number) =>
                    col.sortable ? (
                        <TableCell
                            key={col.dataKey}
                            component="div"
                            className={clsx(classes.tableCellHeader, classes.tableCellHead)}
                            style={{
                                width: col.width as CSSProperties["width"],
                                minWidth: col.minWidth,
                                maxWidth: col.maxWidth,
                                height: 48,
                                display: "flex",
                                alignItems: "center"
                            }}
                            data-testid={"header-tablecell-" + index}
                        >
                            <TableSortLabel
                                className={classes.tableHeadText}
                                classes={{ icon: classes.tableHeadIcon }}
                                active={orderBy === col.dataKey}
                                direction={orderBy === col.dataKey ? col.order : TableSort.asc}
                                onClick={(): void => handleSortClick(col.dataKey)}
                            >
                                <Typography
                                    noWrap
                                    className={classes.tableCell}
                                    variant="subtitle2"
                                    key={col.dataKey}
                                    sx={{ color: "rgba(0, 0, 0, 0.54)" }}
                                >
                                    {col.dataKey === DataKey.created
                                        ? (lang.dateCreated as string)
                                        : (lang[col.dataKey as keyof Translation] as string)
                                          ? (lang[col.dataKey as keyof Translation] as string)
                                          : (col.label as string)}
                                </Typography>
                            </TableSortLabel>
                            {col.isResizable ? <WidthResizer columnIndex={index} /> : ""}
                        </TableCell>
                    ) : (
                        <Typography
                            noWrap
                            className={classes.tableCellTextHeader}
                            variant="subtitle2"
                            style={{
                                width: col.width as CSSProperties["width"],
                                minWidth: col.minWidth,
                                maxWidth: col.maxWidth,
                                height: 48,
                                display: "flex",
                                alignItems: "center"
                            }}
                            key={col.dataKey}
                        >
                            {(lang[col.dataKey as keyof Translation] as string)
                                ? (lang[col.dataKey as keyof Translation] as string)
                                : col.label}
                        </Typography>
                    )
                )}
            </div>
        );
    };

    return (
        <div className={classes.container}>
            {!props.hideHeader && <HeaderRow />}
            <div className={classes.content} data-testid="infinite-table-rows">
                <AutoSizer>
                    {({ height, width }): JSX.Element => (
                        <List height={height} itemCount={tableData.length} itemSize={48} width={width}>
                            {Row}
                        </List>
                    )}
                </AutoSizer>
            </div>
        </div>
    );
};

export default InfiniteTable;
