import React, { useState, ReactElement, useRef } from "react";
import { makeStyles } from "@mui/styles";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DeleteIcon from "@mui/icons-material/Delete";

import { LookdownItem } from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { DemographicOptions } from "components/reports/heatmap/interface";
import { SurveySection, SurveyQuestion } from "managerPortal/context/interfaces";
import { useLang } from "core/hooks";
import { getAvailableSurveySectionsOnlyPickOne, surveySectionsPickOneWithIsFun } from "core/helpers";
import { QuestionType } from "managerPortal/context/enums";
import SelectExpandableList from "./SelectExpandableList";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { Box } from "lib/box";
import { IconButton } from "lib/icon-button";
import { useUser } from "core/context/user/useUser";
import { Typography } from "lib/typography";

type Props = {
    compareOptions: DemographicOptions[];
    surveySections: SurveySection[];
    lookdownItem: LookdownItem;
    updateLookdownItem: (item: LookdownItem, index: number) => void;
    index: number;
    deleteLookdownItem: (index: number) => void;
    showLookup: boolean;
    disabledDemographicIds: number[];
    bulkDemographicFieldId: number;
};

const useStyles = makeStyles(() => ({
    surveyItemContainer: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        marginRight: 20
    },
    lookupSurveyItemContainer: {
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box"
    },
    lookupSurveyItemList: {
        height: 392,
        width: 400
    },
    surveyItemList: {
        paddingLeft: 20,
        height: 392,
        width: 400
    },
    surveyItemButton: {
        display: "flex",
        textTransform: "none",
        width: 400,
        height: 53,
        borderBottom: "solid thin darkgrey",
        borderRadius: 0,
        marginBottom: 5,
        marginLeft: 20,
        backgroundColor: "#fafafa",
        boxSizing: "border-box"
    },
    lookupSurveyItemButton: {
        display: "flex",
        textTransform: "none",
        width: 400,
        height: 53,
        borderBottom: "solid thin darkgrey",
        borderRadius: 0,
        marginBottom: 5,
        backgroundColor: "#fafafa",
        boxSizing: "border-box"
    },
    surveyItemButtonTextWrapper: {
        display: "flex",
        width: 380,
        alignItems: "center",
        paddingLeft: 10
    },
    surveyItemButtonText: {
        flexBasis: "90%",
        display: "block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        fontWeight: 400,
        fontSize: 14,
        textAlign: "left"
    },
    surveyItemButtonIcon: {
        flexBasis: "10%"
    },
    requiredText: {
        marginLeft: "14px"
    }
}));

const LookdownList = (props: Props): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();
    const { user } = useUser();
    const [showSurveyItemList, setShowSurveyItemList] = useState<boolean>(false);
    const lookdownContactContainerRef = useRef<HTMLDivElement>(null);

    const handleSurveyItemSelect = (id: string, level: number, title: string): void => {
        setShowSurveyItemList(false);
        if (level === 0) {
            props.updateLookdownItem(
                { ...props.lookdownItem, type: 0, measureId: +id.slice(1), measureField: title },
                props.index
            );
        } else if (level === 1) {
            props.updateLookdownItem(
                { ...props.lookdownItem, type: 1, measureId: +id, measureField: title },
                props.index
            );
        }
    };
    const handleFieldSelected = (value: AutocompleteValue | null): void => {
        autoScrollLookdownContactContainer();
        if (!value) {
            props.updateLookdownItem(
                { ...props.lookdownItem, demographicFieldId: -1, demographicField: "" },
                props.index
            );
            return;
        }
        props.updateLookdownItem(
            { ...props.lookdownItem, demographicFieldId: value.id, demographicField: value.label },
            props.index
        );
    };

    const handleLookupFieldSelected = (value: AutocompleteValue | null, index: number): void => {
        autoScrollLookdownContactContainer();
        if (!value) {
            if (index === 0) {
                props.updateLookdownItem(
                    { ...props.lookdownItem, lookupIds: [-1, props.lookdownItem.lookupIds[1]] },
                    props.index
                );
            } else {
                props.updateLookdownItem(
                    { ...props.lookdownItem, lookupIds: [props.lookdownItem.lookupIds[0], -1] },
                    props.index
                );
            }
            return;
        }
        if (index === 0) {
            props.updateLookdownItem(
                { ...props.lookdownItem, lookupIds: [value.id, props.lookdownItem.lookupIds[1]] },
                props.index
            );
        } else {
            props.updateLookdownItem(
                { ...props.lookdownItem, lookupIds: [props.lookdownItem.lookupIds[0], value.id] },
                props.index
            );
        }
    };

    const surveySections = (): SurveySection[] => {
        return props.surveySections
            .map((section: SurveySection) => {
                return {
                    ...section,
                    questions: section.questions.filter(
                        (question: SurveyQuestion) => question.questionType === QuestionType.PickOne && question.isFun
                    )
                };
            })
            .filter((section: SurveySection) => section.questions.length);
    };
    const handleOpenSurveyItemList = (): void => {
        setShowSurveyItemList(!showSurveyItemList);
        autoScrollLookdownContactContainer();
    };

    const autoScrollLookdownContactContainer = (): void => {
        setTimeout(() => {
            if (lookdownContactContainerRef && lookdownContactContainerRef.current) {
                lookdownContactContainerRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "end",
                    inline: "nearest"
                });
            }
        }, 100);
    };

    const handleSurveyItemListOnBlur = (): void => {
        if (showSurveyItemList) {
            setShowSurveyItemList(false);
        }
    };

    const selectedDemographic = (id: number) => {
        const found = props.compareOptions.find(s => s.id === id);
        if (found) {
            return {
                id: found.id,
                label: found.name
            };
        }
        return {
            id: -1,
            label: ""
        };
    };

    if (props.showLookup) {
        return (
            <Box flexDirection="column">
                <Box display="flex" pl={2} gap={2} pb={1}>
                    <Typography sx={{ width: 400 }}>{lang.lookup}</Typography>
                    <Typography>{lang.lookdown}</Typography>
                </Box>
                <Box display="flex" ref={lookdownContactContainerRef} pl={2}>
                    <Box display="flex" flexDirection="column" gap={2} width={416}>
                        <Box width={400}>
                            <Autocomplete
                                id={`ss-export-lookup-combo-box-${props.index}-1`}
                                options={props.compareOptions
                                    .filter(option => option.id !== -1)
                                    .map(s => {
                                        return {
                                            id: s.id,
                                            label: s.name
                                        };
                                    })}
                                getOptionDisabled={(option): boolean => {
                                    return props.disabledDemographicIds.includes(option.id);
                                }}
                                noOptionsText={lang.noOptionsAvailable}
                                placeholder={lang.demographics}
                                value={selectedDemographic(props.lookdownItem.lookupIds[0])}
                                onChange={(v: AutocompleteValue | null) => {
                                    handleLookupFieldSelected(v, 0);
                                }}
                            />
                        </Box>
                        <Box width={400}>
                            <Autocomplete
                                id={`ss-export-lookup-combo-box-${props.index}-2`}
                                options={props.compareOptions
                                    .filter(option => option.id !== -1)
                                    .map(s => {
                                        return {
                                            id: s.id,
                                            label: s.name
                                        };
                                    })}
                                getOptionDisabled={(option): boolean => {
                                    return props.disabledDemographicIds.includes(option.id);
                                }}
                                noOptionsText={lang.noOptionsAvailable}
                                placeholder={lang.demographics}
                                value={selectedDemographic(props.lookdownItem.lookupIds[1])}
                                onChange={(v: AutocompleteValue | null) => {
                                    handleLookupFieldSelected(v, 1);
                                }}
                            />
                        </Box>
                    </Box>
                    <Box
                        display="flex"
                        flexDirection="column"
                        gap={
                            props.bulkDemographicFieldId !== -1 &&
                            props.bulkDemographicFieldId === props.lookdownItem.demographicFieldId
                                ? 1
                                : 2
                        }
                        width={400}
                    >
                        <Box width={400} display={"flex"} flexDirection={"column"}>
                            <Autocomplete
                                id={`ss-export-ld-combo-box-${props.index}`}
                                options={props.compareOptions
                                    .filter(option => option.id !== -1)
                                    .map(s => {
                                        return {
                                            id: s.id,
                                            label: s.name
                                        };
                                    })}
                                getOptionDisabled={(option): boolean => {
                                    return props.disabledDemographicIds.includes(option.id);
                                }}
                                noOptionsText={lang.noOptionsAvailable}
                                placeholder={lang.demographics}
                                value={selectedDemographic(props.lookdownItem.demographicFieldId)}
                                onChange={handleFieldSelected}
                            />
                            {props.bulkDemographicFieldId !== -1 &&
                                props.bulkDemographicFieldId === props.lookdownItem.demographicFieldId && (
                                    <Typography fontSize={12} fontWeight={400} color={"red"}>
                                        {lang.bulkDemographicSame}
                                    </Typography>
                                )}
                        </Box>
                        <div
                            className={classes.lookupSurveyItemContainer}
                            tabIndex={0}
                            onBlur={handleSurveyItemListOnBlur}
                        >
                            <div
                                onClick={handleOpenSurveyItemList}
                                className={classes.lookupSurveyItemButton}
                                data-testid={`btn-dialog-export-surveyItem-${props.index}`}
                            >
                                <div className={classes.surveyItemButtonTextWrapper}>
                                    <div
                                        className={classes.surveyItemButtonText}
                                        data-testid={
                                            props.lookdownItem.measureField
                                                ? `btn-dialog-export-label-${props.lookdownItem.measureField}`
                                                : `btn-dialog-export-label-surveyItem`
                                        }
                                    >
                                        {props.lookdownItem.measureField
                                            ? props.lookdownItem.measureField
                                            : `${lang.surveyDimensionOrItem}`}
                                    </div>
                                    <div className={classes.surveyItemButtonIcon}>
                                        <ArrowDropDownIcon style={{ color: "grey" }} />
                                    </div>
                                </div>
                            </div>
                            {!showSurveyItemList && (
                                <div className={classes.requiredText}>
                                    <Typography
                                        fontSize={12}
                                        fontWeight={400}
                                        color={props.lookdownItem.measureField === "" ? "red" : "grey"}
                                    >
                                        {lang.required}
                                    </Typography>
                                </div>
                            )}
                            {showSurveyItemList && (
                                <div
                                    className={classes.lookupSurveyItemList}
                                    data-testid={`survey-item-list-${props.index}`}
                                >
                                    <SelectExpandableList
                                        rawData={surveySectionsPickOneWithIsFun(
                                            getAvailableSurveySectionsOnlyPickOne(surveySections(), user)
                                        )}
                                        onItemSelected={handleSurveyItemSelect}
                                    />
                                </div>
                            )}
                        </div>
                    </Box>
                    <IconButton
                        aria-label="delete"
                        data-testid={`lookdown-delete-${props.index}`}
                        style={{ maxHeight: 58, marginLeft: 26, marginTop: 78, alignSelf: "flex-start" }}
                        onClick={(event): void => {
                            event.stopPropagation();
                            props.deleteLookdownItem(props.index);
                        }}
                    >
                        <DeleteIcon fontSize="medium" />
                    </IconButton>
                </Box>
            </Box>
        );
    } else {
        return (
            <Box display="flex" ref={lookdownContactContainerRef} pl={2}>
                <Box width={400}>
                    <Autocomplete
                        id={`ss-export-ld-combo-box-${props.index}`}
                        options={props.compareOptions
                            .filter(option => option.id !== -1)
                            .map(s => {
                                return {
                                    id: s.id,
                                    label: s.name
                                };
                            })}
                        getOptionDisabled={(option): boolean => {
                            return props.disabledDemographicIds.includes(option.id);
                        }}
                        noOptionsText={lang.noOptionsAvailable}
                        placeholder={lang.demographics}
                        value={selectedDemographic(props.lookdownItem.demographicFieldId)}
                        onChange={handleFieldSelected}
                    />
                </Box>
                <div className={classes.surveyItemContainer} tabIndex={0} onBlur={handleSurveyItemListOnBlur}>
                    <div
                        onClick={handleOpenSurveyItemList}
                        className={classes.surveyItemButton}
                        data-testid={`btn-dialog-export-surveyItem-${props.index}`}
                    >
                        <div className={classes.surveyItemButtonTextWrapper}>
                            <div
                                className={classes.surveyItemButtonText}
                                data-testid={
                                    props.lookdownItem.measureField
                                        ? `btn-dialog-export-label-${props.lookdownItem.measureField}`
                                        : `btn-dialog-export-label-surveyItem`
                                }
                            >
                                {props.lookdownItem.measureField
                                    ? props.lookdownItem.measureField
                                    : `${lang.surveyDimensionOrItem}`}
                            </div>
                            <div className={classes.surveyItemButtonIcon}>
                                <ArrowDropDownIcon style={{ color: "grey" }} />
                            </div>
                        </div>
                    </div>
                    {showSurveyItemList && (
                        <div className={classes.surveyItemList} data-testid={`survey-item-list-${props.index}`}>
                            <SelectExpandableList
                                rawData={surveySectionsPickOneWithIsFun(
                                    getAvailableSurveySectionsOnlyPickOne(surveySections(), user)
                                )}
                                onItemSelected={handleSurveyItemSelect}
                            />
                        </div>
                    )}
                </div>
                <IconButton
                    aria-label="delete"
                    data-testid={`lookdown-delete-${props.index}`}
                    style={{ maxHeight: 58 }}
                    onClick={(event): void => {
                        event.stopPropagation();
                        props.deleteLookdownItem(props.index);
                    }}
                >
                    <DeleteIcon fontSize="medium" />
                </IconButton>
            </Box>
        );
    }
};

export default LookdownList;
