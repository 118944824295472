import React, { FunctionComponent, ChangeEvent, useState, ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { useLang } from "core/hooks";
import { isStringEmptyOrSpaces } from "core/helpers";
import { theme } from "lib/theme";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "lib/dialog";
import { Typography } from "lib/typography";
import { TextField } from "lib/text-field";
import { Button } from "lib/button";
import { Divider } from "lib/divider";

const useStyles = makeStyles(() => ({
    dialogContent: {
        width: "444px"
    },
    dialogHeader: {
        fontWeight: "bold",
        borderBottom: theme.border.main
    },
    dialogBody: {
        display: "flex",
        flexDirection: "column",
        paddingTop: "18px",
        paddingBottom: "8px"
    }
}));

interface Props {
    fileTitle?: string;
    dialogTitle: string;
    isOpen: boolean;
    onClose: () => void;
    hasSubTitle?: boolean;
    onSubmitCallback: (mainTitle: string, secondaryTitle: string) => void;
    extraAction?: ReactElement;
}

export const DialogExportSlide: FunctionComponent<Props> = props => {
    const { lang } = useLang();
    const { dialogTitle, fileTitle, isOpen, onClose, onSubmitCallback, hasSubTitle, extraAction } = props;
    const [mainTitle, setMainTitle] = useState(fileTitle ?? "");
    const [secondaryTitle, setSecondaryTitle] = useState("");
    const [error, setError] = useState<boolean>(false);
    const [mainTitleHelperText, setMainTitleHelperText] = useState<string>("");

    const validation = (): boolean => {
        if (isStringEmptyOrSpaces(mainTitle)) {
            setError(true);
            setMainTitleHelperText(lang.dialogExportSlide.controls.errorMainTitle);
            return false;
        }

        return true;
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
        const { name, value } = event.target;

        if (name === "mainTitle") {
            const helperText = !value ? lang.dialogExportSlide.controls.errorMainTitle : "";

            setMainTitleHelperText(helperText);
            setError(!value);
            setMainTitle(value);
            return;
        }

        if (name === "subtitle") {
            setSecondaryTitle(value);
            return;
        }
    };

    const onExportClick = (): void => {
        validation() && onSubmitCallback(mainTitle, secondaryTitle);
    };

    const classes = useStyles();

    return (
        <Dialog open={isOpen} onClose={onClose}>
            <div className={classes.dialogContent}>
                <DialogTitle className={classes.dialogHeader}>
                    <Typography variant="h4">{dialogTitle}</Typography>
                </DialogTitle>
                <DialogContent>
                    <div className={classes.dialogBody}>
                        <TextField
                            autoFocus
                            data-testid="text-field-export-title"
                            name="mainTitle"
                            error={error}
                            label={lang.dialogExportSlide.controls.labelMainTitle}
                            onChange={handleChange}
                            value={mainTitle}
                            helperText={mainTitleHelperText}
                        />
                        {hasSubTitle && (
                            <TextField
                                data-testid="text-field-export-subtitle"
                                name="subtitle"
                                label={lang.dialogExportSlide.controls.labelSubTitle}
                                multiline
                                onChange={handleChange}
                                value={secondaryTitle}
                                helperText={lang.dialogExportSlide.controls.helperSubTitle}
                            />
                        )}
                        {extraAction && extraAction}
                    </div>
                </DialogContent>
                <Divider />
                <DialogActions sx={{ height: 50 }}>
                    <Button
                        onClick={onExportClick}
                        disabled={error || isStringEmptyOrSpaces(mainTitle)}
                        data-testid="btn-dialog-export-slide"
                        variant="text"
                    >
                        {lang.ok}
                    </Button>
                    <Button onClick={onClose} variant="text" style={{ marginRight: 12 }}>
                        {lang.cancel}
                    </Button>
                </DialogActions>
            </div>
        </Dialog>
    );
};
