import React, { useState, useEffect, useRef, ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import { Help } from "@mui/icons-material";
import { useMutation } from "@apollo/client";
import { useParams } from "react-router-dom";

import { useLang, useSurveys, useSnackbar, useLoading, useOrgChartService } from "core/hooks";
import { SurveyInfo, EditSurveyInput, SurveyReportlabel, EditSurveyReportlabelInput } from "./interfaces";
import { SurveyInputKey } from "./enums";
import { isStringEmptyOrSpaces, copyArrayWithObjects, sortArray } from "core/helpers";
import SurveyReportLabelList from "./SurveyReportLabelList";
import { userSettingMutation } from "api/mutations";
import { FilterParameter } from "components/filters/interfaces";
import { Tag } from "components/admin/tags/interface";
import { dateFormat } from "core/constants";
import { Button } from "lib/button";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { Box } from "lib/box";
import { Typography } from "lib/typography";
import { Paper } from "lib/paper";
import { theme } from "lib/theme";
import { Chip } from "lib/chip";
import { Checkbox } from "lib/checkbox";
import { TextField } from "lib/text-field";
import { Tooltip } from "lib/tooltip";
import { IconButton } from "lib/icon-button";
import dayjs, { Dayjs } from "dayjs";
import { DatePicker } from "lib/date-picker";
import { Divider } from "lib/divider";
import { useUser } from "core/context/user/useUser";

type Props = {
    surveySelected: SurveyInfo;
    onSurveyUpdate: (survey: SurveyInfo) => void;
    onSaveSurvey: (survey: EditSurveyInput) => void;
    onValidationError: (setError: boolean) => void;
    forOptimization: boolean;
    addCreatedTag?: (tag: Tag) => void;
    deleteCreatedTag?: (tag: Tag) => void;
    addCreatedOrgTags?: (tags: Tag[]) => void;
};

const useStyles = makeStyles(() => ({
    demographicLabelInfoContent: {
        display: "flex",
        alignItems: "center",
        fontWeight: 500,
        width: 280,
        paddingRight: 14
    },
    infoDetails: {
        display: "flex"
    }
}));

const styledDemographicLabel = {
    display: "flex",
    flexDirection: "column",
    padding: 0,
    overflowY: "auto",
    marginTop: "8px"
};

const surveyTagsPaperRoot = {
    display: "flex",
    gap: 1,
    boxSizing: "border-box",
    maxWidth: 500,
    minWidth: 500,
    justifyContent: "flex-start",
    alignContent: "center",
    flexWrap: "wrap",
    listStyle: "none",
    minHeight: 53,
    margin: 0,
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5)
};

const SurveyGeneral = (props: Props): ReactElement => {
    const classes = useStyles();
    const orgChartService = useOrgChartService();
    const { lang, languageCode } = useLang();
    const { setLoading } = useLoading();
    const [validationError, setValidationError] = useState({ key: "", message: "" });
    const { surveys, updateSingleSurvey } = useSurveys();
    const { user, setUser } = useUser();
    const { setMessage } = useSnackbar();
    const canEditDemographicFields = user.isSiteAdmin || user.isTalentMapAdmin;
    const [surveyReportlabels, setSurveyReportlabels] = useState<SurveyReportlabel[]>([]);
    const [validationReportLabelErrorMessage, setValidationReportLabelErrorMessage] = useState({
        key: -1,
        message: ""
    });
    const [tags, setTags] = useState<Tag[]>([{ name: "", id: -1 }]);
    const [selectedTags, setSelectedTags] = useState<Tag[]>([{ name: "", id: -1 }]);
    const labelRefs = useRef<HTMLDivElement[]>([]);
    const routeParams = useParams<{ id: string }>();

    const validation = (surveyUpdate: SurveyInfo): boolean => {
        if (isStringEmptyOrSpaces(surveyUpdate.translations[0].name)) {
            setValidationError({ key: SurveyInputKey.name, message: lang.surveyNameCannotBeBlank });
            return false;
        } else {
            setValidationError({ key: "", message: "" });
        }

        if (surveyUpdate.confidentialityThreshold < 0 && Number.isInteger(surveyUpdate.confidentialityThreshold)) {
            setValidationError({
                key: SurveyInputKey.confidentialityThreshold,
                message: lang.invalidConfidentialityThreshold
            });
            return false;
        } else {
            setValidationError({ key: "", message: "" });
        }

        const survey = surveys.find(
            (_survey: SurveyInfo) =>
                _survey.isSurveyResult === true &&
                _survey.translations[0].name.toLowerCase().replace(/\s/g, "") ===
                    surveyUpdate.translations[0].name.toLowerCase().replace(/\s/g, "")
        );

        if (survey && survey.id !== surveyUpdate.id) {
            if (!survey.isSurveyResult) {
                setValidationError({ key: SurveyInputKey.name, message: lang.duplicatedLiveSurveyName });
                return false;
            }
            setValidationError({ key: SurveyInputKey.name, message: lang.surveyNameMustBeUnique });
            return false;
        } else {
            setValidationError({ key: "", message: "" });
        }
        return true;
    };

    const handleDeactivateSurvey = (): void => {
        const surveyUpdate = { ...props.surveySelected, active: !props.surveySelected.active };
        if (validation(surveyUpdate as SurveyInfo)) {
            props.onSurveyUpdate(surveyUpdate);

            updateSingleSurvey(surveyUpdate.id, surveyUpdate);

            const editSurveyInput = { ...makeEditSurveyInput(), active: surveyUpdate.active };
            props.onSaveSurvey(editSurveyInput);

            if (!props.surveySelected.active) {
                setMessage(lang.surveyHasBeenReactivated);
            } else {
                setMessage(lang.surveyHasBeenDeactivated);
            }
        }
    };

    const handleChangeField = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const surveyUpdate = { ...props.surveySelected };
        if (event.target.name === SurveyInputKey.name) {
            surveyUpdate.translations[0] = {
                name: event.target.value
            };
            props.onSurveyUpdate(surveyUpdate);
        }
        if (event.target.name === SurveyInputKey.confidentialityThreshold) {
            let newNumber = +event.target.value;
            if (isNaN(newNumber)) newNumber = 5;

            surveyUpdate.confidentialityThreshold = newNumber;
            props.onSurveyUpdate(surveyUpdate);
        }
        if (event.target.name === SurveyInputKey.isLocked) {
            surveyUpdate.lockMappings = event.target.checked;
            props.onSurveyUpdate(surveyUpdate);
        }
    };

    const makeEditSurveyInput = (): EditSurveyInput => ({
        surveyId: props.surveySelected.id,
        organizationId: user.settings.selectedOrganization.id,
        name: props.surveySelected.translations[0].name,
        confidentialityThreshold: props.surveySelected.confidentialityThreshold,
        closeDate: props.surveySelected.closeDate ? props.surveySelected.closeDate.format(dateFormat) : "",
        lockMappings: props.surveySelected.lockMappings
    });

    const handleCloseDateChange = (date: Dayjs | null): void => {
        props.onSurveyUpdate({ ...props.surveySelected, closeDate: date });
        if (props.forOptimization) return;
        const newSurvey = {
            ...makeEditSurveyInput(),
            closeDate: date ? date.format(dateFormat) : ""
        };
        props.onSaveSurvey(newSurvey);
    };

    const handleOnBlur = (): void => {
        if (!props.forOptimization) {
            if (validation(props.surveySelected)) {
                props.onValidationError(false);
                props.onSaveSurvey(makeEditSurveyInput());
                return;
            }
        } else {
            if (!validation(props.surveySelected)) {
                props.onValidationError(true);
                return;
            } else {
                props.onValidationError(false);
                return;
            }
        }

        props.onValidationError(true);
    };

    const validationReportLabels = (id: number): boolean => {
        const hasEmptyValue = surveyReportlabels.map(l => l.currentLabel).some(label => isStringEmptyOrSpaces(label));
        if (hasEmptyValue) {
            setValidationReportLabelErrorMessage({ key: id, message: lang.reportLabelCannotBeBlank });
            return false;
        } else {
            const uniqueValues = new Set(surveyReportlabels.map(l => l.currentLabel.toLocaleLowerCase()));
            if (uniqueValues.size < surveyReportlabels.length) {
                setValidationReportLabelErrorMessage({ key: id, message: lang.reportLabelMustBeUnique });
                return false;
            } else {
                setValidationReportLabelErrorMessage({ key: -1, message: "" });
                return true;
            }
        }
    };
    const handleChangeReportlabels = (e: React.ChangeEvent<HTMLInputElement>, id: number): void => {
        const updateList = copyArrayWithObjects(surveyReportlabels);
        const index = updateList.findIndex((label: SurveyReportlabel) => label.id === id);
        updateList[index].currentLabel = e.target.value;
        setSurveyReportlabels(updateList);
    };
    const handleReportlabelsOnBlur = (id: number): void => {
        if (validationReportLabels(id)) {
            const index = surveyReportlabels.findIndex((label: SurveyReportlabel) => label.id === id);
            const editSurveyReportlabelInput: EditSurveyReportlabelInput = {
                id: id,
                currentLabel: surveyReportlabels[index].currentLabel
            };
            editSurveyReportlabel(editSurveyReportlabelInput);
        }
    };

    const getReportLabels = (): void => {
        setLoading(true);
        orgChartService.getSurveyFields(props.surveySelected.id).then(surveyReportlabels => {
            if (surveyReportlabels) {
                setSurveyReportlabels([...surveyReportlabels]);
            }
            setLoading(false);
        });
    };

    const checkUserSettingsFilterItems = (
        filtersItems: FilterParameter[],
        surveyReportLabel: EditSurveyReportlabelInput
    ): boolean => {
        return filtersItems && filtersItems.some(item => item.field.id === surveyReportLabel.id);
    };

    const updateUserSettingsFilterItems = (
        filtersItems: FilterParameter[],
        surveyReportLabel: EditSurveyReportlabelInput
    ): FilterParameter[] => {
        return filtersItems.map(item => {
            if (item.field.id === surveyReportLabel.id) {
                return { ...item, field: { ...item.field, fieldName: surveyReportLabel.currentLabel } };
            }
            return item;
        });
    };

    const editSurveyReportlabel = (surveyReportLabel: EditSurveyReportlabelInput): void => {
        const shouldUpdateUserSettings = checkUserSettingsFilterItems(user.settings.filtersItems, surveyReportLabel);
        let updatedFilterItems = user.settings.filtersItems;

        try {
            orgChartService.updateSurveyFields(surveyReportLabel.id, surveyReportLabel.currentLabel);
            //update user settings
            if (shouldUpdateUserSettings) {
                updatedFilterItems = updateUserSettingsFilterItems(user.settings.filtersItems, surveyReportLabel);
            }

            const settings = {
                ...user.settings,
                filtersItems: updatedFilterItems
            };
            const mutationOptions = {
                variables: { settings: JSON.stringify(settings) }
            };
            updateUserSetting(mutationOptions);
            setUser({ settings });
        } catch (e) {
            console.log(e);
        }
    };

    const [updateUserSetting] = useMutation(userSettingMutation);

    const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>, index: number): void => {
        if (e.key === "Enter" && labelRefs.current[index + 1]) {
            labelRefs.current[index + 1].focus();
            const textInput = labelRefs.current[index + 1] as HTMLInputElement;
            textInput.select();
        }
    };

    const handleOnFocus = (e: React.FocusEvent, index: number): void => {
        if (labelRefs.current[index]) {
            const textInput = labelRefs.current[index] as HTMLInputElement;
            textInput.select();
        }
    };

    const getTags = async (): Promise<void> => {
        if (props.surveySelected.id !== -1 && routeParams.id !== "optimization") {
            const allTags = await orgChartService.getTags();
            const tagsBySurvey = await orgChartService.getTags(true, props.surveySelected.id);
            setTags(allTags);
            setSelectedTags(tagsBySurvey);
        } else {
            const allTags = await orgChartService.getTags();
            const tagsByOrg = await orgChartService.getTags(false, user.settings.selectedOrganization.id);
            setTags(allTags);
            setSelectedTags(tagsByOrg);
            props.addCreatedOrgTags && props.addCreatedOrgTags(tagsByOrg);
        }
    };

    const sortAndFilterTags = <T extends Tag>(arrayToSort: T[]) =>
        sortArray(arrayToSort, "name", "asc")
            .filter(t => t.id !== -1)
            .map(s => {
                return {
                    id: s.id,
                    label: s.name
                };
            });

    const handleTagSelected = (value: AutocompleteValue | null): void => {
        if (!value) {
            return;
        }
        if (
            props.surveySelected.id !== -1 &&
            routeParams.id !== "optimization" &&
            !selectedTags.map(t => t.id).includes(value.id)
        ) {
            orgChartService.addSurveyTag(props.surveySelected.id, value.id).then(() => {
                const selectedTag = { name: value.label, id: value.id };
                if (!selectedTags.map(t => t.id).includes(value.id)) {
                    setSelectedTags([...selectedTags, selectedTag]);
                }
            });
        } else {
            // for create survey from compass
            const selectedTag = { name: value.label, id: value.id };
            if (!selectedTags.map(t => t.id).includes(value.id)) {
                setSelectedTags([...selectedTags, selectedTag]);
                props.addCreatedTag && props.addCreatedTag(selectedTag);
            }
        }
    };

    const handleDeleteChip = (value: AutocompleteValue) => (): void => {
        const tag = { name: value.label, id: value.id };
        if (props.surveySelected.id !== -1 && routeParams.id !== "optimization") {
            orgChartService.deleteSurveyTag(props.surveySelected.id, tag.id).then(() => {
                const tags = [...selectedTags].filter(t => t.id !== tag.id);
                setSelectedTags(tags);
            });
        } else {
            // for create survey from compass
            const tags = [...selectedTags].filter(t => t.id !== tag.id);
            setSelectedTags(tags);
            props.deleteCreatedTag && props.deleteCreatedTag(tag);
        }
    };

    useEffect(() => {
        if (props.surveySelected.id) {
            getReportLabels();
        }
    }, []);

    useEffect(() => {
        if (props.surveySelected.id && props.surveySelected.id !== -1 && user.isTalentMapAdmin) {
            getTags();
        }
    }, [props.surveySelected.id]);

    useEffect(() => {
        if (validationError.key !== "") {
            validation(props.surveySelected);
        }
    }, [languageCode]);

    return (
        <Box width="100%" display="flex" flexDirection="column">
            <Box display="flex" width="100%" gap={2} alignItems="center" p={2} pl={3} mb={1}>
                <Box width={400}>
                    <TextField
                        onChange={handleChangeField}
                        data-testid="text-field-survey-name"
                        label={lang.name}
                        name={SurveyInputKey.name}
                        value={props.surveySelected.translations[0].name}
                        onBlur={handleOnBlur}
                        error={validationError.key === SurveyInputKey.name}
                        helperText={
                            validationError.key === SurveyInputKey.name ? validationError.message : lang.required
                        }
                        autoFocus={props.forOptimization}
                        disabled={!user.isTalentMapAdmin && !user.isSiteAdmin}
                    />
                </Box>
                <Box width={190}>
                    <DatePicker
                        label={lang.closeDate}
                        value={dayjs(props.surveySelected.closeDate)}
                        onChange={handleCloseDateChange}
                        minDate={dayjs("1900/01/01")}
                        maxDate={dayjs()}
                    />
                </Box>
                <Box width={180}>
                    <TextField
                        onChange={handleChangeField}
                        data-testid="text-field-confidentiality-threshold"
                        label={lang.confidentialityThreshold}
                        name={SurveyInputKey.confidentialityThreshold}
                        value={props.surveySelected.confidentialityThreshold}
                        onBlur={handleOnBlur}
                        disabled={!user.isTalentMapAdmin}
                    />
                </Box>
                <Checkbox
                    labelStyle={{ width: 300, paddingLeft: 4 }}
                    data-testid="admin-lock-PP-Benchmark"
                    name={SurveyInputKey.isLocked}
                    checked={props.surveySelected.lockMappings}
                    onChange={handleChangeField}
                    onBlur={handleOnBlur}
                    disabled={!user.isTalentMapAdmin}
                    label={lang.lockPPAndBenchmark}
                />
                {!props.forOptimization && (
                    <Box display="flex" justifyContent="flex-end" flexGrow={1}>
                        <Button
                            variant={props.surveySelected.active ? "delete" : "primary"}
                            onClick={handleDeactivateSurvey}
                            data-testid="btn-reactivate-deactivate-survey"
                        >
                            {props.surveySelected.active ? lang.deactivate : lang.reactivate}
                        </Button>
                    </Box>
                )}
            </Box>

            {user.isTalentMapAdmin && (
                <Box display="flex" flexDirection="column" p={3} pt={1}>
                    <Typography fontWeight="medium">{lang.benchmarkInformation}</Typography>
                    <Box display="flex" gap={2} alignItems="center" mt={1}>
                        <Box width={400}>
                            <Autocomplete
                                id="survey-general-tag-combo-box"
                                noOptionsText={lang.noOptionsAvailable}
                                options={sortAndFilterTags(tags)}
                                onChange={handleTagSelected}
                                getOptionDisabled={(option): boolean => selectedTags.map(t => t.id).includes(option.id)}
                                placeholder={lang.addTag}
                            />
                        </Box>
                        <Paper component="ul" sx={surveyTagsPaperRoot} data-testid="survey-tag-paper">
                            <Typography variant="body2">{lang.tags}</Typography>
                            {sortAndFilterTags(selectedTags).map(tag => {
                                return (
                                    <li key={tag.id}>
                                        <Chip label={tag.label} onDelete={handleDeleteChip(tag)} />
                                    </li>
                                );
                            })}
                        </Paper>
                    </Box>
                </Box>
            )}
            <Divider />
            <Box pt={2} pb={1}>
                <Typography fontWeight="medium" ml={3}>
                    {lang.surveyInformation}
                </Typography>
                <div className={classes.infoDetails}>
                    <Box display="flex" flexDirection="column" ml={3} gap={0.5} mt={1} mb={1}>
                        <Typography variant="subtitle2">{lang.source}</Typography>
                        <Typography variant="body1">{props.surveySelected.source}</Typography>
                    </Box>
                    {props.surveySelected.source === "Compass" || props.forOptimization ? (
                        <Box display="flex" flexDirection="column" ml={3} gap={0.5} mt={1} mb={1}>
                            <Typography variant="subtitle2">{lang.survey}</Typography>
                            <Typography variant="body1">{props.surveySelected.sgSurveyName}</Typography>
                        </Box>
                    ) : (
                        <></>
                    )}
                </div>
            </Box>
            <Divider />
            {canEditDemographicFields && (
                <Box sx={styledDemographicLabel} data-testid="demographicLabel">
                    <Typography fontWeight="medium" m={2} ml={3}>
                        {lang.demographicsLabels}
                    </Typography>
                    <Box display="flex" pl={3}>
                        <Typography className={classes.demographicLabelInfoContent} fontWeight="medium">
                            {lang.originalDemographicsLabel}
                        </Typography>
                        <Typography className={classes.demographicLabelInfoContent} fontWeight="medium">
                            {lang.demographicsLabelDataExample}
                        </Typography>
                        <Typography className={classes.demographicLabelInfoContent} fontWeight="medium">
                            {lang.demographicsReportLabels}
                            <Tooltip title={lang.demographicLabelTooltip} placement="bottom">
                                <IconButton size="small">
                                    <Help />
                                </IconButton>
                            </Tooltip>
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        {surveyReportlabels &&
                            surveyReportlabels.length > 0 &&
                            surveyReportlabels.map((label: SurveyReportlabel, index) => {
                                return (
                                    <SurveyReportLabelList
                                        key={label.id.toString()}
                                        surveyReportlabel={label}
                                        handleChangeField={handleChangeReportlabels}
                                        onBlur={handleReportlabelsOnBlur}
                                        validationReportLabelErrorMessage={validationReportLabelErrorMessage}
                                        id={label.id}
                                        index={index}
                                        inputRef={(el: HTMLDivElement): HTMLDivElement =>
                                            (labelRefs.current[index] = el)
                                        }
                                        handleKeyUp={handleKeyUp}
                                        handleOnFocus={handleOnFocus}
                                    />
                                );
                            })}
                    </Box>
                </Box>
            )}
        </Box>
    );
};

export default SurveyGeneral;
