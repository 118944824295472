import React, { useState, ReactElement, useCallback, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import { useLazyQuery } from "@apollo/client";

import AdminLayout from "../shared/AdminLayout";
import InfiniteTable from "../../shared/infiniteTable/InfiniteTable";
import EmptyPage from "../../shared/EmptyPage";
import { usersQuery } from "api/queries";
import { useLang, useLoading } from "core/hooks";
import { Translation } from "core/languages/interfaces";
import { Column } from "../../shared/infiniteTable/interfaces";
import { DataKey, TableSort, CellType } from "../../shared/infiniteTable/enums";
import { UsersRoutes } from "routes/enums";
import { copyArrayWithObjects } from "core/helpers";
import noActiveUser from "assets/images/emptyPages/NoActiveUser.svg";
import noDeactivatedUser from "assets/images/emptyPages/NoDeactivatedUser.svg";
import { UserInput, UserInfo, AdminUserList } from "./interface";
import Error500 from "../../errorPage/Error500";
import { isStringEmptyOrSpaces } from "core/helpers";
import { Tab, Tabs } from "lib/tabs";
import { theme } from "lib/theme";
import { useUser } from "core/context/user/useUser";
import dayjs from "dayjs";

const useStyles = makeStyles(() => ({
    listContainer: {
        height: "calc(100% - 48px)",
        borderTop: theme.border.main
    },
    tabs: {
        display: "flex",
        alignItems: "flex-end",
        height: 48,
        minHeight: 48,
        maxHeight: 48
    }
}));

const tableColumnsList: (lang: Translation) => Column[] = lang => {
    return [
        {
            dataKey: DataKey.name,
            label: lang.name,
            minWidth: 320,
            maxWidth: 400,
            width: "100%",
            sortable: true,
            order: TableSort.asc,
            cellType: CellType.link,
            linkTo: `/${UsersRoutes.users}/${UsersRoutes.editUser}/`,
            isResizable: true
        },
        {
            dataKey: DataKey.email,
            label: lang.email,
            width: 400,
            minWidth: 400,
            maxWidth: 400,
            sortable: true,
            cellType: CellType.plainText,
            isResizable: true
        },
        {
            dataKey: DataKey.created,
            label: lang.dateCreated,
            width: 200,
            minWidth: 200,
            sortable: true,
            cellType: CellType.plainText
        },
        {
            dataKey: DataKey.permission,
            label: lang.permission,
            width: 200,
            minWidth: 200,
            sortable: true,
            cellType: CellType.plainText
        },
        {
            dataKey: DataKey.lastActivity,
            label: lang.lastAccess,
            width: 200,
            minWidth: 200,
            sortable: true,
            cellType: CellType.plainText
        }
    ];
};

const AdminUsers = (): ReactElement => {
    const { lang } = useLang();
    const classes = useStyles();
    const [searchStr, setSearchStr] = useState("");
    const [tabSelected, setTabNumber] = useState(0);
    const [userList, setUserList] = useState<UserInput[]>([]);
    const [activeUsers, setActiveUsers] = useState<AdminUserList[]>([]);
    const [deactivatedUsers, setDeactivatedUsers] = useState<AdminUserList[]>([]);
    const tableColumns = copyArrayWithObjects(tableColumnsList(lang));
    const buttonLink = `/${UsersRoutes.users}/${UsersRoutes.addUser}`;
    const { user } = useUser();
    const { setLoading } = useLoading();

    const searchFilter = (users: AdminUserList[]): AdminUserList[] => {
        let filtered = [...users];
        if (!isStringEmptyOrSpaces(searchStr)) {
            filtered = filtered.filter(
                (user: AdminUserList) =>
                    user.name.toLowerCase().includes(searchStr.toLowerCase()) ||
                    user.permission.toLowerCase().includes(searchStr.toLowerCase()) ||
                    user.lastActivity.toLocaleLowerCase().includes(searchStr.toLowerCase()) ||
                    user.email.toLocaleLowerCase().includes(searchStr.toLowerCase()) ||
                    user.created.toLocaleLowerCase().includes(searchStr.toLowerCase())
            );
        }
        return filtered;
    };

    const [refreshUsers, { error, loading }] = useLazyQuery(usersQuery, {
        variables: { orgId: user.settings.selectedOrganization.id },
        onCompleted: (data: { users: UserInput[] }): void => {
            setUserList([...data.users]);
        }
    });

    const onSearchChange = (searchString: string): void => {
        setSearchStr(searchString);
    };

    const handleTabChange = (_: React.ChangeEvent<unknown>, newValue: number): void => {
        setTabNumber(newValue);
    };

    const setLoadingToFalse = useCallback(() => {
        setLoading(false);
    }, [setLoading]);

    const setLoadingToTrue = useCallback(() => {
        setLoading(true);
    }, [setLoading]);

    useEffect(() => {
        if (user.settings.selectedOrganization.id !== -1) {
            refreshUsers();
        }
    }, [user.settings.selectedOrganization.id, refreshUsers]);

    useEffect(() => {
        setActiveUsers(
            userList
                .filter((user: UserInfo) => user.active)
                .map(
                    (user: UserInfo): AdminUserList => ({
                        id: user.id,
                        email: user.email,
                        name: user.name,
                        created: user.created,
                        permission: user.isTalentMapAdmin
                            ? "TalentMap Admin"
                            : user.isSiteAdmin
                              ? "Site Admin"
                              : user.isRestricted
                                ? "Restricted Report Viewer"
                                : "Report Viewer",
                        lastActivity: user.lastActivity
                            ? `${dayjs(user.lastActivity).format("YYYY-MM-DD HH:mm")} UTC`
                            : lang.never
                    })
                )
        );
        setDeactivatedUsers(
            userList
                .filter((user: UserInfo) => !user.active)
                .map(
                    (user: UserInfo): AdminUserList => ({
                        id: user.id,
                        email: user.email,
                        name: user.name,
                        created: user.created,
                        permission: user.isTalentMapAdmin
                            ? lang.talentMapAdmin
                            : user.isSiteAdmin
                              ? lang.siteAdmin
                              : user.isRestricted
                                ? lang.restrictedReportViewer
                                : lang.reportViewer,
                        lastActivity: user.lastActivity
                            ? `${dayjs(user.lastActivity).format("YYYY-MM-DD HH:mm")} UTC`
                            : lang.never
                    })
                )
        );
    }, [JSON.stringify(userList)]);

    useEffect(() => {
        setLoadingToTrue();

        if (!loading) {
            setLoadingToFalse();
        }
    }, [loading, setLoadingToFalse, setLoadingToTrue]);

    if (error) return <Error500 />;
    if (loading) return <></>;

    return (
        <AdminLayout
            onSearchChange={onSearchChange}
            searchTerm={searchStr}
            buttonLabel={lang.addUser}
            buttonLink={buttonLink}
        >
            <div className={classes.tabs}>
                <Tabs
                    value={tabSelected}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={handleTabChange}
                    aria-label="tabs"
                    TabIndicatorProps={{ style: { bottom: 0 } }}
                >
                    <Tab
                        label={`${lang.active} (${searchFilter(activeUsers).length.toString()})`}
                        data-testid="tab-admin-activated-list"
                    />
                    <Tab
                        label={`${lang.deactivated} (${searchFilter(deactivatedUsers).length.toString()})`}
                        data-testid="tab-admin-deactivated-list"
                    />
                </Tabs>
            </div>
            <div className={classes.listContainer} data-testid="adminUsers">
                {tabSelected === 0 &&
                    (activeUsers.length > 0 ? (
                        <InfiniteTable
                            tableColumns={copyArrayWithObjects(tableColumns)}
                            dataSource={searchFilter(activeUsers)}
                            rowPadding={2}
                        />
                    ) : (
                        <EmptyPage image={noActiveUser} message={lang.noActiveUser} subMessage={lang.inviteUser} />
                    ))}
                {tabSelected === 1 &&
                    (deactivatedUsers.length > 0 ? (
                        <InfiniteTable
                            tableColumns={copyArrayWithObjects(tableColumns)}
                            dataSource={searchFilter(deactivatedUsers)}
                            rowPadding={2}
                        />
                    ) : (
                        <EmptyPage
                            image={noDeactivatedUser}
                            message={lang.noDeactivatedUSer}
                            subMessage={lang.usersRemovedFromOrg}
                        />
                    ))}
            </div>
        </AdminLayout>
    );
};

export default AdminUsers;
