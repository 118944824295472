import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";

import { useLang } from "core/hooks";
import { Button, ButtonVariant } from "lib/button";
import { Box } from "lib/box";
import { theme } from "lib/theme";
import { Dialog, DialogContent, DialogTitle, DialogActions, DialogContentText } from "lib/dialog";

type Props = {
    confirmButtonText?: string;
    confirmButtonVariant?: ButtonVariant;
    cancelButtonVariant?: ButtonVariant;
    onConfirmationClicked: () => void;
    onCancelClicked?: () => void;
    cancelButtonText?: string;
    open: boolean;
    message: string;
    submessage?: string;
    messgaeWithLink?: ReactElement;
    title?: string;
    hideCancelButton?: boolean;
};

const useStyles = makeStyles(() => ({
    btnStyleError: {
        color: theme.palette.error.main
    },
    dialogTitle: {
        fontSize: "20px",
        fontWeight: "500"
    },
    dialogContent: {
        minWidth: 500,
        fontSize: "16px"
    },
    dialogContentSub: {
        paddingTop: "4px",
        minWidth: 500,
        fontSize: "16px"
    }
}));

const ConfirmationDialog = (props: Props): ReactElement => {
    const { open, title, message } = props;
    const { lang } = useLang();
    const classes = useStyles();

    const onCancelClicked = (): void => {
        if (props.onCancelClicked) props.onCancelClicked();
    };
    return (
        <div data-testid="confirmation-dialog">
            <Dialog
                PaperProps={{ sx: { maxWidth: 640 } }}
                fullScreen={false}
                open={open}
                onClose={onCancelClicked}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{title ? title : ""}</DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.dialogContent}>{message}</DialogContentText>
                    <DialogContentText className={classes.dialogContentSub}>{props.submessage}</DialogContentText>
                    <DialogContentText className={classes.dialogContent}>{props.messgaeWithLink}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Box mr={2} display={"flex"} gap={2}>
                        <Button
                            autoFocus={true}
                            onClick={props.onConfirmationClicked}
                            variant={props.confirmButtonVariant ? "primary" : "text"}
                            data-testid={
                                props.confirmButtonText
                                    ? `confirmation-dialog-${props.confirmButtonText}`
                                    : "btn-continue"
                            }
                            style={{ display: props.hideCancelButton ? "none" : "block" }}
                        >
                            {props.confirmButtonText ?? lang.continue}
                        </Button>
                        <Button
                            onClick={onCancelClicked}
                            variant={props.cancelButtonVariant ? "text" : "primary"}
                            data-testid={props.cancelButtonText ? `btn-${props.cancelButtonText}` : "btn-cancel"}
                        >
                            {props.cancelButtonText ?? lang.cancel}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ConfirmationDialog;
