import React, { ReactElement } from "react";
import { makeStyles } from "@mui/styles";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Help } from "@mui/icons-material";

import { theme } from "lib/theme";
import { sortArray } from "core/helpers";
import { useLang } from "core/hooks";
import { SelectOptionExpandablePanel } from "components/shared/interfaces";
import { SurveyInfo } from "components/admin/results/interfaces";
import { BreakdownItem, BreakdownItems } from "./interfaces";
import CustomSelectExpandablePanel from "components/reports/responseRate/CustomSelectExpandablePanel";
import { Typography } from "lib/typography";
import { Tooltip } from "lib/tooltip";
import { IconButton } from "lib/icon-button";
import dayjs from "dayjs";
import { Box } from "lib/box";
import { Divider } from "lib/divider";
import { dateFormat } from "core/constants";

type Props = {
    surveySelected: SurveyInfo;
    breakdownItems: BreakdownItems;
    selectOptions: SelectOptionExpandablePanel[];
    setCurrentSelect: (id: number) => void;
    onDeleteBreakdown: (id: number) => void;
    onSelectBreakdown: (id: number, name: string) => void;
    onAddBreakdown: () => void;
    lastTimeUpdated?: string | null;
    //TODO: temporary
    hideIcons?: boolean;
};

const emptyOptions = (name: string): SelectOptionExpandablePanel[] => {
    return [{ id: "-1", title: name }];
};

const useStyles = makeStyles(() => ({
    sidebar: { gridArea: "Sidebar", borderRight: theme.border.main, height: "100%", borderCollapse: "collapse" },
    breakdownItems: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    itemInfo: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: theme.spacing(1)
    },
    addIcon: {
        display: "flex",
        justifyContent: "flex-end",

        "& button": {
            padding: `${theme.spacing(1)} !important`,
            marginRight: theme.spacing(1)
        }
    },
    lastUpdated: {
        height: 48,
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(2),
        borderBottom: theme.border.main
    },
    reportBreakdownContentHeader: {
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        marginBottom: theme.spacing(3),
        marginRight: theme.spacing(1),
        "& button": {
            padding: `${theme.spacing(1)} !important`,
            marginRight: theme.spacing(1)
        }
    },
    textLine: {
        paddingBottom: 8
    },
    hideIcon: { display: "none" }
}));

const MultiSelectionSidebar = (props: Props): ReactElement => {
    const classes = useStyles();
    const { lang } = useLang();

    const sortedOptions = sortArray(props.selectOptions, "title", "asc");

    const handleSelectBreakdown = (id: string, name: string): void => {
        props.onSelectBreakdown(+id, name);
    };

    const getLastUpdate =
        props.lastTimeUpdated === null || props.breakdownItems === undefined
            ? ""
            : dayjs(props.lastTimeUpdated).format(dateFormat);

    const tooltipHelpText = (
        <div>
            {lang.responseRateHelpText.map((l, index) => (
                <div key={index} className={classes.textLine}>
                    {l}
                </div>
            ))}
        </div>
    );

    return (
        <div className={classes.sidebar}>
            <Box height={48} alignItems="center" display="flex" pl={2}>
                <Typography variant="subtitle1">{lang.lastUpdated + getLastUpdate}</Typography>
            </Box>
            <Divider />
            <Box pl={2} pr={2} pt={1}>
                <Box
                    data-testid="reportBreakdownContentHeader"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    mb={1}
                >
                    <Typography variant="body1"> {lang.demographics}</Typography>
                    <Tooltip title={tooltipHelpText} className={props.hideIcons ? classes.hideIcon : ""}>
                        <IconButton aria-label={lang.help}>
                            <Help />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Box display="flex" flexDirection="column" gap={1.5}>
                    {props.breakdownItems.items.map((item: BreakdownItem) => (
                        <Box display="flex" key={item.id} onClick={(): void => props.setCurrentSelect(item.id)}>
                            <CustomSelectExpandablePanel
                                selectOptions={
                                    props.selectOptions.length ? sortedOptions : emptyOptions(lang.noOptionsAvailable)
                                }
                                disabledItemsIds={
                                    props.selectOptions.length
                                        ? props.breakdownItems.items.map((item): string => item.id.toString())
                                        : [emptyOptions(lang.noOptionsAvailable)[0].id]
                                }
                                selectedItem={{ id: item.id.toString(), name: item.name }}
                                onSelectItem={handleSelectBreakdown}
                            />
                            <IconButton
                                onClick={(): void => props.onDeleteBreakdown(item.id)}
                                data-testid={`rr-sidebar-delete-${item.name}`}
                                className={props.hideIcons ? classes.hideIcon : ""}
                            >
                                <DeleteIcon fontSize="medium" />
                            </IconButton>
                        </Box>
                    ))}
                    <div className={props.hideIcons ? classes.hideIcon : classes.addIcon}>
                        <IconButton
                            onClick={props.onAddBreakdown}
                            disabled={
                                props.breakdownItems.items.length > 0 &&
                                props.breakdownItems.items[props.breakdownItems.items.length - 1].id === -1
                            }
                            data-testid="rr-sidebar-add"
                        >
                            <AddIcon fontSize="medium" />
                        </IconButton>
                    </div>
                </Box>
            </Box>
        </div>
    );
};

export default MultiSelectionSidebar;
