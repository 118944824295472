import React, { ReactElement, useState, useEffect, useCallback } from "react";
import FileCopy from "@mui/icons-material/FileCopy";
import Highlighter from "react-highlight-words";

import { useLang, useCommentsService } from "core/hooks";
import { useCommentSummaryStyles } from "./comments-summary.style";
import { CommentsSummaryPayload } from "api/rest/interfaces";
import { filterConnectorToBackEnd } from "components/filters/helper";
import { Filter } from "components/filters/interfaces";
import { CommentSummaryStatus } from "managerPortal/components/comments/interfaces";
import Loading from "components/shared/Loading";
import { CommentState } from "../interface";
import { ConfidentialityResult } from "managerPortal/components/snapshotReport/enums";
import ShowTooSimilarConfidentialityMessage from "../ShowTooSimilarConfidentialityMessage";
import ShowBelowConfidentialityMessage from "../ShowBelowConfidentialityMessage";
import { Typography } from "lib/typography";
import { IconButton } from "lib/icon-button";
import { Snackbar } from "lib/snackbar";

type Props = {
    surveyId: number;
    sectionOrItemTitle: string;
    currentFilter: Filter;
    searchTerm: string;
    itemIds: number[];
    summaryLoadingStatus: boolean;
    updateSummaryLoadingStatus: (status: boolean) => void;
    confidentialityResult: CommentState["confidentialityResult"];
};

export const CommentsSummary = ({
    surveyId,
    sectionOrItemTitle,
    currentFilter,
    searchTerm,
    itemIds,
    summaryLoadingStatus,
    updateSummaryLoadingStatus,
    confidentialityResult
}: Props): ReactElement => {
    const classes = useCommentSummaryStyles();
    const { lang, languageCode } = useLang();
    const commentsService = useCommentsService();
    const [summary, setSummary] = useState<string>("");
    const [suggestion, setSuggestion] = useState<string>("");
    const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });

    const copyToClipboard = (): void => {
        navigator.clipboard.writeText(
            `${lang.commonTopicsFrom} ${sectionOrItemTitle}:
${summary}


${lang.commonSuggestionFrom} ${sectionOrItemTitle}:
${suggestion}`
        );
    };

    const getStatus = useCallback((): void => {
        let timerId: NodeJS.Timeout;
        const commentsSummaryPayload: CommentsSummaryPayload = {
            surveyId: surveyId,
            hierarchyId: 0,
            cardId: 0,
            filters: filterConnectorToBackEnd(currentFilter.items),
            languageCode,
            itemIds,
            title: ""
        };
        commentsService
            .getCommentSummary(commentsSummaryPayload)
            .then(status => {
                if (
                    status &&
                    status.suggestionStatus === CommentSummaryStatus.Completed &&
                    status.summaryStatus === CommentSummaryStatus.Completed
                ) {
                    updateSummaryLoadingStatus(false);
                    if (timerId) {
                        clearTimeout(timerId);
                    }
                    setSummary(status.summary);
                    setSuggestion(status.suggestions);
                } else {
                    updateSummaryLoadingStatus(true);
                    timerId = setTimeout(() => getStatus(), 3000);
                }
            })
            .catch(() => {
                if (timerId) {
                    clearTimeout(timerId);
                }
                setSnackbar({ isOpen: true, message: lang.somethingWentWrong });
                updateSummaryLoadingStatus(false);
            });
    }, [currentFilter.items, surveyId, JSON.stringify(itemIds)]);

    const getHighlitedSummary = (summary: string): ReactElement => {
        return (
            <>
                <Highlighter
                    highlightClassName={classes.highlightWord}
                    searchWords={searchTerm ? [searchTerm] : []}
                    caseSensitive={false}
                    autoEscape={true}
                    textToHighlight={summary}
                />
            </>
        );
    };

    const handleCloseSnackbar = (): void => {
        setSnackbar({ isOpen: false, message: "" });
    };

    useEffect(() => {
        getStatus();
    }, [currentFilter.items, surveyId, JSON.stringify(itemIds)]);

    if (summaryLoadingStatus) {
        return (
            <Loading styleProps={{ background: "white" }} zIndex={1000} borderLeft>
                <Typography>{lang.loadingCommentsSummary}</Typography>
            </Loading>
        );
    }

    return (
        <>
            {confidentialityResult === ConfidentialityResult.tooSimilar ? (
                <div className={classes.pivotGridWrapper}>
                    <ShowTooSimilarConfidentialityMessage />
                </div>
            ) : confidentialityResult === ConfidentialityResult.belowThreshold ? (
                <div className={classes.pivotGridWrapper}>
                    <ShowBelowConfidentialityMessage />
                </div>
            ) : (
                <div className={classes.summary}>
                    <div className={classes.summaryHeader}>
                        <Typography
                            variant="h6"
                            style={{ fontWeight: 500, fontSize: 16 }}
                        >{`${lang.commonTopicsFrom} ${sectionOrItemTitle}`}</Typography>
                        <IconButton
                            className={classes.settingIcon}
                            onClick={copyToClipboard}
                            id="btn-summary-copy-to-clipBoard"
                            color="primary"
                        >
                            <FileCopy />
                        </IconButton>
                    </div>
                    <Typography style={{ whiteSpace: "pre-wrap" }}>{getHighlitedSummary(summary)}</Typography>
                    <div className={classes.suggestionHeader}>
                        <Typography
                            variant="h6"
                            style={{ fontWeight: 500, fontSize: 16 }}
                        >{`${lang.commonSuggestionFrom} ${sectionOrItemTitle}`}</Typography>
                    </div>
                    <Typography style={{ whiteSpace: "pre-wrap" }}>{getHighlitedSummary(suggestion)}</Typography>
                </div>
            )}
            <Snackbar open={snackbar.isOpen} message={snackbar.message} handleClose={handleCloseSnackbar} />
        </>
    );
};
