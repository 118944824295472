import { Action } from "core/interfaces";
import { AdminParticipantsActions } from "../actions";
import { AdminParticipantState } from "../interfaces";

export const adminParticipantsReducer = (state: AdminParticipantState, action: Action): AdminParticipantState => {
    const { type, payload } = action;
    switch (type) {
        case AdminParticipantsActions.SELECT_TAB:
            return {
                ...state,
                tabSelected: payload.tabSelected
            };
        case AdminParticipantsActions.CHANGE_SEARCH_STRING:
            return {
                ...state,
                searchString: payload.searchString
            };

        case AdminParticipantsActions.SNACKBAR_OPEN_CLOSE:
            return {
                ...state,
                snackBar: { ...payload.snackBar }
            };
        case AdminParticipantsActions.SET_SURVEY_LIST:
            return {
                ...state,
                deactivatedSurveyList: payload.deactivatedSurveyList,
                activatedSurveyList: payload.activatedSurveyList
            };
        case AdminParticipantsActions.SET_COMPONENT_STATUS:
            return {
                ...state,
                componentStatus: payload.componentStatus
            };
        default:
            return {
                ...state
            };
    }
};
