import React, { useReducer, useEffect, ReactElement, useCallback } from "react";
import { Typography } from "lib/typography";

import EmptyResults from "assets/images/emptyPages/ReportingEmptyState.svg";
import { useLang, useAdminService } from "core/hooks";
import { Translation } from "core/languages/interfaces";
import { copyArrayWithObjects, isStringEmptyOrSpaces } from "core/helpers";
import { emptySelectedOrganization } from "core/context/inits";
import AdminLayout from "../shared/AdminLayout";
import { Snackbar } from "lib/snackbar";
import InfiniteTable from "../../shared/infiniteTable/InfiniteTable";
import { Column } from "../../shared/infiniteTable/interfaces";
import { TableData } from "../../shared/infiniteTable/types";
import { DataKey, CellType } from "../../shared/infiniteTable/enums";
import { mapTableSource } from "../../shared/infiniteTable/hooks";
import EmptyPage from "../../shared/EmptyPage";
import { adminParticipantsReducer } from "./reducers";
import { useAdminParticipantStyles } from "./styles";
import { AdminParticipantState, AdminParticipantSurveyInfo, AdminParticipantSurveyResponse } from "./interfaces";
import { AdminParticipantRoutes } from "routes/enums";
import { AdminParticipantsActions } from "./actions";
import { ComponentStatus } from "core/enums";
import { Tab, Tabs } from "lib/tabs";
import dayjs from "dayjs";
import { useUser } from "core/context/user/useUser";

const initialState = (): AdminParticipantState => {
    return {
        tabSelected: 0,
        searchString: "",
        snackBar: { isOpen: false, message: "" },
        activatedSurveyList: [],
        deactivatedSurveyList: [],
        componentStatus: ComponentStatus.idle
    };
};

const tableColumnsList: (lang: Translation) => Column[] = lang => {
    return [
        {
            dataKey: DataKey.name,
            label: lang.survey,
            minWidth: 170,
            maxWidth: 470,
            width: "100%",
            sortable: true,
            cellType: CellType.link,
            linkTo: `/${AdminParticipantRoutes.result}/${AdminParticipantRoutes.participants}/`,
            isResizable: true
        },
        {
            dataKey: DataKey.status,
            label: lang.status,
            width: 150,
            sortable: true,
            cellType: CellType.plainText,
            truncate: true
        },
        {
            dataKey: DataKey.participantCount,
            label: lang.participants,
            width: 150,
            sortable: true,
            cellType: CellType.plainText,
            truncate: true
        },
        {
            dataKey: DataKey.responseCount,
            label: lang.participants,
            width: 150,
            sortable: true,
            cellType: CellType.plainText,
            truncate: true
        },
        {
            dataKey: DataKey.lastResponseSubmittedTime,
            label: lang.dateResponseSubmitted,
            width: 200,
            sortable: true,
            cellType: CellType.time,
            isResizable: false
        },
        {
            dataKey: DataKey.source,
            label: lang.source,
            width: 150,
            sortable: true,
            cellType: CellType.plainText,
            truncate: true
        },
        {
            dataKey: DataKey.createdTime,
            label: lang.dateCreated,
            width: 200,
            sortable: true,
            cellType: CellType.time,
            isResizable: false
        },
        {
            dataKey: DataKey.createdBy,
            label: lang.createdBy,
            minWidth: 200,
            maxWidth: 470,
            sortable: true,
            cellType: CellType.plainText,
            truncate: true
        }
    ];
};

const AdminParticipants = (): ReactElement => {
    const { lang, languageCode } = useLang();
    const { user } = useUser();
    const adminService = useAdminService();
    const [state, dispatch] = useReducer(adminParticipantsReducer, null, initialState);
    const classes = useAdminParticipantStyles();
    const tableColumns = tableColumnsList(lang);

    const surveyProcessor = useCallback(
        (surveyList: AdminParticipantSurveyInfo[]): TableData[] => {
            let filtered = surveyList;

            if (surveyList && state) {
                if (!isStringEmptyOrSpaces(state.searchString)) {
                    filtered = filtered.filter((s: AdminParticipantSurveyInfo) =>
                        s.name.toLowerCase().includes(state.searchString.toLowerCase())
                    );
                }

                return mapTableSource(filtered, tableColumns);
            } else {
                const emt: TableData[] = [];
                return emt;
            }
        },
        [state, tableColumns]
    );

    const handleTabChange = (_: React.ChangeEvent<unknown>, newValue: number): void => {
        dispatch({
            type: AdminParticipantsActions.SELECT_TAB,
            payload: { tabSelected: newValue }
        });
    };

    const onSearchChange = (searchStr: string): void => {
        dispatch({
            type: AdminParticipantsActions.CHANGE_SEARCH_STRING,
            payload: { searchString: searchStr }
        });
    };

    const handleCloseSnackbar = (): void => {
        dispatch({
            type: AdminParticipantsActions.SNACKBAR_OPEN_CLOSE,
            payload: { snackBar: { isOpen: false, message: "" } }
        });
    };

    useEffect(() => {
        if (user.settings.selectedOrganization.id) {
            adminService
                .getAdminSurveys(user.settings.selectedOrganization.id, languageCode)
                .then(surveys => {
                    const mappedSurveys = surveys.map(
                        (survey: AdminParticipantSurveyResponse): AdminParticipantSurveyInfo => ({
                            id: survey.surveyId,
                            name: survey.name,
                            open: survey.open,
                            status: survey.open ? lang.open : lang.closed,
                            participantCount: survey.participantCount,
                            responseCount: survey.responseCount,
                            createdTime: survey.createdTime,
                            lastResponseSubmittedTime: survey.lastResponseSubmittedTime,
                            createdByName: survey.createdByName,
                            source: survey.source
                        })
                    );
                    dispatch({
                        type: AdminParticipantsActions.SET_SURVEY_LIST,
                        payload: {
                            activatedSurveyList: surveyProcessor(
                                mappedSurveys
                                    .filter((s: AdminParticipantSurveyInfo) => s.open)
                                    .sort((a, b) => (dayjs(b.createdTime).isBefore(dayjs(a.createdTime)) ? -1 : 1))
                            ),
                            deactivatedSurveyList: surveyProcessor(
                                mappedSurveys
                                    .filter((s: AdminParticipantSurveyInfo) => !s.open)
                                    .sort((a, b) => (dayjs(b.createdTime).isBefore(dayjs(a.createdTime)) ? -1 : 1))
                            )
                        }
                    });
                })
                .catch(() => {
                    dispatch({
                        type: AdminParticipantsActions.SET_SURVEY_LIST,
                        payload: {
                            activatedSurveyList: [],
                            deactivatedSurveyList: []
                        }
                    });
                });
        }
    }, [state.searchString]);

    if (user.settings.selectedOrganization.id === emptySelectedOrganization.id) {
        return (
            <div className={classes.noOrgSelected}>
                <Typography variant="body2">{lang.noOrganizationSelected}</Typography>
                <Typography variant="body2">{lang.selectBeforeManagingSurveys}</Typography>
            </div>
        );
    }

    return (
        <AdminLayout onSearchChange={onSearchChange} searchTerm={state.searchString}>
            <div className={classes.tabs}>
                <Tabs
                    value={state.tabSelected}
                    indicatorColor="secondary"
                    textColor="secondary"
                    onChange={handleTabChange}
                    aria-label="tabs"
                >
                    <Tab
                        label={`${lang.live} (${state.activatedSurveyList.length.toString()})`}
                        data-testid="tab-admin-activated-list"
                    />
                    <Tab
                        label={`${lang.reports} (${state.deactivatedSurveyList.length.toString()})`}
                        data-testid="tab-admin-deactivated-list"
                    />
                </Tabs>
            </div>
            <div className={classes.listContainer} data-testid="admin-participants">
                {state.tabSelected === 0 &&
                    (state.activatedSurveyList.length > 0 ? (
                        <InfiniteTable
                            tableColumns={copyArrayWithObjects(tableColumns).filter(
                                c => c.dataKey !== DataKey.createdBy
                            )}
                            dataSource={state.activatedSurveyList}
                            rowPadding={2}
                            defaultOrderByColumn={DataKey.createdTime}
                        />
                    ) : (
                        <EmptyPage image={EmptyResults} message={lang.noOpenSurvey} />
                    ))}
                {state.tabSelected === 1 &&
                    (state.deactivatedSurveyList.length > 0 ? (
                        <InfiniteTable
                            tableColumns={copyArrayWithObjects(tableColumns)}
                            dataSource={state.deactivatedSurveyList}
                            rowPadding={2}
                            defaultOrderByColumn={DataKey.createdTime}
                        />
                    ) : (
                        <EmptyPage image={EmptyResults} message={lang.noClosedSurvey} />
                    ))}
            </div>
            {state.snackBar.isOpen && (
                <Snackbar
                    open={state.snackBar.isOpen}
                    handleClose={handleCloseSnackbar}
                    message={state.snackBar.message}
                />
            )}
        </AdminLayout>
    );
};

export default AdminParticipants;
