import React, { ReactElement } from "react";
import FlagIcon from "@mui/icons-material/Flag";

import { useLang } from "core/hooks";
import { Scale } from "./interfaces";
import { Typography } from "lib/typography";
import { Divider } from "lib/divider";
import { List, ListItemButton, ListItemText } from "lib/list";
import { Box } from "lib/box";

type Props = {
    defaultScaleSelected: Scale;
    onSelect: (a: Scale) => void;
    surveyScales: Scale[];
};

const DefaultScalesList = (props: Props): ReactElement => {
    const { defaultScaleSelected, onSelect, surveyScales } = props;
    const { lang } = useLang();

    return (
        <Box height="100%">
            <Box height={48} display="flex" alignItems="center" pl={3}>
                <Typography color="grey" variant="body1">
                    {lang.defaultSettings}
                </Typography>
            </Box>
            <Divider />
            <List aria-label="scaleDefaultList" disablePadding>
                {surveyScales.map((scale: Scale) => (
                    <ListItemButton
                        key={scale.id}
                        selected={scale.id === defaultScaleSelected.id}
                        onClick={(): void => onSelect(scale)}
                    >
                        <ListItemText
                            primary={scale.name}
                            primaryTypographyProps={{ variant: "body2" }}
                            sx={{ maxWidth: 50 }}
                        />
                        {scale.isModified ? <FlagIcon fontSize="small" color="primary" /> : <></>}
                    </ListItemButton>
                ))}
            </List>
        </Box>
    );
};

export default DefaultScalesList;
