import React, { ReactElement, useState, useRef } from "react";
import { useMutation } from "@apollo/client";
import { mdiArrowExpand, mdiArrowCollapse } from "@mdi/js";
import TuneIcon from "@mui/icons-material/Tune";

import { IconButton, IconButtonV4 } from "lib/icon-button";
import { useLang, useToggle, useOrgChartService } from "core/hooks";
import { CommentState } from "../interface";
import { userSettingMutation } from "api/mutations";
import { SearchBarHandle } from "managerPortal/components/shared/SearchBar";
import WordCloud, { WordDisplay } from "./word-cloud";
import { WordCloudSettingPopup, WordCloudHandle } from "./word-cloud-setting-popup";
import ConfirmationDialog from "components/shared/ConfirmationDialog";
import { UserCommentSettings, UserSettings } from "components/admin/users/interface";
import { initCommentsSettings } from "components/admin/users/initialVariables";
import { useStyles } from "./comments-word-cloud.style";
import { getUserSettings } from "../helpers/get-user-settings";
import { Typography } from "lib/typography";
import { Box } from "lib/box";
import { useUser } from "core/context/user/useUser";

type Props = {
    wordCloudRawData: CommentState["wordCloudRawData"];
    wordCloudSettings: CommentState["wordCloudSettings"];
    onSearchTermChange: (term: string) => void;
    resetInfiniteScrollbar: () => void;
    refetchComments: () => void;
};

export const CommentsWordCloud = ({
    wordCloudRawData,
    wordCloudSettings,
    resetInfiniteScrollbar,
    refetchComments,
    onSearchTermChange
}: Props): ReactElement => {
    const orgChartService = useOrgChartService();
    const [showWorldCloud, setShowWorldCloud] = useToggle(true);
    const [wordCloudSettingAnchor, setWordCloudSettingAnchor] = useState<Element | null>(null);
    const [confirmWordCloudSettingsDialogOpen, setConfirmWordCloudSettingsDialogOpen] = useState(false);

    const initHoverWord = { text: "", value: 0 };
    const [hoverWord, setHoverWord] = useState<WordDisplay>(initHoverWord);
    const { lang } = useLang();
    const { user, setUser } = useUser();
    const userSettings = getUserSettings(user);

    const searchBarRef = useRef<SearchBarHandle>(null);
    const wordCloudSettingPopupRef = useRef<WordCloudHandle>(null);
    const accessWordsCloudSettings = user.isTalentMapAdmin || user.isSiteAdmin;

    const classes = useStyles();
    const [updateUserSetting] = useMutation(userSettingMutation);

    const updateUserSettings = (settings: UserSettings): void => {
        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };
        updateUserSetting(mutationOptions);
        setUser({ settings });
    };

    const handleSelectWordFromCloud = (word: string): void => {
        if (searchBarRef && searchBarRef.current) {
            searchBarRef.current.setInputField(word);
        }
        onSearchTermChange(word);
    };

    const handleHoverOverWordCloud = (text: string, value: number): void => {
        const updateHoverWord = { text, value };
        setHoverWord(updateHoverWord);
    };
    const handleMouseOutWordCloud = (): void => {
        setHoverWord(initHoverWord);
    };

    const handleWordCloudSettingPopover = (): void => {
        setWordCloudSettingAnchor(null);
    };

    const refetchWordCloud = (): void => {
        refetchComments();
        if (wordCloudSettingPopupRef && wordCloudSettingPopupRef.current) {
            wordCloudSettingPopupRef.current.resetImportedFile();
        }
    };

    const handleApplyWordCouldSetting = (topWord: number, stopWords: string[]): void => {
        resetInfiniteScrollbar();
        orgChartService.updateCommentWordCloudSettings(user.settings.comment.surveySelected, stopWords).then(() => {
            setWordCloudSettingAnchor(null);
            const userSettings: UserCommentSettings = {
                ...initCommentsSettings,
                surveyId: user.settings.comment.surveySelected,
                wordCloudTopWordsCount: topWord
            };

            const userCommentsSettins = user.settings.commentsSettings;
            const findIndex = userCommentsSettins.findIndex(s => s.surveyId === user.settings.comment.surveySelected);
            if (findIndex > -1) {
                userCommentsSettins[findIndex].wordCloudTopWordsCount = userSettings.wordCloudTopWordsCount;
            } else {
                userCommentsSettins.push(userSettings);
            }
            updateUserSettings({ ...user.settings, commentsSettings: userCommentsSettins });
            refetchWordCloud();
        });
    };

    const handleOpenWordCloudSetting = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const el = event.currentTarget as Element;
        setWordCloudSettingAnchor(el);
    };

    const showConfirmDialog = (status: boolean): void => {
        setConfirmWordCloudSettingsDialogOpen(status);
    };

    const confirmationDialogOnClick = (): void => {
        setConfirmWordCloudSettingsDialogOpen(false);
        if (wordCloudSettingPopupRef && wordCloudSettingPopupRef.current) {
            wordCloudSettingPopupRef.current.resetImportedFile();
            wordCloudSettingPopupRef.current.resetNumberOfTopWords();
        }
    };

    return (
        <>
            <>
                <div className={classes.wordCloudHeader}>
                    <Box display="flex" alignItems="center" justifyContent="flex-start">
                        <Typography variant="h6" style={{ fontWeight: 500, fontSize: 16 }}>
                            {lang.wordCloud}
                        </Typography>
                    </Box>
                    <div className={classes.chartActions}>
                        <IconButtonV4
                            path={showWorldCloud ? mdiArrowCollapse : mdiArrowExpand}
                            onClick={setShowWorldCloud}
                            disabled={wordCloudRawData.length === 0}
                            dataTestid="comment-toggle-wordCloud"
                        />
                        {accessWordsCloudSettings && (
                            <IconButton
                                onClick={handleOpenWordCloudSetting}
                                disabled={wordCloudRawData.length === 0}
                                id="comment-wordcloud-setting"
                                color="primary"
                            >
                                <TuneIcon />
                            </IconButton>
                        )}
                    </div>
                </div>
                {showWorldCloud && wordCloudRawData.length > 0 && (
                    <div className={classes.wordCloud}>
                        <div className={classes.words}>
                            <WordCloud
                                rawData={wordCloudRawData}
                                selectWordFromCloud={handleSelectWordFromCloud}
                                hoverOverWordCloud={handleHoverOverWordCloud}
                                mouseOutWordCloud={handleMouseOutWordCloud}
                            />
                        </div>
                        <div className={classes.worldCloudDisplayChart}>
                            <Typography variant="body2" className={classes.worldCloudDisplayChartTypography}>
                                {`${hoverWord.text} ${hoverWord.value === 0 ? "" : `(${hoverWord.value})`}`}
                            </Typography>
                        </div>
                    </div>
                )}
            </>
            {!!wordCloudSettingAnchor && (
                <WordCloudSettingPopup
                    ref={wordCloudSettingPopupRef}
                    anchorEl={wordCloudSettingAnchor}
                    handleClosePopover={handleWordCloudSettingPopover}
                    applySetting={handleApplyWordCouldSetting}
                    openConfirmationDialog={(): void => showConfirmDialog(true)}
                    wordCloudSettings={wordCloudSettings}
                    wordCloudTopWordsCount={userSettings.wordCloudTopWordsCount}
                />
            )}
            {confirmWordCloudSettingsDialogOpen && (
                <ConfirmationDialog
                    onCancelClicked={(): void => showConfirmDialog(false)}
                    open={confirmWordCloudSettingsDialogOpen}
                    onConfirmationClicked={confirmationDialogOnClick}
                    title={lang.resetWordCloudTitle}
                    message={lang.resetWordCloudMessage}
                    confirmButtonText={lang.reset}
                    cancelButtonVariant="text"
                />
            )}
        </>
    );
};
