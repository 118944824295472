import React, { ReactElement, useState, useReducer, useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import saveAs from "file-saver";
import { useLazyQuery, useMutation } from "@apollo/client";

import {
    useLang,
    useSurveys,
    useLoading,
    useUpdateUserSettings,
    useInsightService,
    useSnackbar,
    useOrgChartService
} from "core/hooks";
import { useInsightStyles } from "./insight.style";
import { emptySurveyInfo } from "components/admin/results/init";
import { SurveyInfo } from "components/admin/results/interfaces";
import { InsightState, CorrelationInfo } from "./interface";
import { getMapSurveySelected, mapSurveysToAutocompleteOption, sortArray } from "core/helpers";
import { insightReducer } from "./reducer";
import { InsightActions } from "./action";
import Error500 from "components/errorPage/Error500";
import { ComponentStatus } from "core/enums";
import { DialogExportSlide } from "managerPortal/components/shared/DialogExportSlide";
import Filters from "components/filters/Filters";
import { initFilter } from "components/filters/inits";
import { Filter, FilterParameter } from "components/filters/interfaces";
import { FilterMode } from "components/filters/enums";
import { HrisFieldInfo } from "managerPortal/interfaces";
import { ReportsLayout } from "../layout/ReportsLayout";
import { BulkExportDemographic } from "managerPortal/components/shared/DialogExportSlideWithLookdown";
import { DialogBulkExportSlide } from "managerPortal/components/shared/DialogBulkExportSlide";
import ShowTooSimilarConfidentialityMessage from "components/reports/comment/ShowTooSimilarConfidentialityMessage";
import ShowBelowConfidentialityMessage from "components/reports/comment/ShowBelowConfidentialityMessage";
import { UserSettings } from "components/admin/users/interface";
import { userSettingMutation } from "api/mutations";
import { ConfidentialityResult } from "components/reports/heatmap/heatmap.init";
import ShowNotEnoughCorrelationResponse from "components/reports/correlation/ShowNotEnoughCorrelationResponse";
import InsightConfidentialityCheck from "./InsightConfidentialityCheck";
import InsightOutcomePopover from "./InsightOutcomePopover";
import InsightComparisonPopover from "./InsightComparisonPopover";
import InsightKeyDriverScore from "./InsightKeyDriverScore";
import { HIDE_ELEMENTS } from "managerPortal/enums/Enums";
import InsightComment from "./InsightComment";
import { demographicFieldsAndValuesQuery } from "api/queries";
import { Autocomplete, AutocompleteValue } from "lib/autocomplete";
import { Snackbar } from "lib/snackbar";
import { Button } from "lib/button";
import { Box } from "lib/box";
import { useUser } from "core/context/user/useUser";

export interface PreSelection {
    showOrgOverall: boolean;
    customizedShowPP: boolean;
}

export const lengthOfComments = 10;

const initialState = (): InsightState => {
    return {
        selectedSurvey: emptySurveyInfo,
        correlation: [],
        keyDriverList: [],
        status: ComponentStatus.idle,
        currentFilter: initFilter,
        isFilterDialogOpen: false,
        confidentialityResult: ConfidentialityResult.success,
        responseCount: 0,
        demographicField: [],
        selectedOutcome: -1,
        selectedKeyDriver: -1,
        selectedDemographicField: "",
        previousPeriodLabels: [],
        keyDriverScore: [],
        expandedKeyDriverSections: [],
        selectedKeyDriverSection: "",
        showOrgOverall: false,
        customizedShowPP: true,
        showPreviousPeriod: false,
        selectedKeyItemId: -1
    };
};

const Insight = (): ReactElement => {
    const { lang, languageCode } = useLang();
    const insightService = useInsightService();
    const orgChartService = useOrgChartService();
    const { surveys } = useSurveys();
    const { user, setUser } = useUser();
    const { setLoading } = useLoading();
    const { setMessage } = useSnackbar();
    const { setKeyDriverInitUserSetting, setUserSettingAfterFilterApplied } = useUpdateUserSettings();
    const [state, dispatch] = useReducer(insightReducer, initialState());
    const [isExportDialogOpen, setExportDialogOpen] = useState(false);
    const [snackbar, setSnackbar] = useState({ isOpen: false, message: "" });
    const [contactFields, setContactFields] = useState<HrisFieldInfo[]>([]);
    const [isBulkExportDialogOpen, setBulkExportDialogOpen] = useState(false);
    const [bulkExportDemographic, setBulkExportDemographic] = useState<BulkExportDemographic>({
        demographicFieldId: -1,
        demographicField: ""
    });
    const [anchorEl, setAnchorEl] = useState<Element | null>(null);
    const [preSelection, setPreSelection] = useState<PreSelection>({
        showOrgOverall: state.showOrgOverall,
        customizedShowPP: state.customizedShowPP
    });
    const [comparisonAnchorEl, setComparisonAnchorEl] = useState<Element | null>(null);
    const [commentLenth, setCommentLength] = useState<number>(lengthOfComments);
    const showPP = !!(state.previousPeriodLabels.length > 0);

    const classes = useInsightStyles();
    const mapSurveys = mapSurveysToAutocompleteOption([...surveys, emptySurveyInfo]);
    const selectedMapSurvey = getMapSurveySelected(mapSurveys, state.selectedSurvey.id);

    const shouldShowEmptyPage = (): boolean => {
        if (surveys.length === 0) {
            return true;
        }

        if (!user.settings.insight) {
            return true;
        }

        if (user.settings.insight) {
            if (user.settings.insight.surveySelected === emptySurveyInfo.id) {
                return true;
            }
            if (surveys.find(s => s.id === user.settings.insight.surveySelected) === undefined) {
                return true;
            }
        }

        if (state.selectedSurvey.id === emptySurveyInfo.id) {
            return true;
        }
        return false;
    };

    const messageComposer = (msg: string, fileName: string): string => {
        const index = msg.indexOf(".");
        return msg.slice(0, index) + fileName + msg.slice(index);
    };

    const [loadDemographics] = useLazyQuery(demographicFieldsAndValuesQuery, {
        onCompleted: data => {
            if (data.demographicFieldsAndValues) {
                const hrisList = [...data.demographicFieldsAndValues].filter((field: HrisFieldInfo) => !field.isHidden);

                const sortedDemographic = sortArray(hrisList, "fieldName", "asc");
                setContactFields(sortedDemographic);
            }
        }
    });

    const [updateUserSetting] = useMutation(userSettingMutation);
    const updateUserSettings = (settings: UserSettings): void => {
        const mutationOptions = {
            variables: { settings: JSON.stringify(settings) }
        };
        updateUserSetting(mutationOptions);
        setUser({ settings });
    };

    const getKeyDriverList = (correlations: CorrelationInfo[], selectedOutcome: number): number[] => {
        if (correlations && correlations.length > 0 && selectedOutcome && selectedOutcome !== -1) {
            const keyDriverList = correlations.find(l => l.id == selectedOutcome);
            if (keyDriverList) {
                return keyDriverList.correlations
                    .filter(c => c.isSection === true && c.id !== selectedOutcome)
                    .sort((a, b) => b.value - a.value)
                    .map(c => c.id);
            } else {
                const itemsCorrelations = correlations.map(c => c.items).reduce((a, b) => a.concat(b), []);
                const itemKeyDriverList = itemsCorrelations.find(i => i.id === selectedOutcome);
                const selectedSection = correlations.find(
                    c => c.items.find(i => i.id === selectedOutcome) !== undefined
                );
                if (itemKeyDriverList) {
                    return itemKeyDriverList.correlations
                        .filter(c => c.isSection === true && c.id !== selectedSection?.id)
                        .sort((a, b) => b.value - a.value)
                        .map(c => c.id);
                } else {
                    return [];
                }
            }
        } else {
            return [];
        }
    };

    const getAutoSelectedOutcomeId = (correlations: CorrelationInfo[]): number => {
        let autoSelectedOutcome = -1;
        const engagmentOutcomeFound = correlations.find(c =>
            c.label.toLocaleLowerCase().includes("organizational engagement")
        );
        if (engagmentOutcomeFound) {
            autoSelectedOutcome = engagmentOutcomeFound.id;
        } else {
            const otherEngagmentOutcomeFound = correlations.find(c =>
                c.label.toLocaleLowerCase().includes("engagement")
            );
            if (otherEngagmentOutcomeFound) {
                autoSelectedOutcome = otherEngagmentOutcomeFound.id;
            } else {
                autoSelectedOutcome = correlations[0].id;
            }
        }

        return autoSelectedOutcome;
    };

    const getSelectedKeyItemId = (
        correlations: CorrelationInfo[],
        selectedOutcomeId: number,
        selectedKeyDriverId: number
    ): number => {
        const selectedkeyItems = correlations.find(c => c.id === selectedKeyDriverId);
        if (selectedkeyItems) {
            const selectedKeyDriverItemIds = selectedkeyItems.items.map(i => i.id);
            const selectedOutcomeFound = correlations.find(c => c.id === selectedOutcomeId);
            if (selectedOutcomeFound) {
                return selectedOutcomeFound.correlations
                    .filter(c => selectedKeyDriverItemIds.includes(c.id))
                    .sort((a, b) => b.value - a.value)[0].id;
            } else {
                return -1;
            }
        } else {
            return -1;
        }
    };

    const handleSurveySelected = (id: number): void => {
        const selectedSurvey = surveys.find((survey: SurveyInfo) => survey.id === id);
        if (selectedSurvey && selectedSurvey.id !== state.selectedSurvey.id) {
            insightService
                .getInsight(selectedSurvey.id, 0, 0, [], languageCode)
                .then(data => {
                    const {
                        confidentialityResult,
                        correlations,
                        demographicFields,
                        responseCount,
                        previousPeriodLabels
                    } = data;
                    const mappedDemographicFields = Object.entries(demographicFields).map(entry => ({
                        id: entry[0],
                        name: entry[1]
                    }));
                    const sortedDemographic = sortArray(mappedDemographicFields, "name", "asc");

                    dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
                    const autoSelectedOutcome = getAutoSelectedOutcomeId(correlations);
                    const updatedKeyDriverList = getKeyDriverList(correlations, autoSelectedOutcome);
                    const autoSelectedKeyDriverId = updatedKeyDriverList[0];
                    dispatch({
                        type: InsightActions.SET_INIT_DATA,
                        payload: {
                            selectedSurvey: selectedSurvey,
                            status: ComponentStatus.idle,
                            correlation: correlations,
                            keyDriverList: getKeyDriverList(correlations, autoSelectedOutcome),
                            currentFilter: initFilter,
                            isFilterDialogOpen: false,
                            confidentialityResult: confidentialityResult,
                            responseCount: responseCount,
                            demographicField: sortedDemographic,
                            selectedOutcome: autoSelectedOutcome,
                            selectedKeyDriver: autoSelectedKeyDriverId,
                            selectedDemographicField: "",
                            previousPeriodLabels: previousPeriodLabels,
                            expandedKeyDriverSections: [],
                            selectedKeyDriverSection: "",
                            showOrgOverall: false,
                            customizedShowPP: true,
                            showPreviousPeriod: previousPeriodLabels && previousPeriodLabels.length > 0,
                            selectedKeyItemId: getSelectedKeyItemId(
                                correlations,
                                autoSelectedOutcome,
                                autoSelectedKeyDriverId
                            )
                        }
                    });
                    loadDemographics({ variables: { surveyId: selectedSurvey.id, languageCode } });
                    if (!user.settings.insight || id !== user.settings.insight.surveySelected) {
                        setKeyDriverInitUserSetting(id, autoSelectedOutcome, autoSelectedKeyDriverId);
                    }
                })
                .catch(() => {
                    dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.error } });
                });
        }
    };

    const restoreInsight = async (
        surveyId: number,
        filterItems: FilterParameter[],
        selectedOutcome: number,
        selectedKeyDriver: number,
        selectedDemographicField: string,
        showOrgOverall: boolean,
        customizedShowPP: boolean
    ): Promise<void> => {
        const selectedSurvey = surveys.find((survey: SurveyInfo) => survey.id === surveyId);
        const restoredFilter = user.settings.filtersItems ? { ...state.currentFilter, items: filterItems } : initFilter;
        if (selectedSurvey && surveyId !== emptySurveyInfo.id) {
            insightService
                .getInsight(surveyId, 0, 0, filterItems, languageCode)
                .then(data => {
                    const {
                        confidentialityResult,
                        correlations,
                        demographicFields,
                        responseCount,
                        previousPeriodLabels
                    } = data;
                    const mappedDemographicFields = Object.entries(demographicFields).map(entry => ({
                        id: entry[0],
                        name: entry[1]
                    }));
                    const sortedDemographic = sortArray(mappedDemographicFields, "name", "asc");

                    if (confidentialityResult === ConfidentialityResult.success) {
                        dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
                        dispatch({
                            type: InsightActions.SET_INIT_DATA,
                            payload: {
                                selectedSurvey: selectedSurvey,
                                status: ComponentStatus.idle,
                                correlation: correlations,
                                keyDriverList: getKeyDriverList(correlations, selectedOutcome),
                                currentFilter: restoredFilter,
                                isFilterDialogOpen: false,
                                confidentialityResult: confidentialityResult,
                                responseCount: responseCount,
                                demographicField: sortedDemographic,
                                selectedOutcome: selectedOutcome,
                                selectedKeyDriver: selectedKeyDriver,
                                selectedDemographicField: selectedDemographicField,
                                previousPeriodLabels: previousPeriodLabels,
                                expandedKeyDriverSections: [],
                                selectedKeyDriverSection: "",
                                showOrgOverall: showOrgOverall,
                                customizedShowPP: customizedShowPP,
                                showPreviousPeriod: previousPeriodLabels && previousPeriodLabels.length > 0,
                                selectedKeyItemId: getSelectedKeyItemId(
                                    correlations,
                                    selectedOutcome,
                                    selectedKeyDriver
                                )
                            }
                        });
                        setPreSelection({
                            ...preSelection,
                            showOrgOverall: showOrgOverall,
                            customizedShowPP: customizedShowPP
                        });
                        loadDemographics({ variables: { surveyId: selectedSurvey.id, languageCode } });
                        if (selectedKeyDriver == -1) {
                            const autoSelectedOutcomeId = getAutoSelectedOutcomeId(correlations);
                            handleSelectOutcomeFirstRender(autoSelectedOutcomeId.toString(), correlations);
                        }
                    } else {
                        dispatch({
                            type: InsightActions.SET_INIT_DATA,
                            payload: {
                                selectedSurvey: selectedSurvey,
                                status: ComponentStatus.idle,
                                correlation: [],
                                keyDriverList: [],
                                currentFilter: restoredFilter,
                                isFilterDialogOpen: false,
                                confidentialityResult: confidentialityResult,
                                responseCount: responseCount,
                                demographicField: [],
                                selectedOutcome: -1,
                                selectedKeyDriver: -1,
                                selectedDemographicField: "",
                                previousPeriodLabels: [],
                                expandedKeyDriverSections: [],
                                selectedKeyDriverSection: "",
                                showOrgOverall: false,
                                customizedShowPP: true,
                                showPreviousPeriod: false,
                                selectedKeyItemId: -1
                            }
                        });
                        setPreSelection({
                            ...preSelection,
                            showOrgOverall: false,
                            customizedShowPP: true
                        });
                        loadDemographics({ variables: { surveyId: selectedSurvey.id, languageCode } });
                    }
                })
                .catch(() => {
                    dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.error } });
                });
        }
    };

    const handleBulkExport = (): void => {
        setSnackbar({ isOpen: true, message: lang.startCommentBulkExport });
    };
    const handleUpdateBulkExportDemographic = (updatedBulkExport: BulkExportDemographic): void => {
        setBulkExportDemographic(updatedBulkExport);
    };
    const handleBulkExportDialogClose = (): void => {
        setBulkExportDialogOpen(false);
    };

    const handleStartExport = (isBulkExport: boolean): void => {
        if (isBulkExport) {
            setBulkExportDialogOpen(true);
        } else {
            setExportDialogOpen(true);
        }
    };
    const handleExportDialogClose = (): void => {
        setExportDialogOpen(false);
    };
    const handleExport = (mainTitle: string): void => {
        setExportDialogOpen(false);
        setSnackbar({ isOpen: true, message: messageComposer(lang.snapshotExportMsg[0], mainTitle) });
        const selectedDemographicValue =
            state.demographicField.find(d => d.id === state.selectedDemographicField)?.name ?? "";
        const newDemographicFilter = {
            combinationOperator: "AND",
            comparisonOperator: "EQUALS",
            filterType: "ContactField",
            field: { id: parseInt(state.selectedDemographicField), fieldName: selectedDemographicValue },
            target: [{ id: state.selectedKeyDriverSection, text: state.selectedKeyDriverSection }],
            order: state.currentFilter.items.length
        } as FilterParameter;
        const updatedFilter =
            state.selectedKeyDriverSection == "" || state.selectedKeyDriverSection === state.keyDriverScore[0].text
                ? state.currentFilter.items
                : [...state.currentFilter.items, newDemographicFilter];
        const outcomeIsItem = state.correlation.find(c => c.id == state.selectedOutcome) == undefined;
        orgChartService
            .exportInsightReport(
                state.selectedSurvey.id,
                mainTitle,
                0,
                0,
                updatedFilter,
                languageCode,
                outcomeIsItem,
                state.selectedOutcome,
                state.selectedKeyDriver,
                state.selectedDemographicField === "" ? null : parseInt(state.selectedDemographicField),
                !state.customizedShowPP,
                false,
                // hide org overall for now
                //state.showOrgOverall
                false
            )
            .then((blob: unknown) => {
                saveAs(blob as Blob, mainTitle);
                setSnackbar({ isOpen: false, message: "" });
            })
            .catch(() => {
                setMessage(lang.somethingWentWrong);
            });
    };

    const handleCloseSnackbar = (): void => {
        setSnackbar({ isOpen: false, message: "" });
    };

    const handleOpenFilter = (): void => {
        dispatch({
            type: InsightActions.OPEN_CLOSE_FILTER,
            payload: { isFilterDialogOpen: true }
        });
    };
    const handleCloseFilters = (): void => {
        dispatch({
            type: InsightActions.OPEN_CLOSE_FILTER,
            payload: { isFilterDialogOpen: false }
        });
    };

    const updateInsightData = (filter: Filter): void => {
        dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
        insightService
            .getInsight(state.selectedSurvey.id, 0, 0, filter.items, languageCode)
            .then(data => {
                const { confidentialityResult, correlations, responseCount, previousPeriodLabels, demographicFields } =
                    data;
                if (confidentialityResult === ConfidentialityResult.success) {
                    const mappedDemographicFields = Object.entries(demographicFields).map(entry => ({
                        id: entry[0],
                        name: entry[1]
                    }));
                    const sortedDemographic = sortArray(mappedDemographicFields, "name", "asc");
                    dispatch({
                        type: InsightActions.UPDATE_CORRELATION_DATA,
                        payload: {
                            correlation: correlations,
                            currentFilter: filter,
                            keyDriverList: getKeyDriverList(correlations, state.selectedOutcome),
                            selectedKeyDriver: getKeyDriverList(correlations, state.selectedOutcome)[0],
                            isFilterDialogOpen: false,
                            confidentialityResult: confidentialityResult,
                            responseCount: responseCount,
                            demographicFields: sortedDemographic,
                            expandedKeyDriverSections: [],
                            selectedKeyDriverSection: state.selectedKeyDriverSection,
                            showPreviousPeriod: previousPeriodLabels && previousPeriodLabels.length > 0,
                            previousPeriodLabels: previousPeriodLabels,
                            selectedKeyItemId: getSelectedKeyItemId(
                                correlations,
                                state.selectedOutcome,
                                getKeyDriverList(correlations, state.selectedOutcome)[0]
                            )
                        }
                    });
                    loadDemographics({ variables: { surveyId: state.selectedSurvey.id, languageCode } });
                    if (!state.selectedOutcome || state.selectedOutcome == -1) {
                        const autoSelectedOutcomeId = getAutoSelectedOutcomeId(correlations);
                        handleSelectOutcomeFirstRender(autoSelectedOutcomeId.toString(), correlations);
                    }
                } else {
                    dispatch({
                        type: InsightActions.SET_CONFIDENTIALITY_STATUS,
                        payload: { confidentialityResult: confidentialityResult }
                    });
                    dispatch({
                        type: InsightActions.SET_FILTER,
                        payload: { currentFilter: filter, responseCount: responseCount }
                    });
                }
            })
            .catch(() => {
                dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.error } });
            })
            .finally(() => {
                setUserSettingAfterFilterApplied(filter);
                dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
            });
    };

    const handleSelectOutcomeFirstRender = (id: string, correlations: CorrelationInfo[]): void => {
        dispatch({
            type: InsightActions.SELECT_OUTCOME,
            payload: { selectedOutcome: parseInt(id) }
        });
        const updatedKeyDriverList = getKeyDriverList(correlations, parseInt(id));
        const selectedKeyDriverId = updatedKeyDriverList[0];
        const selectedKeyItemId = getSelectedKeyItemId(correlations, selectedKeyDriverId, parseInt(id));
        dispatch({
            type: InsightActions.UPDATE_SELECTED_KEY_ITEM,
            payload: {
                selectedKeyItemId: selectedKeyItemId
            }
        });
        dispatch({
            type: InsightActions.SELECT_KEYDRIVER,
            payload: { selectedKeyDriver: selectedKeyDriverId }
        });
        const updatedSettings = {
            ...user.settings,
            insight: {
                ...user.settings.insight,
                selectedOutcome: parseInt(id),
                selectedKeyDriver: selectedKeyDriverId
            }
        };
        updateUserSettings(updatedSettings);
    };

    const handleSelectOutcome = (id: string): void => {
        dispatch({
            type: InsightActions.SELECT_OUTCOME,
            payload: { selectedOutcome: parseInt(id) }
        });
        dispatch({
            type: InsightActions.SELECT_EXPANDED_SECTION,
            payload: { sectionTitle: [] }
        });
        const updatedKeyDriverList = getKeyDriverList(state.correlation, parseInt(id));
        const selectedKeyDriverId = updatedKeyDriverList[0];
        const selectedKeyItemId = getSelectedKeyItemId(state.correlation, parseInt(id), selectedKeyDriverId);
        dispatch({
            type: InsightActions.UPDATE_SELECTED_KEY_ITEM,
            payload: {
                selectedKeyItemId: selectedKeyItemId
            }
        });
        dispatch({
            type: InsightActions.UPDATE_KEYDRIVER_LIST,
            payload: { keyDriverList: updatedKeyDriverList }
        });
        dispatch({
            type: InsightActions.SELECT_KEYDRIVER,
            payload: { selectedKeyDriver: selectedKeyDriverId }
        });
        const updatedSettings = {
            ...user.settings,
            insight: {
                ...user.settings.insight,
                selectedOutcome: parseInt(id),
                selectedKeyDriver: selectedKeyDriverId
            }
        };
        updateUserSettings(updatedSettings);
        setAnchorEl(null);
    };

    const handleSelectKeyDriver = (value: AutocompleteValue | null): void => {
        if (!value) return;
        const { id } = value;

        dispatch({
            type: InsightActions.SELECT_KEYDRIVER,
            payload: { selectedKeyDriver: id }
        });
        dispatch({
            type: InsightActions.SELECT_EXPANDED_SECTION,
            payload: { sectionTitle: [] }
        });
        const selectedKeyItemId = getSelectedKeyItemId(state.correlation, state.selectedOutcome, id);
        dispatch({
            type: InsightActions.UPDATE_SELECTED_KEY_ITEM,
            payload: {
                selectedKeyItemId: selectedKeyItemId
            }
        });
        const updatedSettings = {
            ...user.settings,
            insight: {
                ...user.settings.insight,
                selectedKeyDriver: id
            }
        };
        updateUserSettings(updatedSettings);
    };

    const clearSelectedDemographicField = (): void => {
        dispatch({
            type: InsightActions.SELECT_DEMOGRAPHIC,
            payload: { selectedDemographicField: "" }
        });
        dispatch({
            type: InsightActions.SELECT_EXPANDED_SECTION,
            payload: { sectionTitle: [] }
        });
        const updatedSettings = {
            ...user.settings,
            insight: {
                ...user.settings.insight,
                selectedDemographicField: ""
            }
        };
        updateUserSettings(updatedSettings);
    };

    const handleSelectDemographic = (value: AutocompleteValue | null): void => {
        if (!value) {
            clearSelectedDemographicField();
            return;
        }
        const { id } = value;

        dispatch({
            type: InsightActions.SELECT_DEMOGRAPHIC,
            payload: { selectedDemographicField: id.toString() }
        });
        dispatch({
            type: InsightActions.SELECT_EXPANDED_SECTION,
            payload: { sectionTitle: [] }
        });
        const updatedSettings = {
            ...user.settings,
            insight: {
                ...user.settings.insight,
                selectedDemographicField: id.toString()
            }
        };
        updateUserSettings(updatedSettings);
    };

    const handleKeyDriverSectionScoreClick = (sectionTitle: string): void => {
        dispatch({
            type: InsightActions.SELECT_KEYDIVER_SECTION,
            payload: { selectedKeyDriverSection: sectionTitle }
        });
        if (state.expandedKeyDriverSections.includes(sectionTitle)) {
            const updatedSections = [...state.expandedKeyDriverSections].filter(s => s !== sectionTitle);
            dispatch({
                type: InsightActions.SELECT_EXPANDED_SECTION,
                payload: { sectionTitle: updatedSections }
            });
        } else {
            const updatedSections = [...state.expandedKeyDriverSections, sectionTitle];
            dispatch({
                type: InsightActions.SELECT_EXPANDED_SECTION,
                payload: { sectionTitle: updatedSections }
            });
        }
    };
    const handleKeyDriverSectionScoreSelected = (sectionTitle: string): void => {
        dispatch({
            type: InsightActions.SELECT_KEYDIVER_SECTION,
            payload: { selectedKeyDriverSection: sectionTitle }
        });
    };
    const handleOpenSurveyItemList = (event: React.MouseEvent<HTMLDivElement>): void => {
        const el = event.currentTarget as Element;
        setAnchorEl(el);
    };

    const handleClosePopover = (): void => {
        setAnchorEl(null);
    };

    const getSelectedOutcomTitle = (): string => {
        if (!state.selectedOutcome || state.selectedOutcome == -1) return lang.outcome;
        const selectedSectionOutcome = state.correlation.find(c => c.id === state.selectedOutcome);
        if (selectedSectionOutcome) {
            return selectedSectionOutcome.label;
        } else {
            const itemOutcome = state.correlation.map(c => c.items).reduce((a, b) => a.concat(b), []);
            const selectedItemOutcome = itemOutcome.find(i => i.id === state.selectedOutcome);
            if (selectedItemOutcome) {
                return selectedItemOutcome.label;
            } else {
                return lang.outcome;
            }
        }
    };

    const mapSelectedKeyDriverToOption = (option: number): string => {
        const selectedKeyDriverField = state.correlation.find(c => c.id === option);
        const selectedCorrelation = state.correlation.find(c => c.id === state.selectedOutcome);
        if (selectedKeyDriverField && selectedCorrelation) {
            const selectedCorrelationValue = selectedCorrelation.correlations.find(
                i => i.isSection === true && i.id === option
            );
            if (selectedCorrelationValue && selectedCorrelationValue.value) {
                return `${selectedKeyDriverField.label} (${selectedCorrelationValue.value
                    .toFixed(2)
                    .replace(/0./, ".")})`;
            } else {
                return "";
            }
        } else if (selectedKeyDriverField && !selectedCorrelation) {
            const itemsCorrelations = state.correlation.map(c => c.items).reduce((a, b) => a.concat(b), []);
            const itemKeyDriverList = itemsCorrelations.find(i => i.id === state.selectedOutcome);
            if (itemKeyDriverList) {
                const selectedItemCorrelationValue = itemKeyDriverList.correlations.find(c => c.id === option);
                if (selectedItemCorrelationValue && selectedItemCorrelationValue.value) {
                    return `${selectedKeyDriverField.label} (${selectedItemCorrelationValue.value
                        .toFixed(2)
                        .replace(/0./, ".")})`;
                } else {
                    return "";
                }
            } else {
                return "";
            }
        } else {
            return "";
        }
    };

    const mapKeyDriverListToOptions = (keyDriverList: number[]) => {
        return keyDriverList.map(l => {
            return {
                id: l,
                label: mapSelectedKeyDriverToOption(l)
            };
        });
    };

    const getSelectedkeyDriverLabel = (id: number) => {
        mapKeyDriverListToOptions(state.keyDriverList);
        const found = mapKeyDriverListToOptions(state.keyDriverList).find(k => k.id === id);
        if (found) {
            return {
                id: found.id,
                label: found.label
            };
        }
        return {
            id: -1,
            label: ""
        };
    };

    const getSelectedkeyDemographicField = (strId: string) => {
        const found = state.demographicField.find(k => k.id === strId);
        if (found) {
            return {
                id: +found.id,
                label: found.name
            };
        }
        return {
            id: -1,
            label: ""
        };
    };

    const handleComparisonOpen = (event: React.MouseEvent<HTMLButtonElement>): void => {
        const el = event.currentTarget as Element;
        setComparisonAnchorEl(el);
    };

    const handleCloseComparisonPopover = (): void => {
        setPreSelection({
            ...preSelection,
            showOrgOverall: state.showOrgOverall,
            customizedShowPP: state.customizedShowPP
        });
        setComparisonAnchorEl(null);
    };

    const setShowOrgOverall = (): void => {
        setPreSelection({ ...preSelection, showOrgOverall: !preSelection.showOrgOverall });
    };
    const toggleCustomizedPP = (): void => {
        setPreSelection({
            ...preSelection,
            customizedShowPP: !preSelection.customizedShowPP
        });
    };

    const handleApplyComparison = (): void => {
        dispatch({
            type: InsightActions.APPLY_PRESELECTION,
            payload: {
                showOrgOverall: preSelection.showOrgOverall,
                customizedShowPP: preSelection.customizedShowPP
            }
        });
        const updatedSettings = {
            ...user.settings,
            insight: {
                ...user.settings.insight,
                showOrgOverall: preSelection.showOrgOverall,
                customizedShowPP: preSelection.customizedShowPP
            }
        };
        updateUserSettings(updatedSettings);
        setComparisonAnchorEl(null);
    };

    const checkKeyItemCorrelationThreshold = (): boolean => {
        if (!state.keyDriverScore || state.keyDriverScore.length === 0 || !state.selectedKeyDriverSection) return false;
        const selectedKeyDriverScore = state.keyDriverScore.find(k => k.text === state.selectedKeyDriverSection);
        if (!selectedKeyDriverScore) return false;
        return selectedKeyDriverScore.count >= HIDE_ELEMENTS.correlationLessThan;
    };

    const handleUpdateSelectedKeyItem = (id: number): void => {
        dispatch({
            type: InsightActions.UPDATE_SELECTED_KEY_ITEM,
            payload: {
                selectedKeyItemId: id
            }
        });
    };

    const handleUpdateCommentLength = (count: number): void => {
        setCommentLength(count);
    };

    useEffect(() => {
        const restoredState = user.settings.insight;
        if (
            restoredState &&
            restoredState.surveySelected !== emptySurveyInfo.id &&
            mapSurveys.find(s => s.id === restoredState.surveySelected) !== undefined
        ) {
            dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
            restoreInsight(
                restoredState.surveySelected,
                user.settings.filtersItems,
                restoredState.selectedOutcome,
                restoredState.selectedKeyDriver,
                restoredState.selectedDemographicField,
                // hide org overall for now
                //restoredState.showOrgOverall,
                false,
                restoredState.customizedShowPP
            );
        } else {
            dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
        }
    }, []);

    useEffect(() => {
        if (state.selectedKeyDriver && state.selectedKeyDriver !== -1) {
            dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
            const selectedDemographicFieldId =
                state.selectedDemographicField === "" ? null : parseInt(state.selectedDemographicField);
            insightService
                .getInsightLookdown(
                    state.selectedSurvey.id,
                    0,
                    0,
                    state.currentFilter.items,
                    languageCode,
                    selectedDemographicFieldId,
                    state.selectedKeyDriver,
                    // hide show org overall for now
                    //state.showOrgOverall,
                    false,
                    null
                )
                .then(data => {
                    const defautSelectedSectionTitle =
                        state.selectedKeyDriverSection &&
                        state.selectedKeyDriverSection !== state.keyDriverScore[0].text
                            ? state.selectedKeyDriverSection
                            : data[0].text ?? null;
                    dispatch({
                        type: InsightActions.UPDATE_KEYDRIVER_SCORE,
                        payload: { keyDriverScore: data }
                    });
                    dispatch({
                        type: InsightActions.SELECT_KEYDIVER_SECTION,
                        payload: { selectedKeyDriverSection: defautSelectedSectionTitle }
                    });
                })
                .catch(() => {
                    dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.error } });
                })
                .finally(() => {
                    dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
                });
        } else {
            dispatch({
                type: InsightActions.UPDATE_KEYDRIVER_SCORE,
                payload: { keyDriverScore: [] }
            });
        }
    }, [state.selectedKeyDriver, state.selectedDemographicField, state.currentFilter, state.showOrgOverall]);

    useEffect(() => {
        if (state.selectedSurvey.id !== emptySurveyInfo.id && state.selectedKeyDriverSection !== "") {
            dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.loading } });
            let updatedFilter = [...state.currentFilter.items];
            if (state.selectedKeyDriverSection !== state.keyDriverScore[0].text) {
                const selectedDemographicValue =
                    state.demographicField.find(d => d.id === state.selectedDemographicField)?.name ?? "";
                const newDemographicFilter = {
                    combinationOperator: "AND",
                    comparisonOperator: "EQUALS",
                    filterType: "ContactField",
                    field: { id: parseInt(state.selectedDemographicField), fieldName: selectedDemographicValue },
                    target: [{ id: state.selectedKeyDriverSection, text: state.selectedKeyDriverSection }],
                    order: state.currentFilter.items.length
                } as FilterParameter;
                updatedFilter = [...state.currentFilter.items, newDemographicFilter];
            }
            insightService
                .getInsight(state.selectedSurvey.id, 0, 0, updatedFilter, languageCode)
                .then(data => {
                    const { confidentialityResult, correlations } = data;
                    if (confidentialityResult === ConfidentialityResult.success) {
                        dispatch({
                            type: InsightActions.UPDATE_KEYDRIVER_CORRELATION,
                            payload: {
                                correlation: correlations,
                                keyDriverList: getKeyDriverList(correlations, state.selectedOutcome),
                                confidentialityResult: confidentialityResult
                            }
                        });
                    }
                })
                .catch(() => {
                    dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.error } });
                })
                .finally(() => {
                    dispatch({ type: InsightActions.SET_STATUS, payload: { status: ComponentStatus.idle } });
                });
        }
    }, [state.selectedKeyDriverSection]);

    useEffect(() => {
        if (state.selectedSurvey.id !== emptySurveyInfo.id) {
            window.location.reload();
        }
    }, [languageCode]);

    useEffect(() => {
        setLoading(state.status === ComponentStatus.loading);
    }, [state.status]);

    if (state.status === ComponentStatus.error) {
        return <Error500 />;
    }

    return (
        <ReportsLayout
            mapSurveys={mapSurveys}
            selectedSurvey={selectedMapSurvey}
            handleSurveySelected={handleSurveySelected}
            handleStartExport={handleStartExport}
            handleOpenFilter={handleOpenFilter}
            exportDisabled={
                state.selectedSurvey.id === emptySurveyInfo.id ||
                state.confidentialityResult !== ConfidentialityResult.success ||
                commentLenth === 0 ||
                !user.isCommentViewer ||
                !checkKeyItemCorrelationThreshold()
            }
            filterDisabled={state.selectedSurvey.id === emptySurveyInfo.id}
            exportDataTestId={"btn-insight-export"}
            filter={state.currentFilter}
            onDeleteFilterItem={updateInsightData}
            isEmptyPage={shouldShowEmptyPage()}
            contactFields={contactFields}
        >
            <>
                <div className={classes.infoHeader}>
                    <div className={classes.surveyItemContainer}>
                        <div
                            onClick={(e): void => {
                                handleOpenSurveyItemList(e);
                            }}
                            data-testid="insight-outcome-button"
                            className={classes.surveyItemButton}
                        >
                            <div className={classes.surveyItemButtonTextWrapper}>
                                <div className={classes.surveyItemButtonLabelWrapper}>
                                    {state.selectedOutcome && state.selectedOutcome !== -1 && (
                                        <div className={classes.surveyItemButtonLabel}>{lang.outcome}</div>
                                    )}
                                    <div className={classes.surveyItemButtonText}>{getSelectedOutcomTitle()}</div>
                                </div>
                                <ArrowDropDownIcon style={{ color: "grey" }} />
                            </div>
                        </div>
                    </div>
                    <div className={classes.infoHeaderItem_dropDown}>
                        <Autocomplete
                            id="insight-select-keyDriver-fields-combo-box"
                            options={mapKeyDriverListToOptions(state.keyDriverList)}
                            noOptionsText={lang.noOptionsAvailable}
                            value={getSelectedkeyDriverLabel(state.selectedKeyDriver)}
                            disabled={state.selectedOutcome === -1}
                            onChange={handleSelectKeyDriver}
                            placeholder={lang.dimensions}
                            autoHighlight
                            background="white"
                            label={lang.dimensions}
                        />
                    </div>
                    <div className={classes.infoHeaderItem_dropDown}>
                        <Autocomplete
                            id="insight-select-demographic-fields-combo-box"
                            options={state.demographicField.map(c => {
                                return {
                                    id: +c.id,
                                    label: c.name
                                };
                            })}
                            getOptionDisabled={option => {
                                return option.id.toString() === state.selectedDemographicField;
                            }}
                            noOptionsText={lang.noOptionsAvailable}
                            value={getSelectedkeyDemographicField(state.selectedDemographicField)}
                            onChange={handleSelectDemographic}
                            placeholder={lang.demographics}
                            autoHighlight
                            background="white"
                            label={lang.demographics}
                        />
                    </div>
                    <Box pl={3} pr={3} alignSelf="center">
                        <Button
                            variant="text"
                            disabled={!showPP}
                            onClick={(e): void => {
                                handleComparisonOpen(e!);
                            }}
                            data-testid="btn-insight-open-comparison"
                        >
                            {lang.comparisons}
                        </Button>
                    </Box>
                </div>
                {state.confidentialityResult === ConfidentialityResult.tooSimilar ? (
                    <InsightConfidentialityCheck responseCount={state.responseCount}>
                        <ShowTooSimilarConfidentialityMessage />
                    </InsightConfidentialityCheck>
                ) : state.confidentialityResult === ConfidentialityResult.tooFewResponsesToCorrelate ? (
                    <InsightConfidentialityCheck responseCount={state.responseCount}>
                        <ShowNotEnoughCorrelationResponse />
                    </InsightConfidentialityCheck>
                ) : state.confidentialityResult === ConfidentialityResult.belowThreshold ? (
                    <InsightConfidentialityCheck responseCount={state.responseCount}>
                        <ShowBelowConfidentialityMessage />
                    </InsightConfidentialityCheck>
                ) : (
                    state.status !== ComponentStatus.loading && (
                        <div className={classes.dataContent}>
                            <div className={classes.sidebar} data-testid="sideBar">
                                <InsightKeyDriverScore
                                    correlation={state.correlation}
                                    responseCount={state.responseCount}
                                    keyDriverScore={state.keyDriverScore}
                                    selectedKeyDriverSection={state.selectedKeyDriverSection}
                                    handleKeyDriverSectionScoreSelected={handleKeyDriverSectionScoreSelected}
                                    handleKeyDriverSectionScoreClick={handleKeyDriverSectionScoreClick}
                                    expandedKeyDriverSections={state.expandedKeyDriverSections}
                                    showOrgOverall={state.showOrgOverall}
                                    customizedShowPP={state.customizedShowPP}
                                    previousPeriodLabels={state.previousPeriodLabels}
                                    keyDriverScoreThreshold={checkKeyItemCorrelationThreshold()}
                                    selectedKeyDriver={state.selectedKeyDriver}
                                    selectedOutcome={state.selectedOutcome}
                                    selectedKeyItemId={state.selectedKeyItemId}
                                    updateSelectedKeyItem={handleUpdateSelectedKeyItem}
                                />
                            </div>
                            <div className={classes.comment}>
                                <InsightComment
                                    keyDriverScoreThreshold={checkKeyItemCorrelationThreshold()}
                                    selectedKeyItemId={state.selectedKeyItemId}
                                    correlation={state.correlation}
                                    surveyId={state.selectedSurvey.id}
                                    currentFilter={state.currentFilter}
                                    demographicField={state.demographicField}
                                    selectedDemographicField={state.selectedDemographicField}
                                    selectedKeyDriverSection={state.selectedKeyDriverSection}
                                    keyDriverScore={state.keyDriverScore}
                                    updateCommentLength={handleUpdateCommentLength}
                                />
                            </div>
                        </div>
                    )
                )}
                {isExportDialogOpen && (
                    <DialogExportSlide
                        dialogTitle={lang.exportPowerPoint}
                        isOpen={isExportDialogOpen}
                        onClose={handleExportDialogClose}
                        onSubmitCallback={handleExport}
                    />
                )}
                {isBulkExportDialogOpen && (
                    <DialogBulkExportSlide
                        title={lang.exportPowerPoint}
                        isOpen={isBulkExportDialogOpen}
                        onClose={handleBulkExportDialogClose}
                        onSubmitCallback={handleBulkExport}
                        contactFields={contactFields}
                        bulkExportDemographic={bulkExportDemographic}
                        updateBulkExportDemographic={handleUpdateBulkExportDemographic}
                    />
                )}
                <Snackbar
                    open={snackbar.isOpen}
                    message={snackbar.message}
                    handleClose={handleCloseSnackbar}
                    autohideOff
                />
                {state.isFilterDialogOpen && (
                    <Filters
                        onCloseFilter={handleCloseFilters}
                        isOpen={state.isFilterDialogOpen}
                        onFiltersApplied={updateInsightData}
                        currentFilter={state.currentFilter}
                        filterMode={FilterMode.insight}
                        surveyId={state.selectedSurvey.id}
                        contactFields={contactFields}
                    />
                )}
                {!!anchorEl && (
                    <InsightOutcomePopover
                        anchorEl={anchorEl}
                        correlation={state.correlation}
                        handleClosePopover={handleClosePopover}
                        handleSelectOutcome={handleSelectOutcome}
                    />
                )}
                {!!comparisonAnchorEl && (
                    <InsightComparisonPopover
                        comparisonAnchorEl={comparisonAnchorEl}
                        handleCloseComparisonPopover={handleCloseComparisonPopover}
                        showPP={showPP}
                        preSelection={preSelection}
                        setShowOrgOverall={setShowOrgOverall}
                        toggleCustomizedPP={toggleCustomizedPP}
                        handleApplyComparison={handleApplyComparison}
                        disableRule={
                            state.showOrgOverall === preSelection.showOrgOverall &&
                            state.customizedShowPP === preSelection.customizedShowPP
                        }
                        enableComparison={
                            state.showOrgOverall !== preSelection.showOrgOverall ||
                            state.customizedShowPP !== preSelection.customizedShowPP
                        }
                    />
                )}
            </>
        </ReportsLayout>
    );
};

export default Insight;
