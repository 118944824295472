import { Action } from "core/interfaces";
import { ResponseRateActions } from "../action";
import { ResponseRateState } from "../interface";

export const responseRateReducer = (state: ResponseRateState, action: Action): ResponseRateState => {
    const { type, payload } = action;
    switch (type) {
        case ResponseRateActions.SET_BREAKDOWN_ITEMS:
            return {
                ...state,
                breakdownItems: payload.breakdownItems,
                responseRate: payload.responseRate ?? state.responseRate
            };
        case ResponseRateActions.SET_RESPONSE_RATE:
            return {
                ...state,
                responseRate: payload.responseRate,
                overallResponseRate: payload.overallResponseRate,
                lastTimeUpdated: payload.lastTimeUpdated
            };

        case ResponseRateActions.SET_CURRENT_SELECT_ID:
            return {
                ...state,
                currentSelectId: payload.currentSelectId
            };

        case ResponseRateActions.ON_SELECT_SURVEY:
            return {
                ...state,
                contactFieldsOptions: payload.contactFieldsOptions,
                surveyItemsOptions: payload.surveyItemsOptions,
                breakdownItems: payload.breakdownItems,
                lastTimeUpdated: payload.lastTimeUpdated
            };
        case ResponseRateActions.SET_STATUS:
            return {
                ...state,
                status: payload.status
            };

        case ResponseRateActions.SELECT_SURVEY:
            return {
                ...state,
                surveySelected: payload.surveySelected
            };
        case ResponseRateActions.OPEN_EXPORT:
            return { ...state, isExportDialogOpen: payload.isExportDialogOpen };
        case ResponseRateActions.OPEN_FILTER:
            return { ...state, isFilterDialogOpen: payload.isFilterDialogOpen };
        case ResponseRateActions.SET_FILTER:
            return {
                ...state,
                currentFilter: payload.currentFilter
            };
        default:
            return {
                ...state
            };
    }
};
