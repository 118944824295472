import React, { ReactElement, useEffect, useReducer } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";

import { makeStyles } from "@mui/styles";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { RouteComponentProps, useHistory, useParams, withRouter } from "react-router-dom";

import { IconButton } from "lib/icon-button";
import { editSurveyMutation, importSurveyMutation } from "api/mutations";
import { surveyQuery } from "api/queries";
import Error500 from "components/errorPage/Error500";
import SidebarHandleState from "components/shared/SidebarHandleState";
import { copyArrayWithObjects } from "core/helpers";
import { useLang, useLoading, useSurveys } from "core/hooks";
import { SurveyEditActions } from "./actions";
import BenchmarksMapping from "./benchmarkMapping/BenchmarkMapping";
import CustomScales from "./customScales/CustomScales";
import { SurveyEditSidebar } from "./enums";
import { emptySurveyInfo } from "./init";
import { EditSurveyInput, SurveyEditState, SurveyInfo } from "./interfaces";
import PreviousPeriod from "./previousPeriod/PreviousPeriod";
import { surveyDetailsReducer } from "./reducers";
import SurveyGeneral from "./SurveyGeneral";
import { Tag } from "components/admin/tags/interface";
import { dateFormat } from "core/constants";
import AssignAccess from "./assignAccess/AssignAccess";
import Dashboard from "./dashboard/Dashboard";
import { ButtonLink } from "lib/button-link";
import { Button } from "lib/button";
import { Typography } from "lib/typography";
import { theme } from "lib/theme";
import dayjs from "dayjs";
import { Box } from "lib/box";
import { useUser } from "core/context/user/useUser";
import { useOrganization } from "core/context/organization/useOrganization";

const useStyles = makeStyles(() => ({
    surveyEditContainer: { height: "100%", boxSizing: "border-box" },
    surveyEditContent: {
        display: "flex",
        height: "calc(100% - 56px)",
        boxSizing: "border-box"
    },
    head: {
        height: 56,
        boxSizing: "border-box",
        width: "100%",
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: theme.border.main
    },
    headerTitleGroup: { display: "flex", float: "left", alignItems: "center" }
}));

const init = (selectedSidebar: SurveyEditSidebar): SurveyEditState => {
    return {
        selectedSidebarItem: selectedSidebar,
        surveySelected: emptySurveyInfo,
        validationError: false,
        forOptimization: false,
        createdTags: []
    };
};

type StateType = {
    selectedSurvey: {
        id: number;
        translations: { name: string }[];
    };
};

type Props = RouteComponentProps<Record<string, string | undefined>, Record<string, unknown>, StateType>;
//this page is being used as the survey editing page AND for importing survey from alchemer (the last step survey optimization: editing survey name or confidentiality),
//the difference is in the url parameters

const SurveyEdit = (props: Props): ReactElement => {
    const classes = useStyles();
    const { setLoading } = useLoading();
    const routeParams = useParams<{ id: string }>();
    const redirectURL = "/admin/results";
    const { lang } = useLang();

    const { setSurveyList, updateSingleSurvey } = useSurveys();
    const { user } = useUser();
    const { organizations } = useOrganization();
    const selectedOrgId = user.settings.selectedOrganization.id;
    let isDashboardEnabled = false;
    const selectedOrganizations = organizations.filter(organization => organization.id === selectedOrgId);
    if (selectedOrganizations.length > 0) {
        const selectedOrganization = selectedOrganizations[0];
        isDashboardEnabled = selectedOrganization.isDashboardEnabled;
    }

    const [state, dispatch] = useReducer(surveyDetailsReducer, SurveyEditSidebar.general, init);

    const history = useHistory();
    const getDashboardEditorViewRule = (): boolean => {
        if (user.isTalentMapAdmin) {
            return state.forOptimization || !state.surveySelected.active;
        } else if (user.isSiteAdmin) {
            return state.forOptimization || !state.surveySelected.active || !isDashboardEnabled;
        } else {
            return false;
        }
    };

    const sidebarItems = [
        { id: SurveyEditSidebar.general, title: lang[SurveyEditSidebar.general] },
        {
            id: SurveyEditSidebar.customScale,
            title: lang[SurveyEditSidebar.customScale],
            disabled: state.forOptimization || !state.surveySelected.active
        },
        {
            id: SurveyEditSidebar.benchmarks,
            title: lang[SurveyEditSidebar.benchmarks],
            disabled: state.forOptimization || !state.surveySelected.active
        },
        {
            id: SurveyEditSidebar.previousPeriod,
            title: lang[SurveyEditSidebar.previousPeriod],
            disabled: state.forOptimization || !state.surveySelected.active
        },
        {
            id: SurveyEditSidebar.dashboard,
            title: lang[SurveyEditSidebar.dashboard],
            disabled: getDashboardEditorViewRule()
        },
        {
            id: SurveyEditSidebar.assignAccess,
            title: lang[SurveyEditSidebar.assignAccess],
            disabled: state.forOptimization || !state.surveySelected.active
        }
    ];

    const [editSurvey] = useMutation(editSurveyMutation, {
        onCompleted: () => {
            updateSingleSurvey(state.surveySelected.id, state.surveySelected);
        }
    });

    const [optimize, { loading: importLoading, error: importError }] = useMutation(importSurveyMutation, {
        onCompleted: data => {
            if (data.importSurvey) {
                const setSurvey = data.importSurvey;
                dispatch({
                    type: SurveyEditActions.SET_SURVEY,
                    payload: { surveySelected: setSurvey }
                });
                setSurveyList(setSurvey);
                setLoading(importLoading);
                history.push(redirectURL);
            }
        }
    });

    const [loadSurvey, { error }] = useLazyQuery(surveyQuery, {
        variables: { ...routeParams },
        onCompleted: data => {
            const setSurvey = {
                ...data.survey,
                translations: copyArrayWithObjects(data.survey.translations),
                closeDate: dayjs(data.survey.closeDate)
            };

            dispatch({
                type: SurveyEditActions.SET_SURVEY,
                payload: { surveySelected: setSurvey }
            });
        }
    });

    useEffect(() => {
        if (routeParams.id === "optimization") {
            const setSurvey = {
                ...emptySurveyInfo,
                id: props.location.state.selectedSurvey.id,
                sgSurveyName: props.location.state.selectedSurvey.translations[0].name,
                translations: [{ name: props.location.state.selectedSurvey.translations[0].name }],
                source: "Compass"
            };

            dispatch({
                type: SurveyEditActions.SET_SURVEY,
                payload: { surveySelected: setSurvey }
            });
            dispatch({
                type: SurveyEditActions.SET_FOR_OPTIMIZATION,
                payload: { forOptimization: true }
            });
        } else {
            loadSurvey();
        }
    }, []);

    useEffect(() => {
        if (importError) {
            setLoading(false);
        }
    }, [importError]);

    const handleSidebarClick = (meuItem: string): void => {
        const updateSidebar = meuItem as keyof typeof SurveyEditSidebar;

        dispatch({
            type: SurveyEditActions.UPDATE_SELECTED_SIDEBAR,
            payload: { selectedSidebarItem: SurveyEditSidebar[updateSidebar] }
        });
    };

    const handleSaveSurvey = (surveyUpdate: EditSurveyInput): void => {
        editSurvey({ variables: { survey: { ...surveyUpdate } } });
    };

    const handleSurveyUpdate = (surveyUpdate: SurveyInfo): void => {
        const setSurvey = {
            ...surveyUpdate,
            translations: copyArrayWithObjects(surveyUpdate.translations)
        };

        dispatch({
            type: SurveyEditActions.SET_SURVEY,
            payload: { surveySelected: setSurvey }
        });
    };

    const handleValidationError = (setError: boolean): void => {
        dispatch({
            type: SurveyEditActions.SET_VALIDATION_ERROR,
            payload: { validationError: setError }
        });
    };

    const redirect = (): void => {
        if (!state.validationError) {
            history.push(redirectURL);
        }
    };

    const handleAddTag = (tag: Tag): void => {
        dispatch({
            type: SurveyEditActions.ADD_TAG,
            payload: { tag: tag }
        });
    };

    const handleDeleteTag = (tag: Tag): void => {
        dispatch({
            type: SurveyEditActions.DELETE_TAG,
            payload: { tag: tag }
        });
    };

    const handleAddCreatedOrgTags = (tags: Tag[]): void => {
        dispatch({
            type: SurveyEditActions.ADD_ORG_TAGS,
            payload: { tags: tags }
        });
    };

    useEffect(() => {
        if (importLoading) {
            setLoading(importLoading);
        }
    }, [importLoading, setLoading]);

    const handleSaveButton = (): void => {
        if (!state.validationError) {
            optimize({
                variables: {
                    orgId: user.settings.selectedOrganization.id,
                    sgSurveyId: props.location.state.selectedSurvey.id,
                    sgSurveyName: state.surveySelected.translations[0].name,
                    confidentialityThreshold: state.surveySelected.confidentialityThreshold,
                    tagIds: state.createdTags.map(t => t.id),
                    closeDate: state.surveySelected.closeDate ? state.surveySelected.closeDate.format(dateFormat) : ""
                }
            });
        } else {
            return;
        }
    };

    if (error) return <Error500 />;

    return (
        <div className={classes.surveyEditContainer}>
            <div className={classes.head}>
                <Box display="flex" alignItems="center">
                    {!props.location.state && (
                        <IconButton size="small" onClick={redirect} data-testid="result_page_back_btn">
                            <ArrowBackOutlinedIcon />
                        </IconButton>
                    )}
                    <Typography variant="h6" style={{ paddingLeft: "4px" }}>
                        {state.forOptimization ? lang.resultOptimization : state.surveySelected.translations[0].name}
                    </Typography>
                </Box>
                {props.location.state && (
                    <Box display="flex" gap={2}>
                        <ButtonLink variant="outlined" link={redirectURL} data-testid="btn-survey-edit-cancel">
                            {lang.cancel}
                        </ButtonLink>
                        <Button onClick={handleSaveButton} data-testid="btn-survey-edit-save">
                            {lang.save}
                        </Button>
                    </Box>
                )}
            </div>
            <div className={classes.surveyEditContent}>
                <SidebarHandleState
                    itemList={sidebarItems}
                    onClick={handleSidebarClick}
                    itemSelected={state.selectedSidebarItem}
                />
                {state.selectedSidebarItem === SurveyEditSidebar.general && state.surveySelected.id > 0 && (
                    <SurveyGeneral
                        surveySelected={state.surveySelected}
                        onSurveyUpdate={handleSurveyUpdate}
                        onSaveSurvey={handleSaveSurvey}
                        onValidationError={handleValidationError}
                        forOptimization={state.forOptimization}
                        addCreatedTag={handleAddTag}
                        addCreatedOrgTags={handleAddCreatedOrgTags}
                        deleteCreatedTag={handleDeleteTag}
                    />
                )}
                {state.selectedSidebarItem === SurveyEditSidebar.benchmarks && state.surveySelected.id > 0 && (
                    <BenchmarksMapping surveySelectedId={state.surveySelected.id} />
                )}
                {state.selectedSidebarItem === SurveyEditSidebar.customScale && state.surveySelected.id > 0 && (
                    <CustomScales surveySelectedId={state.surveySelected.id} />
                )}
                {state.selectedSidebarItem === SurveyEditSidebar.previousPeriod && state.surveySelected.id > 0 && (
                    <PreviousPeriod />
                )}
                {state.selectedSidebarItem === SurveyEditSidebar.assignAccess && state.surveySelected.id > 0 && (
                    <AssignAccess surveySelectedId={state.surveySelected.id} />
                )}
                {state.selectedSidebarItem === SurveyEditSidebar.dashboard && state.surveySelected.id > 0 && (
                    <Dashboard surveySelectedId={state.surveySelected.id} />
                )}
            </div>
        </div>
    );
};

export default withRouter(SurveyEdit);
