import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const useCommentSummaryStyles = makeStyles(() => ({
    summary: {
        display: "flex",
        flexDirection: "column",
        padding: "0px 12px 12px 24px",
        overflowY: "auto",
        scrollbarGutter: "stable"
    },
    summaryHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px"
    },
    suggestionHeader: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        padding: "24px 0px 12px 0px"
    },
    highlightWord: {
        backgroundColor: "#7BEDA7"
    },
    settingIcon: {
        color: theme.palette.primary.main
    },
    pivotGridWrapper: {
        "& >p": {
            fontWeight: 400,
            marginLeft: 30,
            marginTop: 30
        }
    }
}));
