import { gql } from "@apollo/client";

export const importSurveyMutation = gql`
    mutation importSurvey(
        $orgId: Int!
        $sgSurveyId: Int!
        $sgSurveyName: String!
        $confidentialityThreshold: Int!
        $tagIds: [Int!]!
        $closeDate: String!
    ) {
        importSurvey(
            orgId: $orgId
            sgSurveyId: $sgSurveyId
            sgSurveyName: $sgSurveyName
            confidentialityThreshold: $confidentialityThreshold
            tagIds: $tagIds
            closeDate: $closeDate
        ) {
            active
            created
            closeDate
            source
            id
            createdByName
            sgSurveyName
            isSurveyResult
            translations {
                name
            }
        }
    }
`;
