import React, { ReactElement, useMemo } from "react";

import SelectExpandablePanel from "components/shared/SelectExpandablePanel";
import { getAvailableSurveySections, surveySectionsToExpandableDataInFilter } from "core/helpers";
import { useLang, useSurveys } from "core/hooks";
import { SelectItem } from "core/interfaces";
import { SurveySection } from "managerPortal/context/interfaces";
import { HrisFieldInfo } from "managerPortal/interfaces";
import { ComparisonOperator, FilterMode, FilterType, FilterSelectionType, FilterTypeMap } from "./enums";
import { initField } from "./inits";
import { FilterParameter } from "./interfaces";
import { SurveyInfo } from "components/admin/results/interfaces";
import { surveyQuestionFilter, SurveySectionFilterDimensionName } from "./helper";
import { initSurveyQuestion } from "managerPortal/context/initialState";

import { Typography } from "lib/typography";
import { FormControl } from "lib/form-control";
import { Select, SelectChangeEvent } from "lib/select";
import { MenuItem } from "lib/menu-item";
import { List, ListItemButton, ListItemText } from "lib/list";
import { FilledInput } from "lib/filled-input";
import { useUser } from "core/context/user/useUser";
import { Box } from "lib/box";
import { Divider } from "lib/divider";

type Props = {
    selectedFilterItem: FilterParameter;
    updateFilterItems: (param: FilterParameter) => void;
    filterItems: FilterParameter[];
    filterMode: FilterMode;
    surveyId: SurveyInfo["id"];
    surveySections: SurveySection[];
    fieldsList: HrisFieldInfo[];
    showSentiment: boolean;
};

export const dimensionScoreTargetDefault = [
    { id: "1", text: "1" },
    { id: "5", text: "5" }
];

export const FilterFieldsList = (props: Props): ReactElement => {
    const {
        selectedFilterItem,
        filterItems,
        filterMode,
        surveyId,
        surveySections,
        fieldsList,
        showSentiment,
        updateFilterItems
    } = props;
    const { lang } = useLang();
    const { user } = useUser();
    const { surveys } = useSurveys();
    const survey = surveys.find(s => s.id === surveyId) as SurveyInfo;

    const handleComparisonTypeChange = (event: SelectChangeEvent<unknown>): void => {
        if (event.target.value) {
            const filterType = event.target.value as FilterSelectionType;
            let updateSelectedFilterItem: FilterParameter = {
                ...selectedFilterItem,
                field: initField,
                filterType: FilterType.contactField,
                comparisonOperator: ComparisonOperator.equals,
                target: []
            };
            if (filterType === FilterSelectionType.surveyItem) {
                updateSelectedFilterItem = {
                    ...updateSelectedFilterItem,
                    filterType: FilterType.surveyItemPickOne
                };
            }

            if (filterType === FilterSelectionType.date) {
                updateSelectedFilterItem = {
                    ...updateSelectedFilterItem,
                    field: { id: -1 * selectedFilterItem.order, fieldName: "date" },
                    filterType: FilterType.date,
                    comparisonOperator: ComparisonOperator.none
                };
            }
            updateFilterItems(updateSelectedFilterItem);
        }
    };

    const handleFieldSelected = (newItem: HrisFieldInfo): void => {
        const updateSelectedFilterItem = {
            ...selectedFilterItem,
            field: { id: newItem.fieldId, fieldName: newItem.fieldName },
            target: []
        };
        updateFilterItems(updateSelectedFilterItem);
    };

    const handleSurveyItemSelect = (id: string, name: string): void => {
        const itemFound = surveyQuestionFilter(surveySections, +id);
        const dimensionFound = SurveySectionFilterDimensionName(surveySections, +id);
        if (itemFound) {
            if ("itemId" in itemFound) {
                const updateSelectedPickOneItem = {
                    ...selectedFilterItem,
                    field: { id: +id, fieldName: name, dimensionName: dimensionFound },
                    filterType: FilterType.surveyItemPickOne,
                    target: []
                };
                updateFilterItems(updateSelectedPickOneItem);
            } else {
                if (itemFound.questionId !== initSurveyQuestion.questionId) {
                    const updateSelectedPickManyItem = {
                        ...selectedFilterItem,
                        field: { id: +id, fieldName: name, questionId: +id, dimensionName: dimensionFound },
                        filterType: FilterType.surveyItemPickMany,
                        target: []
                    };
                    updateFilterItems(updateSelectedPickManyItem);
                }
            }
        }
    };

    const getSelectedFilterItemFilterType = (filterType: FilterType): FilterSelectionType => {
        switch (filterType) {
            case FilterType.contactField:
                return FilterSelectionType.contactField;
            case FilterType.date:
                return FilterSelectionType.date;
            case FilterType.surveyItemPickMany:
            case FilterType.surveyItemPickOne:
                return FilterSelectionType.surveyItem;
            default:
                return FilterSelectionType.contactField;
        }
    };

    const filterType = useMemo(() => {
        return FilterTypeMap(lang);
    }, [lang, showSentiment, survey?.sentimentCalculationStatus]);

    const showSelector =
        filterMode === FilterMode.snapshot ||
        filterMode === FilterMode.heatmap ||
        filterMode === FilterMode.correlation ||
        filterMode === FilterMode.comment ||
        filterMode === FilterMode.responseRate ||
        filterMode === FilterMode.insight;

    const isSelectExpandablePanel =
        selectedFilterItem.filterType === FilterType.surveyItemPickOne ||
        selectedFilterItem.filterType === FilterType.surveyItemPickMany;

    return (
        <Box width={360} height="100%" display="flex" flexDirection="column">
            {showSelector && (
                <>
                    <Box paddingY={1} paddingX={2}>
                        <FormControl variant="filled" fullWidth>
                            <Select
                                data-testid="filterType-select"
                                value={getSelectedFilterItemFilterType(selectedFilterItem.filterType)}
                                onChange={handleComparisonTypeChange}
                                input={<FilledInput hiddenLabel={true} />}
                            >
                                {filterType.map((item: SelectItem) => (
                                    <MenuItem
                                        key={item.value}
                                        value={item.value}
                                        data-testid={`filterType-item-${item.text}`}
                                    >
                                        <Typography>{item.text}</Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <Divider />
                </>
            )}
            {isSelectExpandablePanel && (
                <Box flexGrow={1}>
                    <SelectExpandablePanel
                        rawData={surveySectionsToExpandableDataInFilter(
                            getAvailableSurveySections(surveySections, user)
                        )}
                        onItemSelected={handleSurveyItemSelect}
                        selectedId={selectedFilterItem.field.id}
                    />
                </Box>
            )}
            {!isSelectExpandablePanel && (
                <Box flexGrow={1} sx={{ overflowY: "auto" }}>
                    {selectedFilterItem.filterType === FilterType.contactField && (
                        <List>
                            {fieldsList.map((field: HrisFieldInfo) => (
                                <ListItemButton
                                    key={field.fieldId}
                                    onClick={(): void => handleFieldSelected(field)}
                                    data-testid={`filterField-${field.fieldName}`}
                                    selected={field.fieldId === selectedFilterItem.field.id}
                                    disabled={filterItems.some(
                                        (item: FilterParameter) => item.field.id === field.fieldId
                                    )}
                                >
                                    <ListItemText primary={field.fieldName} />
                                </ListItemButton>
                            ))}
                        </List>
                    )}
                    {selectedFilterItem.filterType === FilterType.date && (
                        <Box pl={2} pt={2}>
                            <Typography>{lang.dateResponseSubmitted}</Typography>
                        </Box>
                    )}
                </Box>
            )}
        </Box>
    );
};
