import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const useCommentStyles = makeStyles(() => ({
    dataContent: {
        display: "grid",
        gridTemplateRows: "1fr",
        gridTemplateColumns: "800px 1fr",
        gridTemplateAreas: `"sidebar commentGrid"`,
        boxSizing: "border-box",
        justifyContent: "center",
        height: "calc(100% - 112px)",
        overflowY: "auto"
    },
    sidebar: {
        borderRight: theme.border.main,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        overflowY: "auto",
        overflowX: "hidden"
    },
    pivotGridWrapper: {
        borderLeft: theme.border.main,
        gridArea: "commentGrid",
        "& >p": {
            fontWeight: 400,
            marginLeft: 30,
            marginTop: 30
        }
    },
    searchBarHeader: {
        display: "flex",
        height: "56px",
        borderBottom: theme.border.main,
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        alignItems: "center",
        justifyContent: "space-between"
    },
    searchBar: {
        width: 400,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 8
    },
    semanticSearchBar: {
        width: 480,
        marginTop: 4,
        marginBottom: 4,
        marginLeft: 8
    },
    responseHeader: {
        display: "flex",
        borderBottom: theme.border.main,
        height: 56
    },
    totalResponseHeader: {
        display: "flex",
        alignItems: "flex-start",
        flexDirection: "column",
        justifyContent: "center",
        marginLeft: 30,
        borderRight: theme.border.main,
        width: 770,
        minWidth: 770
    },
    responsePerSectionHeader: {
        marginLeft: theme.spacing(3),
        marginRight: theme.spacing(3),
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "flex-end"
    },
    filterTagsPlaceHolder: {},
    settingIcon: {
        color: theme.palette.primary.main,
        marginRight: 8,
        alignSelf: "center"
    },
    responsePerSectionHeaderLeft: {
        display: "flex",
        alignItems: "center"
    },
    tabs: {
        display: "flex"
    },
    customBadge: {
        backgroundColor: theme.palette.info.dark,
        color: "white",
        marginLeft: 4,
        transform: "scale(1) translate(100%, -50%) !important"
    }
}));
