import { Action } from "../../../../core/interfaces";
import { SelectSurveyDialogActions } from "../action";
import { SelectSurveyDialogState } from "../interface";

export const selectSurveyDialogReducer = (state: SelectSurveyDialogState, action: Action): SelectSurveyDialogState => {
    const { type, payload } = action;
    switch (type) {
        case SelectSurveyDialogActions.SET_SURVEY_SELECTED:
            return {
                ...state,
                surveySelected: payload.surveySelected
            };
        case SelectSurveyDialogActions.SET_MODE:
            return {
                ...state,
                isLive: payload.isLive,
                surveySelected: payload.surveySelected
            };
        default:
            return {
                ...state
            };
    }
};
