import React, { ReactElement, useState } from "react";
import { makeStyles } from "@mui/styles";

import { Typography } from "lib/typography";
import useMediaQuery from "@mui/material/useMediaQuery";
import { mdiArrowCollapseVertical, mdiArrowExpandVertical } from "@mdi/js";

import { theme } from "lib/theme";
import { useLang } from "core/hooks";
import { PanelInfo } from "./interface";
import CustomLabelInfo from "../../shared/CustomLabelInfo";
import ResponseRateListResult from "./ResponseRateListResult";
import RateBar from "../../shared/RateBar";
import { IconButtonV4 } from "lib/icon-button";
import { ReportBreakdownType } from "./enums";
import { Filter } from "components/filters/interfaces";
import { backgroundLightGrey, tableHead } from "lib/palette";

type Props = {
    overallResponseRateResponseRate: number;
    overallResponseRateSurveysSent: number;
    overallResponseRateSurveysCompleted: number;
    responseRate: PanelInfo[];
    breakdownType: ReportBreakdownType;
    currentFilter: Filter;
};

type StyleProps = {
    tableMaxHeight: number;
    matches1348: boolean;
    matchesLG: boolean;
    matchesXL: boolean;
};

const useStyles = makeStyles(() => ({
    chart: {
        gridArea: "responseRateChart",
        display: "grid",
        justifyContent: "center",
        boxSizing: "border-box",
        gridTemplateRows: "72px 56px 36px 1fr",
        gridTemplateColumns: "minmax(auto, 1400px)",
        gridTemplateAreas: `
            "overallResponseRate"
            "chartActions"
            "chartTableHead"
            "chartTableContent"
        `
    },
    chartOverall: (stylesProps: StyleProps) => ({
        gridArea: "overallResponseRate",
        minHeight: 72,
        height: "fit-content",
        background: backgroundLightGrey,
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        boxSizing: "border-box",
        borderLeft: stylesProps.matchesLG ? "none" : theme.border.main,
        borderRight: stylesProps.matchesLG ? "none" : theme.border.main,
        borderBottom: theme.border.main
    }),
    chartOverallInfo: {
        display: "flex",
        height: 40,
        flexGrow: 1
    },
    chartOverallInfoText: {
        minWidth: 220,
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        boxSizing: "border-box",
        fontWeight: "bold"
    },
    chartOverallInfoValue: {
        width: 180,
        borderLeft: theme.border.main,
        display: "flex",
        alignItems: "center",
        paddingLeft: theme.spacing(2),
        boxSizing: "border-box",
        justifyContent: "center"
    },
    chartOverallBar: (stylesProps: StyleProps) => ({
        width: 448,
        height: 40,
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        borderLeft: stylesProps.matches1348 ? "none" : theme.border.main
    }),
    chartActions: (stylesProps: StyleProps) => ({
        gridArea: "chartActions",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingRight: theme.spacing(2),
        boxSizing: "border-box",
        borderLeft: stylesProps.matchesLG ? "none" : theme.border.main,
        borderRight: stylesProps.matchesLG ? "none" : theme.border.main
    }),
    chartTableContent: (stylesProps: StyleProps) => ({
        //chartCverall Height 72px
        //chartActions Height 56px
        //tableHead Height 36px
        maxHeight: stylesProps.tableMaxHeight === 0 ? "100%" : stylesProps.tableMaxHeight - 72 - 56 - 36,
        gridArea: "chartTableContent",
        boxSizing: "border-box",
        borderLeft: stylesProps.matchesLG ? "none" : theme.border.main,
        borderRight: stylesProps.matchesLG ? "none" : theme.border.main
    }),
    chartTableHead: (stylesProps: StyleProps) => ({
        gridArea: "chartTableHead",
        background: tableHead,
        boxSizing: "border-box",
        borderLeft: stylesProps.matchesLG ? "none" : theme.border.main,
        borderRight: stylesProps.matchesLG ? "none" : theme.border.main
    }),
    tableRow: {
        display: "grid",
        minHeight: 36,
        alignItems: "center",
        gridTemplateRows: "1fr",
        gridTemplateColumns: "1fr 100px 100px 332px",
        gridTemplateAreas: `
            "title surveysSent surveysCompleted responseRate"
        `
    },
    emptyChart: {
        display: "flex",
        flexDirection: "column",
        padding: theme.spacing(3)
    },
    emptyChartTypography: {
        marginBottom: theme.spacing(2)
    }
}));

const ResponseRateChart = (props: Props): ReactElement => {
    const { lang } = useLang();
    const [isAllCollapsed, setCollapseAll] = useState(false);
    const [isAllExpanded, setExpandAll] = useState(false);

    const matches1348 = useMediaQuery("(max-width:1348px)");
    const matchesLG = useMediaQuery(theme.breakpoints.down("lg"));
    const matchesXL = useMediaQuery(theme.breakpoints.down("xl"));

    const elementDiv = document.getElementById("dataContent");
    const tableMaxHeight = elementDiv?.offsetHeight;
    const stylesProps: StyleProps = {
        tableMaxHeight: tableMaxHeight ?? 0,
        matches1348,
        matchesLG,
        matchesXL
    };
    const classes = useStyles(stylesProps);

    const handleCollapseAll = (): void => {
        setCollapseAll(true);
        setExpandAll(false);
    };

    const handleExpandAll = (): void => {
        setExpandAll(true);
        setCollapseAll(false);
    };

    const resetButtons = (): void => {
        setCollapseAll(false);
        setExpandAll(false);
    };

    const collapseAllDisabled =
        isAllCollapsed ||
        props.responseRate.length === 0 ||
        !props.responseRate.some((item: PanelInfo) => item.subNode);

    const expandAllDisabled =
        isAllExpanded || props.responseRate.length === 0 || !props.responseRate.some((item: PanelInfo) => item.subNode);

    return (
        <div className={classes.chart}>
            <div className={classes.chartOverall}>
                <div className={classes.chartOverallInfo}>
                    <div className={classes.chartOverallInfoText}>
                        <Typography variant="body1">{lang.overallResponseRate}</Typography>
                    </div>
                    <div className={classes.chartOverallInfoValue} data-testid="total_survey_sent">
                        <CustomLabelInfo
                            label={lang.surveysSent}
                            info={props.overallResponseRateSurveysSent.toFixed(0)}
                        />
                    </div>
                    <div className={classes.chartOverallInfoValue} data-testid="total_survey_completed">
                        <CustomLabelInfo
                            label={lang.surveysCompleted}
                            info={props.overallResponseRateSurveysCompleted.toFixed(0)}
                        />
                    </div>
                </div>
                <div className={classes.chartOverallBar} data-testid="total_survey_percentage">
                    <RateBar barWidth={Math.round(props.overallResponseRateResponseRate)} rate={1} />
                </div>
            </div>
            <div className={classes.chartActions}>
                <IconButtonV4
                    path={mdiArrowExpandVertical}
                    onClick={handleExpandAll}
                    disabled={expandAllDisabled}
                    tooltip={lang.expandAll}
                    dataTestid="response-rate-chart-expandAll"
                />
                <IconButtonV4
                    path={mdiArrowCollapseVertical}
                    onClick={handleCollapseAll}
                    disabled={collapseAllDisabled}
                    tooltip={lang.collapseAll}
                    dataTestid="response-rate-chart-collapseAll"
                />
            </div>
            <div className={classes.chartTableHead}>
                <div className={classes.tableRow}>
                    <div></div>
                    <Typography align="center" fontSize={14} fontWeight="medium">
                        {props.breakdownType !== ReportBreakdownType.surveyItem && props.responseRate.length > 0
                            ? lang.surveysSent
                            : ""}
                    </Typography>
                    <Typography align="center" fontSize={14} fontWeight="medium">
                        {props.responseRate.length > 0 ? lang.surveysCompleted : ""}
                    </Typography>
                    <Typography pl={3} fontSize={14} fontWeight="medium">
                        {props.responseRate.length > 0 ? lang.responseRatePercentage : ""}
                    </Typography>
                </div>
            </div>
            <div className={classes.chartTableContent}>
                {props.responseRate.length > 0 ? (
                    <ResponseRateListResult
                        rawData={props.responseRate}
                        isAllExpanded={isAllExpanded}
                        isAllCollapsed={isAllCollapsed}
                        onChange={resetButtons}
                        breakdownType={props.breakdownType}
                    />
                ) : props.currentFilter.items.length > 0 ? (
                    <div className={classes.emptyChart}>
                        {lang.responseRateEmptyBreakdownPageText.map((msg, index) => (
                            <Typography variant="subtitle1" className={classes.emptyChartTypography} key={index}>
                                {msg}
                            </Typography>
                        ))}
                    </div>
                ) : (
                    <div className={classes.emptyChart}>
                        {lang.responseRateHelpText.map((msg, index) => (
                            <Typography variant="subtitle1" className={classes.emptyChartTypography} key={index}>
                                {msg}
                            </Typography>
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ResponseRateChart;
