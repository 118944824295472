import { makeStyles } from "@mui/styles";

import { theme } from "lib/theme";

export const useStyles = makeStyles(() => ({
    wordCloudHeader: {
        display: "flex",
        justifyContent: "space-between",
        borderTop: theme.border.main,
        borderBottom: theme.border.main,
        height: 56,
        paddingLeft: 16,
        paddingRight: 8
    },
    wordCloud: {
        height: 315,
        minHeight: 315,
        display: "flex",
        justifyContent: "space-between",
        position: "relative"
    },
    commentCountText: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start"
    },
    chartActions: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end"
    },
    words: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "100px"
    },
    worldCloudDisplayChart: {
        float: "right",
        top: 10,
        right: 5,
        position: "absolute"
    },
    worldCloudDisplayChartTypography: {
        fontSize: 14,
        fontWeight: 500,
        marginRight: 8,
        marginTop: 10,
        boxSizing: "border-box"
    },
    settingIcon: {
        color: theme.palette.primary.main
    },
    stackedBar: {
        width: 600,
        height: 28
    }
}));
