export enum AdminRoutes {
    goalsChallenges = "goalsChallenges",
    organizations = "organizations",
    organizationDetail = "organizationDetail",
    results = "results",
    users = "users",
    tags = "tags",
    benchmarks = "benchmarks",
    bulkStatus = "bulkStatus",
    participants = "participants"
}
