import React, { ReactElement, useReducer } from "react";
import { useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";

import { createUserMutation } from "api/mutations";
import UserDetail from "./UserDetail";
import { UserMutateDetail, UserMutate } from "./interface/";
import { useLang, useOrgHasAdmin } from "core/hooks";
import { initUserInput } from "./initialVariables";
import { isStringEmptyOrSpaces, isEmailFieldValid } from "core/helpers";
import { UserMutateReducer } from "./reducers";
import { UserMutateActions } from "./actions";
import { Snackbar } from "lib/snackbar";
import { UserRole } from "./enums";
import { Box } from "lib/box";
import { Divider } from "lib/divider";
import { Typography } from "lib/typography";

const init: UserMutate = {
    user: initUserInput,
    snackbar: { isOpen: false, message: "" },
    validation: { message: "", key: "" }
};

const AddUser = (): ReactElement => {
    const { lang } = useLang();
    const { selectedOrg } = useOrgHasAdmin();

    const redirectURL = "/admin/users";
    const [state, dispatch] = useReducer(UserMutateReducer, init);
    const history = useHistory();

    const [createUser] = useMutation(createUserMutation, {
        onCompleted: () => {
            dispatch({
                type: UserMutateActions.SET_SNACKBAR_STATUS,
                payload: { snackbar: { isOpen: true, message: lang.newUserHasBeenCreated } }
            });
            history.push(redirectURL);
        },
        onError: () => {
            dispatch({
                type: UserMutateActions.SET_VALIDATION_ERROR,
                payload: {
                    validation: { message: lang.userEmailError, key: "email" }
                }
            });
        }
    });

    const onChangeField = (key: keyof UserMutateDetail, newValue: string | number | boolean): void => {
        let updateUser: UserMutateDetail = {
            ...state.user,
            [key]: newValue
        };

        if (updateUser.isSiteAdmin) {
            updateUser = { ...updateUser, [UserRole.isRestricted]: false };
        }

        if (selectedOrg.id !== -1) {
            updateUser.organizationId = selectedOrg.id;
        }

        dispatch({ type: UserMutateActions.SET_USER, payload: { user: updateUser } });
    };

    const inputValidation = (): boolean => {
        if (isStringEmptyOrSpaces(state.user.name)) {
            dispatch({
                type: UserMutateActions.SET_VALIDATION_ERROR,
                payload: {
                    validation: { message: lang.userNameCannotBeBlank, key: "name" }
                }
            });
            return false;
        }

        if (isStringEmptyOrSpaces(state.user.email)) {
            dispatch({
                type: UserMutateActions.SET_VALIDATION_ERROR,
                payload: {
                    validation: { message: lang.userEmailCannotBeBlank, key: "email" }
                }
            });
            return false;
        }

        if (isEmailFieldValid(state.user.email)) {
            dispatch({
                type: UserMutateActions.SET_VALIDATION_ERROR,
                payload: {
                    validation: { message: lang.userEmailCannotBeInvalid, key: "email" }
                }
            });
            return false;
        }

        return true;
    };

    const handleSave = (): void => {
        const queryOptions = {
            variables: {
                user: {
                    ...state.user
                }
            }
        };

        createUser(queryOptions);
    };

    const handleSaveButton = (): void => {
        if (!inputValidation()) {
            return;
        }
        handleSave();
    };

    const handleCloseSnackbar = (): void => {
        dispatch({
            type: UserMutateActions.SET_SNACKBAR_STATUS,
            payload: { snackbar: { isOpen: false, message: "" } }
        });
    };
    return (
        <Box>
            <Box display="flex" justifyContent="flex-start" alignItems="center" height={56} pl={4} pr={3}>
                <Typography variant="h6">{lang.addUser}</Typography>
            </Box>
            <Divider />
            <UserDetail
                user={state.user}
                validation={state.validation}
                editMode={false}
                onChangeField={onChangeField}
                redirectURL={redirectURL}
                handleSaveButton={handleSaveButton}
            />
            <Snackbar open={state.snackbar.isOpen} handleClose={handleCloseSnackbar} message={state.snackbar.message} />
        </Box>
    );
};

export default AddUser;
