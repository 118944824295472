import React, { ReactElement } from "react";
import {
    MainRoutes,
    OrganizationsRoutes,
    SurveysRoutes,
    ReportRoutes,
    UsersRoutes,
    ParticipantsRoutes
} from "./routes";
import RouteRender from "./RouteRender";
import { useUser } from "core/context/user/useUser";

const MainRouter = (): ReactElement => {
    const { user } = useUser();

    const allRoutes = MainRoutes(user)
        .concat(OrganizationsRoutes(user))
        .concat(SurveysRoutes(user))
        .concat(ParticipantsRoutes(user))
        .concat(ReportRoutes(user))
        .concat(UsersRoutes(user));

    return <RouteRender routes={allRoutes} />;
};

export default MainRouter;
